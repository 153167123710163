import { Box, Tooltip } from "@mui/material";

import { NChip } from "@ngi/react-component";
import { LocationGroup } from "src/types/locationGroup";
import { LocationNameMapping } from "src/types/locations";
import { TableCustomCell } from "src/types/table";

export const LocationNameCellFactory = (
  locationNameMapping: LocationNameMapping,
) => {
  const Component = ({
    getValue,
  }: TableCustomCell<LocationGroup, LocationGroup["location_ids"]>) => {
    const location_ids = getValue() || [];

    const locationNames = location_ids.map(
      (id) => locationNameMapping[id] || id,
    );
    //
    // Limit to first 3 names and calculate the remaining count
    const displayedNames = locationNames.slice(0, 3);
    const remainingCount = locationNames.length - displayedNames.length;

    return (
      <Box className="flex gap-4 items-center">
        {displayedNames &&
          displayedNames.map((name, i) => (
            <NChip key={i} label={name} size="small" />
          ))}
        {remainingCount > 0 && (
          <Tooltip
            placement="right-end"
            title={
              <div className="flex flex-col">
                {locationNames.slice(3).map((name, i) => (
                  <div key={i}>{name}</div>
                ))}
              </div>
            }
          >
            <span className="cursor-pointer">and {remainingCount} more</span>
          </Tooltip>
        )}
      </Box>
    );
  };

  Component.displayName = "LocationNameCell";
  return Component;
};
