import { useCallback, useMemo, useState } from "react";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { Check, CopyAll, Error } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  MenuItem as MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueries } from "@tanstack/react-query";
import { getDefaultsForSchema } from "zod-defaults";

import { NButton, NDialog } from "@ngi/react-component";
import {
  addProjectPhaseRevisionMutationQuery,
  duplicateProjectPhaseRevisionMutationQuery,
  updateProjectPhaseRevisionMutationQuery,
} from "src/queries/mutations";
import { getProjectPhaseRevisionsQuery } from "src/queries/queries";
import {
  projectPhaseRevisionSchema,
  ProjectPhaseRevisionSchemaType,
} from "src/schemas/projectPhaseRevisionSchema";
import { PhaseRevision } from "src/types/api";
import { ProjectPhase } from "src/types/projectPhase";
import { MakeOptional } from "src/utils/types";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  project_id: string;
  phase_id: string;
  revision_id?: string;
  initialData?: MakeOptional<ProjectPhaseRevisionSchemaType, "description">;
  description?: string;
  projectPhases: ProjectPhase[];
};

export const CreateOrUpdateProjectPhaseRevision = ({
  open,
  setOpen,
  initialData = getDefaultsForSchema(projectPhaseRevisionSchema),
  project_id,
  phase_id,
  revision_id,
  projectPhases,
}: Props) => {
  const [busyStatus, setBusyStatus] = useState<
    "idle" | "mutating" | "error" | "success"
  >("idle");

  const formContext = useForm<ProjectPhaseRevisionSchemaType>({
    mode: "onTouched",
    resolver: zodResolver(projectPhaseRevisionSchema),
    // ensure that the selected methods to export as set in the form options at start
    defaultValues: {
      ...initialData,
    },
  });

  const projectPhaseMapping = useMemo(
    () =>
      projectPhases.reduce(
        (acc, phase) => {
          acc[phase.phase_id] = phase;
          return acc;
        },
        {} as Record<string, ProjectPhase>,
      ),
    [projectPhases],
  );

  const allRevisions = useQueries({
    queries: projectPhases.map((phase) =>
      getProjectPhaseRevisionsQuery(phase.project_id, phase.phase_id),
    ),
    combine: (results) => ({
      data: results.map((result) => result.data),
      pending: results.some((result) => result.isPending),
    }),
  });

  const allRevisionsOptions = useMemo((): {
    value: PhaseRevision;
    label: string;
  }[] => {
    if (!allRevisions.data) return [];
    return allRevisions.data.reduce(
      (acc, revisions) => {
        revisions.forEach((revision: PhaseRevision) => {
          acc.push({
            value: revision,
            label: `${projectPhaseMapping[revision.phase_id].name} | ${revision.name}`,
          });
        });
        return acc;
      },
      [] as { value: PhaseRevision; label: string }[],
    );
  }, [allRevisions.data, projectPhaseMapping]);

  const isValid = formContext.formState.isValid;

  const [copyDataFromOtherRevision, setCopyDataFromOtherRevision] =
    useState<boolean>(false);

  const [sourceRevisionID, setSourceRevisionID] = useState<string | null>(null);

  const handleChange = (event: SelectChangeEvent) => {
    const revision_id = event.target.value;
    setSourceRevisionID(revision_id);
  };

  const createProjectPhaseRevisionMutation = useMutation(
    addProjectPhaseRevisionMutationQuery(project_id, phase_id),
  );

  const duplicateProjectPhaseRevisionMutation = useMutation(
    duplicateProjectPhaseRevisionMutationQuery(project_id, phase_id),
  );

  const updateProjectPhaseRevisionMutation = useMutation(
    updateProjectPhaseRevisionMutationQuery(project_id, phase_id),
  );

  const text = useMemo(
    () => (revision_id ? "Update revision" : "Create new revision"),
    [revision_id],
  );

  const handleCreateProjectPhase = useCallback(async () => {
    const isValid = await formContext.trigger();
    if (!isValid) return;

    if (revision_id) {
      updateProjectPhaseRevisionMutation.mutate({
        revision_id,
        payload: formContext.getValues(),
      });
      setOpen(false);
    } else {
      if (sourceRevisionID) {
        setBusyStatus("mutating");
        duplicateProjectPhaseRevisionMutation.mutate(
          {
            phase_id,
            payload: {
              ...formContext.getValues(),
              source_revision_id: sourceRevisionID,
            },
          },

          {
            onError: () => setBusyStatus("error"),
            onSuccess: () => setBusyStatus("success"),
          },
        );
      } else {
        createProjectPhaseRevisionMutation.mutate({
          phase_id,
          payload: formContext.getValues(),
        });
        setOpen(false);
      }
    }
  }, [
    formContext,
    revision_id,
    setOpen,
    updateProjectPhaseRevisionMutation,
    sourceRevisionID,
    duplicateProjectPhaseRevisionMutation,
    phase_id,
    createProjectPhaseRevisionMutation,
  ]);

  const createPhaseActions = useMemo(() => {
    const disabled = !isValid;
    return (
      <>
        {busyStatus === "idle" && (
          <NButton onClick={handleCreateProjectPhase} disabled={disabled}>
            {text}
          </NButton>
        )}
        {busyStatus !== "mutating" && (
          <NButton onClick={() => setOpen(false)} variant="text">
            {busyStatus === "success" || busyStatus === "error"
              ? "Close"
              : "Cancel"}
          </NButton>
        )}
      </>
    );
  }, [busyStatus, handleCreateProjectPhase, isValid, setOpen, text]);

  return (
    <NDialog
      dialogTitle={text}
      onClose={() => setOpen(false)}
      open={open}
      actions={createPhaseActions}
    >
      <div>
        {busyStatus !== "idle" ? (
          <div className="flex flex-col items-center justify-center w-full">
            <Box sx={{ m: 1, position: "relative" }}>
              <Fab
                aria-label="save"
                color={
                  busyStatus === "mutating"
                    ? "primary"
                    : busyStatus === "error"
                      ? "error"
                      : "success"
                }
              >
                {busyStatus === "success" ? (
                  <Check />
                ) : busyStatus === "error" ? (
                  <Error />
                ) : (
                  <CopyAll />
                )}
              </Fab>
              {busyStatus === "mutating" && (
                <CircularProgress
                  size={68}
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography
              variant="h6"
              color={
                busyStatus === "mutating"
                  ? "primary"
                  : busyStatus === "error"
                    ? "error"
                    : "success"
              }
            >
              {busyStatus === "mutating"
                ? "Duplicating revision..."
                : busyStatus === "error"
                  ? "Something went wrong while duplicating the revision"
                  : "Successfully duplicated the revision"}
            </Typography>
          </div>
        ) : (
          <FormContainer formContext={formContext}>
            <Grid container spacing={2} sx={{ px: 2 }}>
              <Grid item xs={12}>
                <TextFieldElement
                  name="name"
                  label="Name"
                  required
                  sx={{ my: 1, width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElement
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  sx={{ my: 1, width: "100%" }}
                />
              </Grid>
              {!revision_id && (
                <Grid item xs={12}>
                  <div className="flex justify-center items-center">
                    <Switch
                      checked={copyDataFromOtherRevision}
                      onChange={() =>
                        setCopyDataFromOtherRevision(!copyDataFromOtherRevision)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />

                    <NButton
                      color="primary"
                      variant="text"
                      className="!pl-0"
                      onClick={() =>
                        setCopyDataFromOtherRevision(!copyDataFromOtherRevision)
                      }
                    >
                      <Typography
                        variant="body2"
                        color={
                          copyDataFromOtherRevision ? "black" : "textSecondary"
                        }
                      >
                        Copy data from an existing revision?
                      </Typography>
                    </NButton>
                  </div>
                </Grid>
              )}
              {!revision_id && copyDataFromOtherRevision && (
                <Grid item xs={12}>
                  <div className="flex items-center">
                    <Typography className="w-40">Copy data from:</Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      className="SelectCell"
                      id="demo-simple-select"
                      value={sourceRevisionID || ""}
                      displayEmpty
                      onChange={handleChange}
                      fullWidth
                    >
                      {allRevisionsOptions.map((opt, i) => (
                        <MenuItem key={i} value={opt.value.revision_id}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
              )}
            </Grid>
          </FormContainer>
        )}
      </div>
    </NDialog>
  );
};
