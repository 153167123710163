import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { NBaseAlert, NButton } from "@ngi/react-component";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSaveAndClose: () => void;
  onNotSaveAndClose: () => void;
};

export const EditableLayerDialogCloseConfirm = ({
  open,
  onCancel,
  onSaveAndClose,
  onNotSaveAndClose,
}: Props) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <NBaseAlert
        severity="warning"
        title="Unsaved changes!"
        description="You have unsaved changes. If you decide to close without saving, the changes will be lost."
      />
    </DialogContent>
    <DialogActions className="gap-2">
      <NButton onClick={onCancel} variant="text">
        Continue editing
      </NButton>
      <NButton onClick={onSaveAndClose} variant="text">
        Save and close
      </NButton>
      <NButton
        onClick={onNotSaveAndClose}
        variant="text"
        /* @ts-expect-error the color props exists on Mui but not on NGI-reac-component button */
        color="error"
      >
        Close without saving
      </NButton>
    </DialogActions>
  </Dialog>
);
