import { ChangeEvent } from "react";
import { FormControl, TextField } from "@mui/material";

import { SoilLayerType } from "src/schemas/soilLayerSchema";
import { TableCustomCell } from "src/types/table";

export const DepthCell = ({
  getValue,
  row,
  column,
  table,
}: TableCustomCell<SoilLayerType, SoilLayerType["depth"]>) => {
  const depth = getValue() || 0;
  const updateData = table.options.meta?.updateData;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (updateData) {
      updateData(
        row.index,
        column.id,
        parseFloat(event.target.value as string),
      );
    }
  };

  if (row.index === 0) {
    return <span className="pl-4">{depth}</span>;
  }

  return (
    <FormControl fullWidth>
      <TextField value={depth} onChange={handleChange} type="number" />
    </FormControl>
  );
};
