import { useCallback, useMemo, useRef, useState } from "react";
import { Settings } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import * as d3 from "d3";

import { NButton, NDialog } from "@ngi/react-component";
import { PlotSettings } from "src/components/common/plots/PlotSetting";
import { RAW_CPT_PLOTS } from "src/definitions/plotConstants";
import { IMPORT_CPT_STATUSES } from "src/definitions/statuses";
import { getCPTQuery } from "src/queries/queries";
import { DataPoint } from "src/types/data";
import { Location } from "src/types/locations";
import { GeoPlot } from "src/types/plots";
import { groupByMethodId } from "src/utils/groupCPTs";
import { CptPlot } from "./CptPlot";

import "./CptPlotPreview.scss";

type Props = {
  project_id: string;
  siite_location_id: string;
  locations: Location[];
  open: boolean;
  setOpen: (b: boolean) => void;
};

export const CptPlotPreview = ({
  project_id,
  siite_location_id,
  locations,
  open,
  setOpen,
}: Props) => {
  const [currentCptID, setCurrentCptID] = useState<string>(siite_location_id);
  const [openPlotSettings, setOpenPlotSettings] = useState<boolean>(false);
  const zoomDispatch = useRef(d3.dispatch("zoom")).current;
  const defaultSelectedPlots = RAW_CPT_PLOTS.filter((plot) => plot.default);
  const [selectedPlots, setSelectedPlots] = useState<GeoPlot[]>(
    RAW_CPT_PLOTS.filter((plot) => plot.default),
  );
  const [numPlotPerRow, setNumPlotPerRow] = useState<number>(
    defaultSelectedPlots.length,
  );

  const plotSettingsHasChanged = useMemo(
    () =>
      defaultSelectedPlots.length !== selectedPlots.length ||
      defaultSelectedPlots.some((plot, i) => plot.id !== selectedPlots[i].id) ||
      numPlotPerRow !== defaultSelectedPlots.length,
    [defaultSelectedPlots, numPlotPerRow, selectedPlots],
  );

  const resetPlotSettings = useCallback(() => {
    setSelectedPlots(defaultSelectedPlots);
    setNumPlotPerRow(defaultSelectedPlots.length);
  }, [defaultSelectedPlots]);

  const currentIndex = useMemo(
    () =>
      locations.findIndex(
        (location) => location.siite_location_id === currentCptID,
      ),
    [locations, currentCptID],
  );

  const prevCptID = useMemo(() => {
    const prevLocation = locations[currentIndex - 1];
    if (!prevLocation) return null;
    return prevLocation.import_status === IMPORT_CPT_STATUSES.SUCCESS
      ? prevLocation.siite_location_id
      : null;
  }, [locations, currentIndex]);

  const nextCptID = useMemo(() => {
    const nextLocation = locations[currentIndex + 1];
    if (!nextLocation) return null;
    return nextLocation.import_status === IMPORT_CPT_STATUSES.SUCCESS
      ? nextLocation.siite_location_id
      : null;
  }, [locations, currentIndex]);

  const { status, error, data } = useQuery(
    getCPTQuery(project_id, currentCptID),
  );

  const cptDataGrouped = useMemo(() => {
    if (!data) return [[]];
    return groupByMethodId(data.data);
  }, [data]);

  // Prefetch the previous and next CPTs
  useQuery(getCPTQuery(project_id, prevCptID || ""));
  useQuery(getCPTQuery(project_id, nextCptID || ""));

  const actions = useMemo(
    () => (
      <>
        <NButton
          onClick={() => prevCptID && setCurrentCptID(prevCptID)}
          variant="text"
          disabled={!prevCptID}
        >
          Previous CPT
        </NButton>
        <NButton
          onClick={() => nextCptID && setCurrentCptID(nextCptID)}
          variant="text"
          disabled={!nextCptID}
        >
          Next CPT
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Close
        </NButton>
      </>
    ),
    [setCurrentCptID, prevCptID, nextCptID, setOpen],
  );

  if (status === "pending") {
    return <span>Loading...</span>;
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }
  const { data: cptData } = data;

  // TODO: Check if cptData is empty and show it in a dialog instead of below the table
  if (cptData.length === 0) {
    return <span>No data to display</span>;
  }

  const yDataRange: [number, number] = [0, cptData[cptData.length - 1].depth];

  const margins = { top: 50, left: 50, bottom: 50, right: 50 };

  return (
    <NDialog
      dialogTitle={`Preview of ${locations[currentIndex].name}`}
      onClose={() => setOpen(false)}
      open={open}
      actions={actions}
      fullScreen
    >
      <div className="h-full flex flex-col">
        <div className="px-2 flex justify-end">
          <Tooltip title="Plot settings">
            <IconButton
              color="primary"
              onClick={() => setOpenPlotSettings(true)}
            >
              <Settings />
            </IconButton>
          </Tooltip>
        </div>

        <div className={`grow grid grid-cols-${numPlotPerRow} auto-rows-fr`}>
          {selectedPlots.map((plot) => (
            <CptPlot
              key={plot.id}
              id={`${plot.id}`}
              data={cptDataGrouped}
              xKeys={plot.series.x}
              yKey={plot.series.y}
              margins={margins}
              yScaleDataRange={yDataRange}
              zoomDispatch={zoomDispatch}
            />
          ))}
        </div>
      </div>
      {openPlotSettings && (
        <NDialog
          dialogTitle="Plot settings"
          onClose={() => setOpenPlotSettings(false)}
          open={open}
          maxWidth="md"
          actions={
            <>
              <NButton
                variant="text"
                disabled={!plotSettingsHasChanged}
                onClick={() => resetPlotSettings()}
              >
                Reset to default settings
              </NButton>
              <NButton
                variant="text"
                onClick={() => setOpenPlotSettings(false)}
              >
                Close
              </NButton>
            </>
          }
        >
          <PlotSettings
            numPlotPerRow={numPlotPerRow}
            setNumPlotPerRow={setNumPlotPerRow}
            selectedPlots={selectedPlots}
            setSelectedPlots={setSelectedPlots}
            plots={RAW_CPT_PLOTS}
          />
        </NDialog>
      )}
    </NDialog>
  );
};
