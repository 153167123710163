import { useState } from "react";
import { ClickAwayListener, FormControl, TextField } from "@mui/material";

import { TableCustomCell } from "src/types/table";

export const EditableTextCell = <TData, TKey extends keyof TData>({
  getValue,
  row,
  column,
  table,
}: TableCustomCell<TData, TData[TKey]>) => {
  const initialValue = getValue() as TData[TKey];

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<TData[TKey]>(initialValue);

  const updateData = () => {
    if (value !== initialValue) {
      table.options.meta?.updateData(row.index, column.id, value);
    }
  };

  // Only update the value onBlur or if clicking away to ensure good performance
  const onBlur = () => {
    updateData();
  };

  return (
    <FormControl fullWidth>
      <ClickAwayListener onClickAway={updateData}>
        <TextField
          value={value as string}
          onChange={(e) => setValue(e.target.value as TData[TKey])}
          onBlur={onBlur}
        />
      </ClickAwayListener>
    </FormControl>
  );
};

type Props<T> = {
  updateData: (index: number, id: keyof T, value: string) => void;
  field: keyof T; // The specific field this cell is editing
};

export const EditableTextCellFactory = <T extends object>({
  updateData,
  field,
}: Props<T>) => {
  const Component = ({
    getValue,
    row,
  }: TableCustomCell<T, T[keyof T]>): JSX.Element => {
    const initialValue = getValue() as string;

    const [value, setValue] = useState(initialValue);

    const handleUpdateData = () => {
      if (value !== initialValue) {
        updateData(row.index, field, value);
      }
    };

    const onBlur = () => {
      handleUpdateData();
    };

    const onClickAway = () => {
      handleUpdateData();
    };

    return (
      <FormControl fullWidth>
        <ClickAwayListener onClickAway={onClickAway}>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          />
        </ClickAwayListener>
      </FormControl>
    );
  };
  Component.displayName = "EditableCell";
  return Component;
};
