import { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { OSM } from "ol/source";

import { MapContext } from "./MapContext";

import "ol/ol.css";

const BOX_SHADOW =
  "0px 1px 5px 0px rgba(0, 0, 0, 0.12),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.2)";

export const MapElement = ({ children }: { children?: ReactNode }) => {
  const mapRef = useRef<Map>(
    new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: [0, 0],
        zoom: 1,
      }),
    }),
  );
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    mapRef.current.setTarget(divRef.current);

    return () => {
      if (mapRef.current) {
        mapRef.current.setTarget(undefined);
      }
    };
  }, []);

  return (
    <MapContext.Provider value={mapRef.current}>
      <div
        ref={divRef as MutableRefObject<HTMLDivElement>}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "4px",
          boxShadow: BOX_SHADOW,
        }}
      />
      {children}
    </MapContext.Provider>
  );
};
