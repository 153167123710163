import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { PlotSelection } from "src/components/PlotSelection";
import { GeoPlot } from "src/types/plots";

type PlotSettingsProps = {
  numPlotPerRow: number;
  setNumPlotPerRow: React.Dispatch<React.SetStateAction<number>>;
  selectedPlots: GeoPlot[];
  setSelectedPlots: React.Dispatch<React.SetStateAction<GeoPlot[]>>;
  plots: GeoPlot[];
};

const plotPerRowOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const PlotSettings: React.FC<PlotSettingsProps> = ({
  numPlotPerRow,
  setNumPlotPerRow,
  selectedPlots,
  setSelectedPlots,
  plots,
}) => {
  const handlePlotPerRowChange = (e: SelectChangeEvent) => {
    setNumPlotPerRow(parseInt(e.target.value));
  };

  return (
    <div className="h-full">
      <div className="grid grid-cols-1 gap-4">
        <div className="flex items-center justify-start min-w-64 gap-8">
          <div className="flex flex-col w-64">
            <Typography variant="h6">Plots per row</Typography>
            <Typography variant="caption">
              This only applies to large screens
            </Typography>
          </div>
          <FormControl className="w-48">
            <Select
              value={`${numPlotPerRow}`}
              onChange={handlePlotPerRowChange}
            >
              {plotPerRowOptions.map((num, i) => (
                <MenuItem key={i} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-col">
          <div className="w-64 pb-2">
            <Typography variant="h6">Select plots:</Typography>
          </div>
          <div>
            <PlotSelection
              selectedPlots={selectedPlots}
              setSelectedPlots={setSelectedPlots}
              plots={plots}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
