import axios from "axios";

import {
  CreateSoilUnitSchemaType,
  SoilUnitSchemaType,
} from "src/schemas/unitSoilLayerSchema";
import { setURL } from "src/utils/api";

export type CreateSoilUnitProps = {
  revision_id: string;
  payload: CreateSoilUnitSchemaType;
};

export const createSoilUnit = async ({
  revision_id,
  payload,
}: CreateSoilUnitProps) => {
  const url = setURL(`revision/${revision_id}/soil_unit`);
  await axios.post(url, payload);
};

export type BatchCreateSoilUnitProps = {
  revision_id: string;
  payload: CreateSoilUnitSchemaType[];
};

export const batchCreateSoilUnit = async ({
  revision_id,
  payload,
}: BatchCreateSoilUnitProps) => {
  const url = setURL(`revision/${revision_id}/soil_units`);
  await axios.post(url, { units: payload });
};

export type UpdateSoilUnitProps = {
  soil_unit_id: string;
  payload: CreateSoilUnitSchemaType;
};

export const updateSoilUnit = async ({
  soil_unit_id,
  payload,
}: UpdateSoilUnitProps) => {
  const url = setURL(`soil_unit/${soil_unit_id}`);
  await axios.put(url, payload);
};

export type SetDefaultSoilUnitProps = {
  soil_unit_id: string;
};

export const setDefaultSoilUnit = async ({
  soil_unit_id,
}: SetDefaultSoilUnitProps) => {
  const url = setURL(`soil_unit/${soil_unit_id}/default`);
  await axios.put(url);
};

export type DeleteSoilUnitProps = {
  soil_unit_id: string;
};

export const deleteSoilUnit = async ({ soil_unit_id }: DeleteSoilUnitProps) => {
  const url = setURL(`soil_unit/${soil_unit_id}`);
  await axios.delete(url);
};

type GetRevisionSoilUnitProps = {
  revision_id: string;
};

export const getRevisionSoilUnits = async ({
  revision_id,
}: GetRevisionSoilUnitProps): Promise<SoilUnitSchemaType[]> => {
  const url = setURL(`revision/${revision_id}/soil_units`);
  const response = await axios.get(url);
  if (response.status === 200) {
    return response.data.soil_units;
  }
  return response.data;
};
