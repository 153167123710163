import { useCallback } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import { GeoPlot } from "src/types/plots";

type Props = {
  plots: GeoPlot[];
  selectedPlots: GeoPlot[];
  setSelectedPlots: (plots: GeoPlot[]) => void;
};

export const PlotSelection = ({
  plots,
  selectedPlots,
  setSelectedPlots,
}: Props) => {
  const handleSelect = useCallback(
    (checkedStatus: boolean, plot: GeoPlot) => {
      if (!checkedStatus && selectedPlots.includes(plot)) {
        setSelectedPlots(selectedPlots.filter((p) => p !== plot));
      }
      if (checkedStatus && !selectedPlots.includes(plot)) {
        const newSelection = [...selectedPlots, plot].sort(
          (a, b) => a.order - b.order,
        );
        setSelectedPlots(newSelection);
      }
    },
    [selectedPlots, setSelectedPlots],
  );

  return (
    <div className="grid grid-cols-3">
      {plots.map((plot, i) => (
        <FormControlLabel
          key={plot.id}
          control={
            <Checkbox
              checked={selectedPlots.includes(plot)}
              onChange={(e) => handleSelect(e.target.checked, plot)}
            />
          }
          label={`(${i + 1}) ${plot.title}`}
        />
      ))}
    </div>
  );
};
