import {
  LayerTypeSchema,
  soilLayerDefaultValues,
  SoilLayerType,
} from "src/schemas/soilLayerSchema";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { LocationGroup } from "src/types/locationGroup";
import { findLast } from "src/utils/arrays";
import { roundToDecimal } from "src/utils/math";
import { ClipOption, PasteOption } from "./soil-layering-definitions";

export const setEmptySoilLayer = (depth: number): SoilLayerType => ({
  ...soilLayerDefaultValues,
  depth: roundToDecimal(depth, 2),
});

const setSoilLayersWhenNewSoilLayersAreLonger = (
  newSoilLayers: SoilLayerType[],
  currentSoilLayers: SoilLayerType[],
): SoilLayerType[] => {
  const currentDepths = currentSoilLayers.map((soilLayer) => soilLayer.depth);

  const otherSoilLayers = newSoilLayers
    .filter((soilLayer) => !currentDepths.includes(soilLayer.depth))
    .map((soilLayer) => {
      const prevLayer = findLast(
        currentSoilLayers,
        (layer) => layer.depth < soilLayer.depth,
      );
      return { ...prevLayer, depth: roundToDecimal(soilLayer.depth, 2) };
    }) as SoilLayerType[];

  return [...currentSoilLayers, ...otherSoilLayers].sort(
    (a, b) => a.depth - b.depth,
  );
};

const setSoilLayersWhenNewSoilLayersAreShorter = (
  newSoilLayers: SoilLayerType[],
  currentSoilLayers: SoilLayerType[],
): SoilLayerType[] => {
  const newDepths = newSoilLayers.map((soilLayer) =>
    roundToDecimal(soilLayer.depth, 2),
  );
  return currentSoilLayers.filter((soilLayer) =>
    newDepths.includes(soilLayer.depth),
  );
};

export const reconcileNewSoilLayersFromSoilLayers = (
  newSoilLayers: SoilLayerType[],
  currentSoilLayers: SoilLayerType[],
): SoilLayerType[] => {
  // if the newSoilLayers has the same length as the currentSoilLayers, return the newSoilLayers with metadata from currentSoilLayers
  if (newSoilLayers.length === currentSoilLayers.length) {
    return currentSoilLayers.map((currentSoilLayer, index) => ({
      ...currentSoilLayer,
      depth: roundToDecimal(newSoilLayers[index].depth, 2),
    }));
  }
  // If the newSoilLayers is longer than the currentSoilLayers, then add a new empty layer for the extra depth
  if (
    newSoilLayers.length > currentSoilLayers.length &&
    currentSoilLayers.length > 0
  ) {
    return setSoilLayersWhenNewSoilLayersAreLonger(
      newSoilLayers,
      currentSoilLayers,
    );
  }
  // If the newSoilLayers is shorter than the currentSoilLayers, then return the currentSoilLayers with the extra layers removed
  if (
    newSoilLayers.length < currentSoilLayers.length &&
    newSoilLayers.length > 0
  ) {
    return setSoilLayersWhenNewSoilLayersAreShorter(
      newSoilLayers,
      currentSoilLayers,
    );
  }

  // If none applies then it means the currentSoilLayers is empty and the newSoilLayers is not empty
  return newSoilLayers.map((soilLayer) => setEmptySoilLayer(soilLayer.depth));
};

type SetLayerForPLotProps = {
  layers: SoilLayerType[];
  soilUnits: SoilUnitSchemaType[];
  max_depth: number;
};

export type SetLayerForPLotReturn = {
  from_depth: number;
  to_depth: number;
  color: string;
  soilUnitName: string;
}[];

export const setLayerForPLot = ({
  layers,
  soilUnits,
  max_depth,
}: SetLayerForPLotProps): SetLayerForPLotReturn => {
  const nLayers = layers.length;
  const ans = layers.reduce((acc, layer, i) => {
    const soilUnit = soilUnits.find(
      (soilUnit) => soilUnit.soil_unit_id === layer.soil_unit_id,
    );
    const to_depth = i === nLayers - 1 ? max_depth : layers[i + 1].depth;
    acc.push({
      from_depth: layer.depth,
      to_depth,
      color: soilUnit?.color || "black",
      soilUnitName: soilUnit?.name || "Unknown",
    });
    return acc;
  }, [] as SetLayerForPLotReturn);

  return ans;
};

export const assignSoilUnitIdToSubLayers = (
  soilLayers: SoilLayerType[],
): SoilLayerType[] => {
  let lastLayerIndex: number | null = null; // To track the index of the most recent "LAYER"

  return soilLayers.map((layer, index) => {
    if (layer.layer_type === LayerTypeSchema.Values.LAYER) {
      // If it's a "LAYER", store its index
      lastLayerIndex = index;
      return layer; // Return the "LAYER" as is
    } else if (
      layer.layer_type === LayerTypeSchema.Values.SUBLAYER &&
      lastLayerIndex !== null
    ) {
      // If it's a "SUBLAYER", update the soil_unit_id of the last "LAYER"
      layer.soil_unit_id = soilLayers[lastLayerIndex].soil_unit_id;
    }
    return layer; // Return the "SUBLAYER" or the updated "LAYER"
  });
};

type CopyPasteOptions = {
  pasteOption: PasteOption;
  clipOption?: ClipOption;
};

export function handleCopyPasteLayersAndMaxDepth(
  selectedLocationGroupForCopy: LocationGroup,
  targetLocationGroups: LocationGroup[],
  options: CopyPasteOptions,
): LocationGroup[] {
  // if (!selectedLocationGroupForCopy) {
  //   console.warn("No location group selected for copy.");
  //   return targetLocationGroups;
  // }

  const { pasteOption, clipOption } = options;

  // Determine the maximum depth of the layers to adjust depth if needed
  const calculateMaxDepthFromLayers = (layers: SoilLayerType[]): number =>
    layers.reduce((maxDepth, layer) => Math.max(maxDepth, layer.depth), 0);

  // Copy layers based on paste option
  return targetLocationGroups.map((targetGroup) => {
    const updatedGroup = { ...targetGroup };

    if (
      pasteOption === PasteOption.COPY_LAYERS ||
      pasteOption === PasteOption.COPY_LAYERS_AND_MAX_DEPTH
    ) {
      updatedGroup.layers = [...selectedLocationGroupForCopy.layers];

      if (pasteOption === PasteOption.COPY_LAYERS_AND_MAX_DEPTH) {
        updatedGroup.max_depth = selectedLocationGroupForCopy.max_depth;
      } else if (pasteOption === PasteOption.COPY_LAYERS && clipOption) {
        const copiedLayersMaxDepth = calculateMaxDepthFromLayers(
          selectedLocationGroupForCopy.layers,
        );

        if (clipOption === ClipOption.CLIP_LAYERS_TO_FIT_MAX_DEPTH) {
          updatedGroup.layers = selectedLocationGroupForCopy.layers.filter(
            (layer) => layer.depth <= targetGroup.max_depth,
          );
        } else if (clipOption === ClipOption.ADJUST_MAX_DEPTH_TO_FIT_LAYERS) {
          updatedGroup.max_depth = copiedLayersMaxDepth;
        }
      }
    } else if (pasteOption === PasteOption.COPY_MAX_DEPTH) {
      updatedGroup.max_depth = selectedLocationGroupForCopy.max_depth;
    }

    return updatedGroup;
  });
}
