import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";

import { getAccessToken } from "src/api/auth";
import { AppContent } from "src/components/app/AppContent";

export const Route = createFileRoute("/accesstoken")({
  component: AccessTokenComponent,
});

function AccessTokenComponent() {
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessToken();
      setAccessToken(accessToken);
    })();
  }, []);
  return (
    <AppContent>
      <Box className="max-w-wider mx-auto">
        <Typography variant="h4" className="pb-4">
          Access Token
        </Typography>

        {accessToken}
      </Box>
    </AppContent>
  );
}
