import { useEffect } from "react";
import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { boundingExtent } from "ol/extent";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";

import { Location } from "src/types/locations";
import {
  convertCoordinate,
  findProjectionDefinitionBySrid,
  mapInitSetting,
  mapZoomSettings,
} from "./map-utils";
import { useMapContext } from "./MapContext";

interface MapScaleProps {
  locations: Location[];
}

export const MapScale = ({ locations }: MapScaleProps) => {
  const map = useMapContext();

  useEffect(() => {
    const updateMapScale = () => {
      const features: Feature[] = locations
        .map((location) => {
          const {
            point_easting,
            point_northing,
            point_x_wgs84_web,
            point_y_wgs84_web,
            srid,
            name,
          } = location;

          let coordinates: Coordinate | null = null;

          try {
            // Handle conversion using dynamic SRID
            if (
              point_easting !== undefined &&
              point_northing !== undefined &&
              srid
            ) {
              const projectionDefinition = findProjectionDefinitionBySrid(srid);
              if (projectionDefinition) {
                coordinates = convertCoordinate(
                  `${mapInitSetting.sridView}`, // Converting to EPSG:3857 for map view
                  [point_easting, point_northing],
                ) as Coordinate;
              } else {
                console.warn(
                  `SRID ${srid} not found in projection definitions.`,
                );
              }
            }
            // Fallback to point_x_wgs84_web and point_y_wgs84_web (assumed to be in EPSG:4326)
            else if (
              point_x_wgs84_web !== undefined &&
              point_y_wgs84_web !== undefined
            ) {
              coordinates = fromLonLat([point_x_wgs84_web, point_y_wgs84_web]);
            }

            if (coordinates) {
              return new Feature({
                isLocation: true,
                geometry: new Point(coordinates),
                names: [name],
                count: 1,
              });
            }
          } catch (error) {
            console.error(
              `Error transforming coordinates for location ${name}:`,
              error,
            );
          }
          return null;
        })
        .filter(Boolean) as Feature[];

      if (features.length > 0) {
        const extent = boundingExtent(
          features.map(
            (feature) =>
              (feature.getGeometry() as Point).getCoordinates() as [
                number,
                number,
              ],
          ),
        );
        map.getView().fit(extent, { ...mapZoomSettings, maxZoom: undefined });
      }
    };

    updateMapScale(); // Initial scale update based on the locations

    // If the locations change, recalculate the map scale
  }, [map, locations]);

  return null;
};
