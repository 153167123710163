import { Typography } from "@mui/material";

import { NButton, NCard, NCardContent } from "@ngi/react-component";

type Props = {
  setOpenAddProject: (open: boolean) => void;
};

export const NoProject = ({ setOpenAddProject }: Props) => (
  <NCard className="mt-4">
    <NCardContent className="!p-4">
      <div className="flex gap-4 justify-center items-center">
        <Typography variant="body1">
          No projects found. You need to import projects from FieldManager
          first:
        </Typography>
        <NButton variant="text" onClick={() => setOpenAddProject(true)}>
          Add projects from FieldManager
        </NButton>
      </div>
    </NCardContent>
  </NCard>
);
