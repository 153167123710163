import { Troubleshoot } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { NIconButton } from "@ngi/react-component";
import { LocationGroup } from "src/types/locationGroup";
import { TableCustomCell } from "src/types/table";

export const LocationGroupCPTPreviewCellFactory = (
  handleLocationGroupPreview: (
    location_group_id: string,
    triggerOpendialog: boolean,
  ) => void,
) => {
  const Component = ({
    getValue,
  }: TableCustomCell<
    LocationGroup,
    LocationGroup["location_group_id"]
  >) => {
    const location_group_id = getValue() || "";

    return (
      <Box>
        <Tooltip title="Preview CPT" placement="right-end">
          <NIconButton
            onClick={() => {
              handleLocationGroupPreview(location_group_id, true);
            }}
          >
            <Troubleshoot />
          </NIconButton>
        </Tooltip>
      </Box>
    );
  };

  Component.displayName = "LocationGroupCPTPreviewCell";
  return Component;
};
