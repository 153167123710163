import { Box } from "@mui/material";

import { NCard, NCardHeader } from "@ngi/react-component";

type Props = {
  title: string;
  actions?: React.ReactNode;
};

export const PageContentHeader = ({ title, actions }: Props) => (
  <Box>
    <NCard>
      <NCardHeader
        className="h-[72px]"
        title={title}
        action={actions || null}
      />
    </NCard>
  </Box>
);
