import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import { SaveStatus } from "src/types/status";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  status: SaveStatus;
};

export const SaveSnackbar = ({ open, setOpen, status }: Props) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const text =
    status === "SAVED"
      ? "Data saved to the database"
      : "Data failed to save to the database";

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
        <Alert
          onClose={handleClose}
          severity={
            status === "SAVED" || status === "IDLE" ? "success" : "error"
          }
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};
