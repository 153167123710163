import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { LayerTypeSchema, SoilLayerType } from "src/schemas/soilLayerSchema";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { TableCustomCell } from "src/types/table";

type Props = TableCustomCell<SoilLayerType, SoilLayerType["soil_unit_id"]>;

export const SoilUnitCellFactory = (soilUnits: SoilUnitSchemaType[]) => {
  const component = ({ getValue, row, column, table }: Props) => {
    const options = soilUnits.map((soilUnit) => ({
      value: soilUnit.soil_unit_id,
      label: soilUnit.name,
    }));
    const soilUnit = getValue() || options[0].value;
    const updateData = table.options.meta?.updateData;
    const isSubLayer =
      row.original.layer_type === LayerTypeSchema.Values.SUBLAYER;

    const handleChange = (event: SelectChangeEvent) => {
      if (updateData) {
        updateData(row.index, column.id, event.target.value as string);
      }
    };

    return (
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          className="SelectCell"
          id="demo-simple-select"
          value={soilUnit}
          displayEmpty
          onChange={handleChange}
          disabled={isSubLayer}
          // sx={borderOnlyOnHover}
        >
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  component.displayName = "SoilUnitCell";
  return component;
};
