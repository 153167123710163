import * as turf from "@turf/turf"; // Import Turf.js
import { Feature, Point } from "geojson";
/*
 * This file contains utility functions for map element extracted out of FieldManager.
 * TODO: once @ngi/openlayers is compatible with React 18, maybe revisit this file
 */
import { Coordinate } from "ol/coordinate";
import { fromLonLat, ProjectionLike } from "ol/proj";
import proj4 from "proj4";

import { Location } from "src/types/locations";
import { MapInitSetting, ProjectionDefinition, SRID } from "src/types/map";
import projections from "./projections";

/**
 * The default map view project in Field Manager is EPSG:3857.
 */
export const mapInitSetting: MapInitSetting = {
  sridView: 3857,
  zoom: 7,
  center: [1187261, 8384696],
};

export const mapZoomSettings = { padding: [30, 30, 30, 30], duration: 1000 };

export const DEFAULT_MAP_VIEW_PROJECTION: ProjectionLike = `EPSG:${mapInitSetting.sridView}`;

export const findProjectionDefinitionBySrid = (
  sridOrig: string | number,
): ProjectionDefinition | undefined => {
  let srid = sridOrig;

  if (typeof sridOrig === "string") {
    srid = parseInt(sridOrig);
  }

  if (Object.prototype.hasOwnProperty.call(projections, srid)) {
    return projections[srid as SRID];
  } else {
    return undefined;
  }
};

/**
 * Convert coordinate from current map view projection to another projection.
 */
export const convertCoordinate = (sridTo: string, coordinate: Coordinate) => {
  const projectionTo = findProjectionDefinitionBySrid(sridTo);
  const projectionFrom = findProjectionDefinitionBySrid(
    mapInitSetting.sridView,
  );

  if (projectionTo && projectionFrom) {
    const newCoordinate = proj4(
      projectionFrom.definition.data,
      projectionTo.definition.data,
      coordinate,
    );
    return newCoordinate;
  }
};

type LocationWithCoordinates = {
  siite_location_id: string;
  coordinates: Coordinate | null;
};

const convertLocationCoordinates = (
  location: Location,
): LocationWithCoordinates | null => {
  const {
    siite_location_id,
    point_easting,
    point_northing,
    srid,
    point_x_wgs84_web,
    point_y_wgs84_web,
  } = location;
  let coordinates: Coordinate | null = null;

  try {
    // Handle conversion using dynamic SRID
    if (point_easting !== undefined && point_northing !== undefined && srid) {
      const projectionDefinition = findProjectionDefinitionBySrid(srid);
      if (projectionDefinition) {
        coordinates = convertCoordinate(
          `${mapInitSetting.sridView}`, // Converting to EPSG:3857 for map view
          [point_easting, point_northing],
        ) as Coordinate;
      } else {
        console.warn(`SRID ${srid} not found in projection definitions.`);
      }
    }
    if (point_x_wgs84_web !== undefined && point_y_wgs84_web !== undefined) {
      coordinates = fromLonLat([point_x_wgs84_web, point_y_wgs84_web]);
    }
    return { siite_location_id, coordinates };
  } catch (error) {
    console.error(
      `Error transforming coordinates for location ${location.siite_location_id}:`,
      error,
    );
  }
  return null;
};

type TurfLocation = Feature<Point, { location: Location }>;

export const findLocationsInPolygon = (
  polygon: Coordinate[][],
  locations: Location[],
): Location[] | null => {
  // Convert the polygon into a Turf.js polygon
  const turfPolygon = turf.polygon(polygon);
  const polyFC = turf.featureCollection([turfPolygon]);

  // Convert locations to Turf.js points and check if they are inside the polygon
  const locationsInPolygon = locations
    .map((location) => {
      const locationWithCoordinates = convertLocationCoordinates(location);
      if (
        !locationWithCoordinates ||
        locationWithCoordinates.coordinates === null
      )
        return null;

      // Create a Turf point
      const turfPoint = turf.point(
        locationWithCoordinates.coordinates as Coordinate,
        { location },
      );
      return turfPoint;
    })
    .filter((v) => v !== null); // Filter out null values

  if (!locationsInPolygon || locationsInPolygon.length === 0) return null;

  const pointsFC = turf.featureCollection(locationsInPolygon as TurfLocation[]);

  // Use Turf.js to check which points are inside the polygon
  const collected = turf.collect(polyFC, pointsFC, "location", "values");

  // Extract the values (locations) from the collection
  const values = collected.features[0]?.properties?.values;

  return values ?? null;
};
