import { Box } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { rowSelectionColumn } from "src/components/common/datatable/features/rowSelection";
import { useRowSelection } from "src/components/common/datatable/hooks/useRowSelection";
import { FieldManagerLocation } from "src/types/locations";

type Props = {
  project_id: string;
  locations: FieldManagerLocation[];
  selectedLocations: string[] | null;
  onSelectedLocationChange: (locations: string[] | null) => void;
};

const tableColumnHelper = createColumnHelper<FieldManagerLocation>();

const columns = [
  rowSelectionColumn(),
  tableColumnHelper.accessor("name", {
    cell: (row) => row.getValue(),
    header: "Name",
    size: 400,
    sortUndefined: "last", //force undefined values to the end
    sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
  }),
  tableColumnHelper.accessor("point_easting", {
    cell: (row) => row.getValue(),
    header: "Easting",
    size: 100,
    sortUndefined: "last", //force undefined values to the end
    sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
    enableColumnFilter: false, // FIXME: sorting does not work for number for now
  }),
  tableColumnHelper.accessor("point_northing", {
    cell: (row) => row.getValue(),
    header: "Northing",
    size: 100,
    sortUndefined: "last", //force undefined values to the end
    sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
    enableColumnFilter: false, // FIXME: sorting does not work for number for now
  }),
  tableColumnHelper.accessor("point_z", {
    cell: (row) => row.getValue(),
    header: "Water Depth",
    size: 100,
    sortUndefined: "last", //force undefined values to the end
    sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
    enableColumnFilter: false, // FIXME: sorting does not work for number for now
  }),
];

export const SelectLocationTable = ({
  project_id,
  locations,
  selectedLocations,
  onSelectedLocationChange,
}: Props) => {
  const { rowSelection, handleOnRowSelectionChange } =
    useRowSelection<FieldManagerLocation>({
      data: locations,
      rowKey: "location_id",
      initialRowSelection: selectedLocations,
      onRowSelectionChange: onSelectedLocationChange,
    });

  return (
    <Box>
      <Datatable
        data={locations}
        columns={columns}
        usePagination
        useRowSelection
        rowSelection={rowSelection}
        setRowSelection={handleOnRowSelectionChange}
        getRowId={(row: FieldManagerLocation) => row.location_id}
      />
    </Box>
  );
};
