import { useCallback, useMemo } from "react";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { NBaseAlert, NButton, NDialog } from "@ngi/react-component";
import { deleteRevisionSoilUnitMutationQuery } from "src/queries/mutations";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  revision_id: string;
  soilUnit: SoilUnitSchemaType;
};

export const DeleteSoilUnitDialog = ({
  open,
  setOpen,
  revision_id,
  soilUnit,
}: Props) => {
  const deleteSoilUnitMutation = useMutation(
    deleteRevisionSoilUnitMutationQuery(revision_id),
  );

  const handleDeleteSoilUnit = useCallback(async () => {
    deleteSoilUnitMutation.mutate({
      soil_unit_id: soilUnit.soil_unit_id,
    });
    setOpen(false);
  }, [deleteSoilUnitMutation, setOpen, soilUnit.soil_unit_id]);

  const actions = useMemo(
    () => (
      <>
        <NButton
          onClick={handleDeleteSoilUnit}
          variant="destructive"
          startIcon={<Delete />}
        >
          Delete soil unit
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    ),
    [handleDeleteSoilUnit, setOpen],
  );

  return (
    <NDialog
      dialogTitle="Delete soil unit"
      onClose={() => setOpen(false)}
      open={open}
      actions={actions}
    >
      <Box>
        <NBaseAlert
          title="Are you sure you want to delete this soil unit?"
          description="The operation cannot be undone."
          severity="warning"
        />
      </Box>
    </NDialog>
  );
};
