import React from "react";

import { getLineStroke } from "src/components/common/plots/utils/lineSroke";
import { PlotLegendItem } from "src/types/plotElements";

type Props = {
  items: PlotLegendItem[];
  classname?: string;
};

export const Legend: React.FC<Props> = ({ items, classname }) => (
  <div className={`Legend ${classname}`}>
    {items.map((item) => (
      <div key={item.id} style={{ display: "flex", alignItems: "center" }}>
        <svg height="10" width="40" style={{ marginRight: "8px" }}>
          <line
            x1="0"
            y1="5"
            x2="40"
            y2="5"
            stroke={item.lineColor}
            strokeWidth={item.lineWidth}
            strokeDasharray={getLineStroke(item.lineStrokeStyle)}
          />
        </svg>
        <span className="text-sm">{item.label}</span>
      </div>
    ))}
  </div>
);
