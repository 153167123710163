import { Box } from "@mui/material";

import { NChip } from "@ngi/react-component";
import { LocationGroup } from "src/types/locationGroup";
import { LocationNameMapping } from "src/types/locations";
import { TableCustomCell } from "src/types/table";

export const LocationNameCellFactory = (
  locationNameMapping: LocationNameMapping,
) => {
  const Component = ({
    getValue,
  }: TableCustomCell<LocationGroup, LocationGroup["location_ids"]>) => {
    const location_ids = getValue() || [];

    const locationNames = location_ids.map(
      (id) => locationNameMapping[id] || id,
    );

    return (
      <Box className="flex gap-4">
        {locationNames &&
          locationNames.map((name, i) => <NChip key={i} label={name} />)}
      </Box>
    );
  };

  Component.displayName = "LocationNameCell";
  return Component;
};
