import { Box } from "@mui/material";

import { NInlineAlert } from "@ngi/react-component";
import { LocationGroupActions } from "src/types/locationGroup";

type Props = {
  locationGroupBinnedActions: LocationGroupActions;
};

export const LocationGroupStatusChange = ({
  locationGroupBinnedActions,
}: Props) => (
  <Box className="flex items-center gap-4">
    <NInlineAlert severity="info">
      {locationGroupBinnedActions.add.length} will be added
    </NInlineAlert>
    <NInlineAlert severity="info">
      {locationGroupBinnedActions.update.length} will be updated
    </NInlineAlert>
    <NInlineAlert severity="info">
      {locationGroupBinnedActions.remove.length} will be deleted
    </NInlineAlert>
  </Box>
);
