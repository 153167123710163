import { useState } from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { NButton, NIconButton } from "@ngi/react-component";
import {
  InlineLoadingComponent,
  InlineLoadingErrorComponent,
} from "src/components/LoadingComponent";
import {
  getProjectPhaseRevisionsQuery,
  getProjectPhasesQuery,
} from "src/queries/queries";
import { PhaseRevision } from "src/types/api";
import { ProjectPhase } from "src/types/projectPhase";
import { CreateOrUpdateProjectPhase } from "./CreateOrUpdateProjectPhase";
import { CreateOrUpdateProjectPhaseRevision } from "./CreateOrUpdateProjectPhaseRevision";
import { DeleteProjectPhase } from "./DeleteProjectPhase";
import { ProjectPhaseRevisionItem } from "./ProjectPhaseRevisionItem";

type Props = {
  project_id: string;
  phase: ProjectPhase;
};

export const ProjectPhaseItem = ({ project_id, phase }: Props) => {
  const [openUpdateProjectPhase, setOpenUpdateProjectPhase] = useState(false);
  const [openCreateProjectPhaseRevision, setOpenCreateProjectPhaseRevision] =
    useState(false);
  const [openDeleteProjectPhase, setOpenDeleteProjectPhase] = useState(false);

  const { data: projectPhases } = useQuery(getProjectPhasesQuery(project_id));

  const {
    data: phaseRevisions,
    isPending,
    isSuccess,
    isError,
  } = useQuery(getProjectPhaseRevisionsQuery(project_id, phase.phase_id));

  const phaseStatus =
    phaseRevisions && phaseRevisions.length > 0
      ? "hasRevisions"
      : "locationNoRevisions";

  const locationNoRevisions = (
    <div className="flex gap-4 justify-center items-center">
      <Typography variant="body1">No revision found:</Typography>
      <NButton
        variant="text"
        onClick={() => setOpenCreateProjectPhaseRevision(true)}
      >
        Create a revision
      </NButton>
    </div>
  );

  return (
    <Box>
      <Paper className="ProjectPhaseItem w-full flex flex-col">
        <Box className="flex w-full items-center border-b border-neutral-400 border-solid">
          <Box className="Info h-10 flex flex-row grow items-center gap-4 px-2">
            <Typography variant="h6">{phase.name}</Typography>
            <Typography
              variant="body1"
              className="hidden sm:block sm:truncate sm:..."
            >
              {phase.description}
            </Typography>
          </Box>
          <Box className="Actions">
            <Tooltip title="Add revision" placement="right-end">
              <NIconButton
                onClick={() => setOpenCreateProjectPhaseRevision(true)}
              >
                <Add />
              </NIconButton>
            </Tooltip>
            <Tooltip title="Edit phase" placement="right-end">
              <NIconButton onClick={() => setOpenUpdateProjectPhase(true)}>
                <Edit />
              </NIconButton>
            </Tooltip>

            <Tooltip title="Delete phase" placement="right-end">
              <NIconButton onClick={() => setOpenDeleteProjectPhase(true)}>
                <Delete />
              </NIconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box className="Revisions bg-neutral-50 w-full px-2 rounded-b divide-y divide-solid divide-neutral-400">
          {isError && (
            <InlineLoadingErrorComponent label="Failed to fetch revisions" />
          )}
          {isPending && <InlineLoadingComponent />}
          {isSuccess &&
            phaseStatus === "locationNoRevisions" &&
            locationNoRevisions}
          {isSuccess &&
            phaseStatus === "hasRevisions" &&
            phaseRevisions.map((revision: PhaseRevision) => (
              <ProjectPhaseRevisionItem
                key={revision.revision_id}
                project_id={project_id}
                phase_id={phase.phase_id}
                revision={revision}
                projectPhases={projectPhases as ProjectPhase[]}
              />
            ))}
        </Box>
      </Paper>
      {openUpdateProjectPhase && (
        <CreateOrUpdateProjectPhase
          open={openUpdateProjectPhase}
          setOpen={setOpenUpdateProjectPhase}
          project_id={project_id}
          phase_id={phase.phase_id}
          initialData={{ name: phase.name, description: phase.description }}
        />
      )}
      {openDeleteProjectPhase && (
        <DeleteProjectPhase
          open={openDeleteProjectPhase}
          setOpen={setOpenDeleteProjectPhase}
          project_id={project_id}
          phase_id={phase.phase_id}
        />
      )}
      {openCreateProjectPhaseRevision && (
        <CreateOrUpdateProjectPhaseRevision
          open={openCreateProjectPhaseRevision}
          setOpen={setOpenCreateProjectPhaseRevision}
          project_id={project_id}
          phase_id={phase.phase_id}
          projectPhases={projectPhases}
        />
      )}
    </Box>
  );
};
