import { v4 as uuidv4 } from "uuid";

import { CreateLocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";
import { euclideanDistance } from "./calculateDistance";
import { findCommonSubstring } from "./string-analysis";

export function createLocationGroup(
  locations: Location[],
): CreateLocationGroup {
  return {
    location_group_id: uuidv4(),
    name: locations[0].name,
    location_ids: locations.map((g) => g.siite_location_id),
  };
}

export function groupLocationByRelativeDistance(
  locations: Location[],
  distance: number,
  groupUndefinedCoordinates: boolean = false, // New parameter to control grouping of undefined coordinates
): CreateLocationGroup[] {
  const groups: Location[][] = [];
  const usedLocations = new Set<string>();

  for (let i = 0; i < locations.length; i++) {
    const location1 = locations[i];

    // Handle undefined easting or northing
    if (
      location1.point_easting === undefined ||
      location1.point_northing === undefined
    ) {
      if (
        groupUndefinedCoordinates &&
        !usedLocations.has(location1.siite_location_id)
      ) {
        groups.push([location1]);
        usedLocations.add(location1.siite_location_id);
      } else if (
        !groupUndefinedCoordinates &&
        !usedLocations.has(location1.siite_location_id)
      ) {
        groups.push([location1]);
        usedLocations.add(location1.siite_location_id);
      }
      continue;
    }

    if (usedLocations.has(location1.siite_location_id)) continue;

    const group: Location[] = [location1];
    usedLocations.add(location1.siite_location_id);

    for (let j = i + 1; j < locations.length; j++) {
      const location2 = locations[j];

      // Ensure both easting and northing are present
      if (
        usedLocations.has(location2.siite_location_id) ||
        location2.point_easting === undefined ||
        location2.point_northing === undefined
      )
        continue;

      const dist = euclideanDistance(
        [location1.point_easting, location1.point_northing],
        [location2.point_easting, location2.point_northing],
      );

      if (dist <= distance) {
        group.push(location2);
        usedLocations.add(location2.siite_location_id);
      }
    }

    groups.push(group);
  }

  return groups.reduce((acc, group, i) => {
    acc.push({
      location_group_id: uuidv4(),
      name: `Group ${i}`,
      location_ids: group.map((g) => g.siite_location_id),
    });
    return acc;
  }, [] as CreateLocationGroup[]);
}

export function groupedLocationAsLocationId(groups: Location[][]): string[][] {
  return groups.map((group) =>
    group.map((location) => location.siite_location_id),
  );
}

type GroupBySimilarity = { name: string; locations: Location[] };
export function groupLocationsByNameSimilarity(
  locations: Location[],
  stringLength: number = 2,
): CreateLocationGroup[] {
  const groups: GroupBySimilarity[] = [];

  const findGroup = (location: Location): GroupBySimilarity | undefined => {
    for (const group of groups) {
      const commonSubstring = findCommonSubstring(
        group.locations.map((loc) => loc.name).concat(location.name),
      );
      if (commonSubstring.length >= stringLength) {
        // Adjust the threshold as needed
        return group;
      }
    }
    return undefined;
  };

  for (const location of locations) {
    const group = findGroup(location);
    if (group) {
      group.locations.push(location);
    } else {
      const newGroupName = findCommonSubstring([location.name]);
      groups.push({ name: newGroupName, locations: [location] });
    }
  }

  // Update group names to the common substring of all group members
  for (const group of groups) {
    group.name = findCommonSubstring(group.locations.map((loc) => loc.name));
  }

  return groups.reduce((acc, group) => {
    acc.push({
      location_group_id: uuidv4(),
      name: group.name,
      location_ids: group.locations.map((g) => g.siite_location_id),
    });
    return acc;
  }, [] as CreateLocationGroup[]);
}

export function autoGroupLocations(
  locations: Location[],
): CreateLocationGroup[] {
  const grouped: Record<string, Location[]> = {};

  locations.forEach((location) => {
    const baseGroup = location.name.replace(/[-a-z]+$/i, ""); // Remove suffixes like '-a', '-alt', etc.
    if (!grouped[baseGroup]) {
      grouped[baseGroup] = [];
    }
    grouped[baseGroup].push(location);
  });

  return Object.entries(grouped).map(([name, locations]) => ({
    location_group_id: uuidv4(),
    name,
    location_ids: locations.map((location) => location.siite_location_id),
  }));
}
