import axios from "axios";

import {
  projectPhaseSchema,
  ProjectPhaseSchemaType,
} from "src/schemas/projectPhaseSchema";
import { setURL } from "src/utils/api";

export type CreateProjectPhaseProps = {
  project_id: string;
  payload: ProjectPhaseSchemaType;
};

export const createProjectPhase = async ({
  project_id,
  payload,
}: CreateProjectPhaseProps) => {
  const parsed_payload = projectPhaseSchema.safeParse(payload);
  if (parsed_payload.success) {
    const url = setURL(`project/${project_id}/phase`);
    await axios.post(url, parsed_payload.data);
  }
};

export type UpdateProjectPhaseProps = {
  phase_id: string;
  payload: ProjectPhaseSchemaType;
};

export const updateProjectPhase = async ({
  phase_id,
  payload,
}: UpdateProjectPhaseProps) => {
  const parsed_payload = projectPhaseSchema.safeParse(payload);
  if (parsed_payload.success) {
    const url = setURL(`phase/${phase_id}`);
    await axios.patch(url, parsed_payload.data);
  }
};

export type DeletProjectPhaseProps = {
  phase_id: string;
};

export const deleteProjectPhase = async ({
  phase_id,
}: DeletProjectPhaseProps) => {
  const params = {}; // TODO: is this needed?
  const url = setURL(`phase/${phase_id}`);
  await axios.delete(url, params);
};
