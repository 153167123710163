import { useCallback, useMemo } from "react";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { NBaseAlert, NButton, NDialog } from "@ngi/react-component";
import { deleteProjectPhaseRevisionMutationQuery } from "src/queries/mutations";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  project_id: string;
  phase_id: string;
  revision_id: string;
};

export const DeleteProjectPhaseRevision = ({
  open,
  setOpen,
  project_id,
  phase_id,
  revision_id,
}: Props) => {
  const deleteProjectPhaseRevisionMutation = useMutation(
    deleteProjectPhaseRevisionMutationQuery(project_id, phase_id),
  );

  const handleDeleteProjectPhaseRevision = useCallback(async () => {
    deleteProjectPhaseRevisionMutation.mutate({
      revision_id,
    });
    setOpen(false);
  }, [deleteProjectPhaseRevisionMutation, revision_id, setOpen]);

  const deleteRevisionActions = useMemo(
    () => (
      <>
        <NButton
          onClick={handleDeleteProjectPhaseRevision}
          variant="destructive"
          startIcon={<Delete />}
        >
          Delete Revision
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    ),
    [handleDeleteProjectPhaseRevision, setOpen],
  );

  return (
    <NDialog
      dialogTitle="Delete Project Phase Revision"
      onClose={() => setOpen(false)}
      open={open}
      actions={deleteRevisionActions}
    >
      <Box>
        <NBaseAlert
          title="Are you sure you want to delete this revision?"
          description="The operation cannot be undone."
          severity="warning"
        />
      </Box>
    </NDialog>
  );
};
