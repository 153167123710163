import { useEffect } from "react";
import { Feature, MapBrowserEvent, Overlay } from "ol";
import { Coordinate } from "ol/coordinate";
import { Listener } from "ol/events";
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj"; // For coordinate transformation
import Cluster from "ol/source/Cluster";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Style, { StyleFunction } from "ol/style/Style";

import { Location } from "src/types/locations"; // Assuming this is the correct import path
import {
  convertCoordinate,
  findProjectionDefinitionBySrid,
  mapInitSetting,
} from "./map-utils"; // The file where the projections are defined
import { useMapContext } from "./MapContext";
import { TooltipElement } from "./TooltipElement";

interface LocationLayerProps {
  locations: Location[];
  pointColor?: string; // Color for points, default is red
}

export const LocationLayer = ({
  locations,
  pointColor = "rgba(255, 0, 0, 0.5)",
}: LocationLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    const distance = 40; // distance to group points in clusters

    // Creating the cluster source
    const clusterSource = new Cluster({
      distance: distance,
      source: new VectorSource(), // The source will be updated with the passed locations
    });

    const vectorLayer = new VectorLayer({
      source: clusterSource,
      style: createLocationClusterStyleFunction(pointColor, map), // Use the wrapped function to pass the color
    });
    map.addLayer(vectorLayer);

    const updateLocationFeatures = () => {
      const features: Feature[] = locations
        .map((location) => {
          const {
            point_easting,
            point_northing,
            point_x_wgs84_web,
            point_y_wgs84_web,
            srid,
            name,
          } = location;

          let coordinates: Coordinate | null = null;

          try {
            // Handle conversion using dynamic SRID
            if (
              point_easting !== undefined &&
              point_northing !== undefined &&
              srid
            ) {
              const projectionDefinition = findProjectionDefinitionBySrid(srid);
              if (projectionDefinition) {
                coordinates = convertCoordinate(
                  `${mapInitSetting.sridView}`, // Converting to EPSG:3857 for map view
                  [point_easting, point_northing],
                ) as Coordinate;
              } else {
                console.warn(
                  `SRID ${srid} not found in projection definitions.`,
                );
              }
            }
            // Fallback to point_x_wgs84_web and point_y_wgs84_web (assumed to be in EPSG:4326)
            else if (
              point_x_wgs84_web !== undefined &&
              point_y_wgs84_web !== undefined
            ) {
              coordinates = fromLonLat([point_x_wgs84_web, point_y_wgs84_web]);
            }

            if (coordinates) {
              return new Feature({
                isLocation: true,
                geometry: new Point(coordinates),
                names: [name],
                count: 1,
              });
            }
          } catch (error) {
            console.error(
              `Error transforming coordinates for location ${name}:`,
              error,
            );
          }
          return null;
        })
        .filter(Boolean) as Feature[];

      const vectorSource = new VectorSource({ features });
      clusterSource.setSource(vectorSource);
    };

    updateLocationFeatures(); // Initialize with passed locations

    const tooltip = new TooltipElement();
    const tooltipOverlay = new Overlay({
      element: tooltip.element,
      offset: [10, 0],
      positioning: "top-left",
    });

    map.addOverlay(tooltipOverlay);

    const onPointerMove = (event: MapBrowserEvent<UIEvent>) => {
      const feature = map.forEachFeatureAtPixel(
        event.pixel,
        (feature) => feature,
      );
      if (feature) {
        const isLocation = feature.get("isLocation");
        if (!isLocation) return;

        const coordinates = (feature.getGeometry() as Point).getCoordinates();
        const clusterFeatures = feature.get("features");

        let content = "";
        if (clusterFeatures.length === 1) {
          const singleFeature = clusterFeatures[0];
          const names = singleFeature.get("names");

          content = `
            <h4 style="font-size: 20px; font-weight: bold;">Locations (1)</h4>
            <ul style="list-style: inside">
              <li>${names.join("</li><li>")}</li>
            </ul>`;
        } else {
          content = `<h4 style="font-size: 20px; font-weight: bold;">Clustered Locations (${clusterFeatures.length})</h4>`;
        }

        tooltipOverlay.setPosition(coordinates);
        tooltip.setContent(content);
        tooltip.show();
      } else {
        tooltipOverlay.setPosition(undefined);
        tooltip.hide();
      }
    };

    map.on("pointermove", onPointerMove);

    return () => {
      map.removeEventListener("pointermove", onPointerMove as Listener);
    };
  }, [map, locations, pointColor]);

  return null;
};

// Function to create a style function based on the color
const createLocationClusterStyleFunction =
  (pointColor: string, map): StyleFunction =>
  (feature) => {
    const size = feature.get("features").length;
    let text: Text;
    const mapExtent = map.getView().calculateExtent(map.getSize());

    // Assume single feature when size === 1
    if (size === 1) {
      const originalFeature = feature.get("features")[0];
      const names = originalFeature.get("names");

      const coordinates = (feature.getGeometry() as Point).getCoordinates();
      const textAlign = getDynamicTextAlignment(coordinates, mapExtent);
      const offsetX = textAlign === "left" ? 20 : -20; // Adjust offset dynamically

      text = new Text({
        text: names.toString(),
        font: "12px Arial",
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({ color: "white", width: 5 }),
        textAlign: textAlign, // Dynamic alignment based on position
        textBaseline: "middle",
        offsetX: offsetX, // Offset adjusted to avoid clipping
        overflow: true,
      });
    } else {
      text = new Text({
        text: size.toString(),
        font: "bold 14px Arial",
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({ color: "white", width: 5 }),
        textAlign: "center",
        textBaseline: "middle",
      });
    }

    return new Style({
      image: new CircleStyle({
        radius: Math.min(10 + size, 40), // Scale the circle radius based on cluster size
        fill: new Fill({ color: pointColor }), // Use the provided point color
        stroke: new Stroke({ color: size === 1 ? "black" : "red", width: 1 }),
      }),
      text,
    });
  };

// Helper function to adjust text alignment based on the label's position
const getDynamicTextAlignment = (
  coordinates: Coordinate,
  mapExtent: number[],
): "left" | "right" | "center" => {
  const [minX, minY, maxX, maxY] = mapExtent;
  const [x, y] = coordinates;

  // Dynamically adjust alignment based on proximity to the map edges
  if (x > maxX - 50) {
    return "right";
  } else if (x < minX + 50) {
    return "left";
  }
  return "left"; // Default alignment
};
