import * as React from "react";
import { useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import { Location } from "src/types/locations";

type Props = {
  locations: Location[];
};

export const LocationImportNotifications = ({ locations }: Props) => {
  const [open, setOpen] = useState(true);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const locationWithCPT = useMemo(() => {
    if (!locations) return [];
    const loc = locations.filter((location) => location.cpt_file_id !== null);
    return loc || [];
  }, [locations]);

  const text = useMemo(
    () => `Importing CPTs: ${locationWithCPT.length} of ${locations.length}`,
    [locationWithCPT.length, locations.length],
  );

  return (
    <div>
      <Snackbar
        open={open}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};
