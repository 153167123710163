export type ParseLocationGroupStatus =
  | "Success"
  | "SoilUnitError"
  | "LocationGroupError"
  | "LocationGroupWarning"
  | "TabMissingError";

export const reasons = (status: ParseLocationGroupStatus) => {
  console.log("status", status);
  if (status === "TabMissingError") {
    return "The excel tab is missing. Please correct the error and import again the file.";
  }
  if (status === "SoilUnitError") {
    return "Could not match all the soil units. Match the missing soil units manually or create the missing ones.";
  }
  if (status === "LocationGroupError") {
    return "Could not assess the locationGroups layers";
  }
  if (status === "LocationGroupWarning") {
    return "Could not match all the location groups. Either create manually the location groups by exiting the import dialog or batch create them by clicking the button below.";
  }
  return "";
};
