import { Box } from "@mui/material";

import { LayerTypeSchema, SoilLayerType } from "src/schemas/soilLayerSchema";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { TableCustomCell } from "src/types/table";

type LayerRepresentation = "full" | "half";

const getColorFromSoilUnit = (
  soilUnit: string,
  soilUnitLayers: SoilUnitSchemaType[],
) =>
  soilUnitLayers.find((layer) => layer.soil_unit_id === soilUnit)?.color ||
  "#000000";

export const SoilUnitColorCellFactory = (
  soilUnitLayers: SoilUnitSchemaType[],
  layers: SoilLayerType[],
) => {
  const Component = ({
    getValue,
    row,
  }: TableCustomCell<SoilLayerType, SoilLayerType["soil_unit_id"]>) => {
    const soilUnit = getValue() || "default";
    const currentRowIndex = row.index;
    const layerSize = layers.length;
    const rowContent = row.original;
    const layerType = rowContent.layer_type;

    const layerReprensation: LayerRepresentation =
      currentRowIndex === 0 || currentRowIndex === layerSize - 1
        ? "full"
        : "half";

    const colorBottom = getColorFromSoilUnit(soilUnit, soilUnitLayers);

    const colorTop =
      layerReprensation === "full"
        ? colorBottom
        : getColorFromSoilUnit(
            layers[currentRowIndex - 1].soil_unit_id,
            soilUnitLayers,
          );

    const width = layerType === LayerTypeSchema.Values.LAYER ? "100%" : "50%";
    const marginLeft =
      layerType === LayerTypeSchema.Values.LAYER ? "0%" : "50%";

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: colorTop,
            width: width,
            marginLeft: marginLeft,
            height: "50%",
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: colorBottom,
            width: width,
            marginLeft: marginLeft,
            height: "50%",
          }}
        ></Box>
      </Box>
    );
  };

  Component.displayName = "SoilUnitColorCell";
  return Component;
};
