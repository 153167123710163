import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

import {
  AXIS_LABEL_MARGIN,
  AXIS_LABEL_TEXT_STYLE,
  AXIS_TICK_TEXT_STYLE,
  DEFAULT_GRID_STYLE,
  DEFAULT_MARGINS,
} from "./constants";
import {
  d3Ref,
  PlotAxis,
  PlotLineStyle,
  PLotMargins,
  RenderLayer,
} from "./types";
import { useResizeObserver } from "./useResizeObserver";

import "./PlotStyle.scss";

interface XYPlotProps {
  plotId: string;
  xAxisOptions: PlotAxis;
  yAxisOptions: PlotAxis;
  renderLayers: ((params: RenderLayer) => void)[];
  zoomDispatch?: d3.Dispatch<unknown>;
  margins?: PLotMargins;
  useXAxis?: boolean;
  useYAxis?: boolean;
  useGrid?: boolean;
  useZoom?: boolean;
  gridStyle?: PlotLineStyle;
}

export const XYPlot: React.FC<XYPlotProps> = ({
  plotId,
  xAxisOptions,
  yAxisOptions,
  renderLayers,
  zoomDispatch,
  margins = DEFAULT_MARGINS,
  useXAxis = true,
  useYAxis = true,
  useGrid = true,
  useZoom = false,
  gridStyle = DEFAULT_GRID_STYLE,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const zoomDispatchRef = zoomDispatch || useRef(d3.dispatch("zoom")).current;

  // Refs to store D3 selections
  const svgSelectionRef = useRef<SVGSVGElement>();
  const contentRef = useRef<d3Ref>();
  const xAxisGroupRef = useRef<d3Ref>();
  const yAxisGroupRef = useRef<d3Ref>();
  const xGridGroupRef = useRef<d3Ref>();
  const yGridGroupRef = useRef<d3Ref>();
  const dataGroupRef = useRef<d3Ref>();
  const clickableAreaRef = useRef<d3Ref>();
  const zoomBehaviorRef = useRef<d3.ZoomBehavior<Element, unknown>>();
  const initializedRef = useRef(false);

  // Refs for scales
  const xScaleRef = useRef<d3.ScaleLinear<number, number>>();
  const yScaleRef = useRef<d3.ScaleLinear<number, number>>();

  const dimensions = useResizeObserver(wrapperRef);

  useEffect(() => {
    if (!svgRef.current || !dimensions) return;

    const svgWidth = dimensions.width;
    const svgHeight = dimensions.height;
    const width = svgWidth - margins.left - margins.right;
    const height = svgHeight - margins.top - margins.bottom;

    const xAxisLabelStyle = {
      ...AXIS_LABEL_TEXT_STYLE,
      ...(xAxisOptions.labelStyle || {}),
    };

    const yAxisLabelStyle = {
      ...AXIS_LABEL_TEXT_STYLE,
      ...(yAxisOptions.labelStyle || {}),
    };

    const xAxisTickTextStyle = {
      ...AXIS_TICK_TEXT_STYLE,
      ...(xAxisOptions.tickTextStyle || {}),
    };

    const yAxisTickTextStyle = {
      ...AXIS_TICK_TEXT_STYLE,
      ...(yAxisOptions.tickTextStyle || {}),
    };

    // Initialize SVG elements only once
    if (!initializedRef.current) {
      initializedRef.current = true;

      // Create SVG container
      const svg = d3
        .select(svgRef.current)
        .attr("width", svgWidth)
        .attr("height", svgHeight);

      svgSelectionRef.current = svg;

      // Add a transparent rect to capture double-clicks across the plot
      const clickableArea = svg
        .append("rect")
        .attr("class", "clickable-area")
        .attr("width", svgWidth)
        .attr("height", svgHeight)
        .style("fill", "transparent")
        .style("pointer-events", "all")
        .on("dblclick", (event) => {
          const [x, y] = d3.pointer(event);
          console.log("Double-click detected at:", { x, y });
          // Handle double-click event as needed
        });

      clickableAreaRef.current = clickableArea;

      // Define the clip path
      svg
        .append("defs")
        .append("clipPath")
        .attr("id", `clip-${plotId}`)
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height);

      // Create content group
      const content = svg
        .append("g")
        .attr("class", "content")
        .attr("transform", `translate(${margins.left},${margins.top})`);

      contentRef.current = content;

      // Initialize grid groups
      xGridGroupRef.current = content.append("g").attr("class", "grid x-grid");
      yGridGroupRef.current = content.append("g").attr("class", "grid y-grid");

      // Initialize axes groups
      xAxisGroupRef.current = content.append("g").attr("class", "x-axis");
      yAxisGroupRef.current = content.append("g").attr("class", "y-axis");

      // Initialize data group with clip-path
      dataGroupRef.current = content
        .append("g")
        .attr("class", "data-group")
        .attr("clip-path", `url(#clip-${plotId})`);

      // Add axis labels to the svg (not inside content)
      svg
        .append("text")
        .attr("class", "x-axis-label")
        .style("text-anchor", "middle");

      svg
        .append("text")
        .attr("class", "y-axis-label")
        .attr("transform", "rotate(-90)")
        .style("text-anchor", "middle");

      // Initialize zoom behavior
      if (useZoom) {
        zoomBehaviorRef.current = d3
          .zoom<SVGSVGElement, unknown>()
          .filter((event) => event.type !== "dblclick") // Prevent zooming on double-click
          .on("zoom", zoomed);

        svg.call(zoomBehaviorRef.current);
      }

      // Zoomed function
      function zoomed(event: d3.D3ZoomEvent<SVGSVGElement, unknown>) {
        const transform = event.transform;

        // Rescale the x and y axes
        const newXScale = transform.rescaleX(xScaleRef.current!);
        const newYScale = transform.rescaleY(yScaleRef.current!);

        // Update axes and grids with new scales
        xAxisGroupRef.current!.call(xAxisGenerator.scale(newXScale));
        yAxisGroupRef.current!.call(yAxisGenerator.scale(newYScale));

        if (useGrid) {
          xGridGroupRef
            .current!.call(xGrid.scale(newXScale))
            .selectAll("line")
            .style("stroke", gridStyle.stroke || "lightgray")
            .style("stroke-width", gridStyle.strokeWidth || 1)
            .style("stroke-dasharray", gridStyle.strokeDasharray || "none");

          yGridGroupRef
            .current!.call(yGrid.scale(newYScale))
            .selectAll("line")
            .style("stroke", gridStyle.stroke || "lightgray")
            .style("stroke-width", gridStyle.strokeWidth || 1)
            .style("stroke-dasharray", gridStyle.strokeDasharray || "none");
        }

        // Update data layers
        renderLayers.forEach((renderLayer) => {
          renderLayer({
            svg: svgSelectionRef.current!,
            content: dataGroupRef.current!,
            xScale: newXScale,
            yScale: newYScale,
            width,
            height,
            svgWidth,
            svgHeight,
            margins,
            plotId,
            clickableArea: clickableAreaRef.current!,
          });
        });

        // Broadcast the zoom event if it was a user interaction
        if (event.sourceEvent) {
          zoomDispatchRef.call("zoom", null, transform, plotId);
        }
      }

      // Listen to zoom events from other plots
      zoomDispatchRef.on(
        `zoom.${plotId}`,
        (transform: d3.ZoomTransform, sourceId: string) => {
          if (!useZoom || sourceId === plotId) return; // Ignore if the event came from this plot
          svgSelectionRef.current!.call(
            zoomBehaviorRef.current!.transform,
            transform,
          );
        },
      );
    }

    // Clear the existing data layers before re-rendering
    dataGroupRef.current!.selectAll("*").remove(); // Clear all existing data layers

    // Update dimensions
    svgSelectionRef.current!.attr("width", svgWidth).attr("height", svgHeight);
    clickableAreaRef.current!.attr("width", svgWidth).attr("height", svgHeight);

    // Update scales
    const xScale = d3
      .scaleLinear()
      .domain(
        xAxisOptions.reverse
          ? [xAxisOptions.dataRange[1], xAxisOptions.dataRange[0]]
          : xAxisOptions.dataRange,
      )
      .range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain(
        yAxisOptions.reverse
          ? [yAxisOptions.dataRange[1], yAxisOptions.dataRange[0]]
          : yAxisOptions.dataRange,
      )
      .range([height, 0]);

    // Store scales in refs to use in zoomed function
    xScaleRef.current = xScale;
    yScaleRef.current = yScale;

    // Axes generators
    const xAxisGenerator =
      xAxisOptions.position === "top"
        ? d3.axisTop(xScale).ticks(xAxisOptions.tickCount || 10)
        : d3.axisBottom(xScale).ticks(xAxisOptions.tickCount || 10);

    const yAxisGenerator =
      yAxisOptions.position === "right"
        ? d3.axisRight(yScale).ticks(yAxisOptions.tickCount || 10)
        : d3.axisLeft(yScale).ticks(yAxisOptions.tickCount || 10);

    // Grid generators
    const xGrid = d3
      .axisBottom(xScale)
      .tickSize(-height)
      .tickFormat(() => "");

    const yGrid = d3
      .axisLeft(yScale)
      .tickSize(-width)
      .tickFormat(() => "");

    // Update grids
    if (useGrid) {
      xGridGroupRef
        .current!.attr("transform", `translate(0,${height})`)
        .call(xGrid)
        .selectAll("line")
        .style("stroke", gridStyle.stroke || "lightgray")
        .style("stroke-width", gridStyle.strokeWidth || 1)
        .style("stroke-dasharray", gridStyle.strokeDasharray || "none");

      yGridGroupRef
        .current!.call(yGrid)
        .selectAll("line")
        .style("stroke", gridStyle.stroke || "lightgray")
        .style("stroke-width", gridStyle.strokeWidth || 1)
        .style("stroke-dasharray", gridStyle.strokeDasharray || "none");
    }

    // Update axes
    if (useXAxis) {
      xAxisGroupRef
        .current!.attr(
          "transform",
          xAxisOptions.position === "top"
            ? "translate(0,0)"
            : `translate(0,${height})`,
        )
        .call(xAxisGenerator);
      // Apply custom text styles to the y-axis ticks
      xAxisGroupRef
        .current!.selectAll("text")
        .style("font-size", xAxisTickTextStyle.fontSize)
        .style("font-family", xAxisTickTextStyle.fontFamily)
        .style("font-weight", xAxisTickTextStyle.fontWeight)
        .style("fill", xAxisTickTextStyle.color);

      // Update axis labels
      svgSelectionRef
        .current!.select(".x-axis-label")
        .attr(
          "transform",
          `translate(${svgWidth / 2},${
            xAxisOptions.position === "top"
              ? margins.top / 2 - AXIS_LABEL_MARGIN
              : svgHeight - margins.bottom / 4 + AXIS_LABEL_MARGIN
          })`,
        )
        .attr("text-anchor", xAxisLabelStyle.align)
        .style("font-size", xAxisLabelStyle.fontSize)
        .style("font-family", xAxisLabelStyle.fontFamily)
        .style("font-weight", xAxisLabelStyle.fontWeight)
        .style("fill", xAxisLabelStyle.color)
        .text(xAxisOptions.label);
    }

    if (useYAxis) {
      yAxisGroupRef
        .current!.attr(
          "transform",
          yAxisOptions.position === "right"
            ? `translate(${width},0)`
            : "translate(0,0)",
        )
        .call(yAxisGenerator);

      //
      // Apply custom text styles to the y-axis ticks
      yAxisGroupRef
        .current!.selectAll("text")
        .style("font-size", yAxisTickTextStyle.fontSize)
        .style("font-family", yAxisTickTextStyle.fontFamily)
        .style("font-weight", yAxisTickTextStyle.fontWeight)
        .style("fill", yAxisTickTextStyle.color);

      svgSelectionRef
        .current!.select(".y-axis-label")
        .attr("x", -svgHeight / 2)
        .attr(
          "y",
          yAxisOptions.position === "right"
            ? svgWidth - margins.right / 4 - AXIS_LABEL_MARGIN
            : margins.left / 4 + AXIS_LABEL_MARGIN,
        )
        .attr("text-anchor", xAxisLabelStyle.align) // Apply text anchor for alignment
        .style("font-size", yAxisLabelStyle.fontSize)
        .style("font-family", yAxisLabelStyle.fontFamily)
        .style("font-weight", yAxisLabelStyle.fontWeight)
        .style("fill", yAxisLabelStyle.color)
        .text(yAxisOptions.label);
    }

    // Update data layers
    renderLayers.forEach((renderLayer) => {
      renderLayer({
        svg: svgSelectionRef.current!,
        content: dataGroupRef.current!,
        xScale,
        yScale,
        width,
        height,
        svgHeight,
        svgWidth,
        margins,
        plotId,
        clickableArea: clickableAreaRef.current!,
      });
    });

    // Update zoom behavior
    if (useZoom) {
      zoomBehaviorRef
        .current!.scaleExtent([
          1,
          Math.max(xAxisOptions.maxZoomLevel, yAxisOptions.maxZoomLevel),
        ])
        .translateExtent([
          [0, 0],
          [width, height],
        ])
        .extent([
          [0, 0],
          [width, height],
        ]);

      // Update zoom transform if necessary
      svgSelectionRef.current!.call(zoomBehaviorRef.current!);
    }

    // Update clip-path dimensions
    svgSelectionRef
      .current!.select(`#clip-${plotId} rect`)
      .attr("width", width)
      .attr("height", height);

    // Update content group transform
    contentRef.current!.attr(
      "transform",
      `translate(${margins.left},${margins.top})`,
    );

    // Update clickable area position if margins change
    clickableAreaRef
      .current!.attr("x", 0)
      .attr("y", 0)
      .attr("width", svgWidth)
      .attr("height", svgHeight);
  }, [
    xAxisOptions,
    yAxisOptions,
    dimensions,
    renderLayers,
    margins,
    useGrid,
    gridStyle,
    zoomDispatch,
    plotId,
    useZoom,
    useXAxis,
    useYAxis,
    zoomDispatchRef,
  ]);

  return (
    <div ref={wrapperRef} style={{ width: "100%", height: "100%" }}>
      <svg ref={svgRef} />
    </div>
  );
};
