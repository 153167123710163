import {
  DragCondition,
  FormattedData,
} from "src/components/common/plots/elements/editableScatter";
import { SoilLayerType } from "src/schemas/soilLayerSchema";
import { D3Event } from "src/types/plotInteractions";

export const dragConditions =
  (radius: number): DragCondition<SoilLayerType> =>
  (
    currentPoint: FormattedData<SoilLayerType>,
    editablePoints: FormattedData<SoilLayerType>[],
    event: D3Event<MouseEvent, SVGElement>,
  ) => {
    const sortedCircles = editablePoints.sort(
      (a, b) => a.data.depth - b.data.depth,
    );
    // Get the index of current in circles
    const currentIndex = sortedCircles.findIndex(
      (c) => c.id === currentPoint.id,
    );

    if (currentIndex === -1) return true; // No point found

    if (currentIndex === 0) return false; // Don't allow drag first point

    const prevCircle = sortedCircles[currentIndex - 1];

    const nextCircle =
      currentIndex < sortedCircles.length - 1 &&
      sortedCircles[currentIndex + 1];

    const canDragUp = currentPoint.y - 2 * radius > prevCircle.y;
    const canDragDown = nextCircle
      ? currentPoint.y + 2 * radius < nextCircle.y
      : true;

    // Determine the direction of dragging
    // @ts-expect-error dy exist on the event
    const direction = event.dy > 0 ? "down" : "up";

    if (direction === "down" && canDragDown) return true;
    if (direction === "up" && canDragUp) return true;

    return false;
  };
