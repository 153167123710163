import { Box, CircularProgress, Paper, Typography } from "@mui/material";

import { PhaseRevision } from "src/types/api";

type Props = {
  revision: PhaseRevision | null;
  isRevisionPending: boolean;
};

export const RevisionOverviewInfo = ({
  revision,
  isRevisionPending,
}: Props) => (
  <Box>
    <Typography variant="h4">Revision info</Typography>
    <Paper className="mt-4">
      <Box className="flex flex-col gap-2 py-2">
        {isRevisionPending ? (
          <div className="flex space-x-4 items-center pl-2">
            <CircularProgress size="30px" className="ml-1" />
            <div className="py-4">
              <Typography variant="h6">
                Loading revision informations...
              </Typography>
            </div>
          </div>
        ) : revision ? (
          <>
            <Box className="flex gap-4 items-center p-2">
              <Typography variant="h6">Name:</Typography>
              <Typography variant="body1">{revision.name}</Typography>
            </Box>
            {revision.description !== "" && (
              <Box className="flex gap-4 items-center p-2">
                <Typography variant="h6">Description:</Typography>
                <Typography variant="body1">{revision.description}</Typography>
              </Box>
            )}
          </>
        ) : null}
      </Box>
    </Paper>
  </Box>
);
