import { ArrowCircleLeft } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";

import "./AppNavigation.scss";

const activeOptions = { exact: true };

type Props = {
  project_id: string;
  phase_id?: string;
  revision_id?: string;
  hasLocationCPTMissing?: boolean;
};

export function ProjectNavigation({
  project_id,
  phase_id,
  revision_id,
  hasLocationCPTMissing = false,
}: Props) {
  return (
    <Box className="AppNavigationWrapper mt-[12px]">
      <nav className="AppNavigation flex h-16 items-center justify-center">
        <Link
          to="/project/$project_id"
          params={{ project_id }}
          activeOptions={activeOptions}
        >
          <Box className={`flex justify-center items-center gap-2 `}>
            {phase_id && revision_id && <ArrowCircleLeft />}
            Project overview
          </Box>
        </Link>
        {phase_id && revision_id && (
          <div className="grow flex justify-center items-center pl-8">
            <Typography variant="h6" className="pr-4">
              Revision:
            </Typography>
            <Link
              to="/project/$project_id/phase/$phase_id/revision/$revision_id"
              params={{ project_id, phase_id, revision_id }}
              activeOptions={activeOptions}
            >
              Overview
            </Link>
            <Link
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/locations"
              params={{ project_id, phase_id, revision_id }}
              activeOptions={activeOptions}
            >
              Locations
            </Link>
            <Link
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/location-groups"
              params={{ project_id, phase_id, revision_id }}
              activeOptions={activeOptions}
              disabled={hasLocationCPTMissing}
            >
              Location groups
            </Link>
            <Link
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/soil-layering"
              params={{ project_id, phase_id, revision_id }}
              activeOptions={activeOptions}
              disabled={hasLocationCPTMissing}
            >
              Global unit layers
            </Link>
            <Link
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/soil-units"
              params={{ project_id, phase_id, revision_id }}
              activeOptions={activeOptions}
            >
              Unit soil layers
            </Link>
          </div>
        )}
      </nav>
    </Box>
  );
}
