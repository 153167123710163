import {
  PlotCircleStyle,
  PlotLineStyle,
  PLotMargins,
  PlotTextStyle,
} from "./types";

export const DEFAULT_MARGINS: PLotMargins = {
  top: 10,
  left: 50,
  bottom: 50,
  right: 10,
};

export const DEFAULT_GRID_STYLE: Required<PlotLineStyle> = {
  stroke: "lightgrey",
  strokeWidth: 1,
  strokeDasharray: "solid",
  fill: "none",
};

export const DEFAULT_LINE_STYLE: Required<PlotLineStyle> = {
  stroke: "black",
  strokeWidth: 1,
  strokeDasharray: "none",
  fill: "none",
};

export const DEFAULT_CIRCLE_STYLE: Required<PlotCircleStyle> = {
  radius: 10,
  fill: "black",
  stroke: "black",
  strokeWidth: 1,
};

export const SELECTED_CIRCLE_STYLE: Required<PlotCircleStyle> = {
  radius: 10,
  fill: "yellow",
  stroke: "red",
  strokeWidth: 2,
};

export const TEXT_STYLE: Required<PlotTextStyle> = {
  fontSize: "10px",
  fontWeight: "normal",
  color: "black",
  fontFamily: "sans-serif",
  align: "start",
};

export const AXIS_LABEL_TEXT_STYLE: Required<PlotTextStyle> = {
  fontSize: "11px",
  fontWeight: "normal",
  color: "black",
  fontFamily: "sans-serif",
  align: "middle",
};

export const AXIS_TICK_TEXT_STYLE: Required<PlotTextStyle> = {
  fontSize: "9px",
  fontWeight: "normal",
  color: "black",
  fontFamily: "sans-serif",
  align: "middle",
};

export const AXIS_LABEL_MARGIN = 5;
