import { z } from "zod";

import { greaterThanMessage } from "src/utils/schema";

export const soilUnitParametersSchema = z.object({
  uw: z.number().gt(10, greaterThanMessage("uw", 10)).optional(),
  Nkt: z.number().gt(10, greaterThanMessage("Nkt", 10)).optional(),
});

export const SoilTypeSchema = z.enum([
  "SAND",
  "SILT",
  "CLAY",
  "ORGANIC",
  "ROCK",
  "OTHER",
]);

export const soilUnitSchema = z.object({
  is_default: z.boolean(),
  soil_unit_id: z.string(),
  name: z.string().min(1),
  // TODO: remove when is_default is ready
  // .regex(/^(?!Default$).*/, { message: '"Default" name is not allowed' }),
  color: z.string().length(7).regex(/^#/),
  soil_type: SoilTypeSchema,
  description: z.string().optional(),
  parameters: soilUnitParametersSchema,
});

export const createSoilUnitSchema = soilUnitSchema.omit({ soil_unit_id: true });

// Inferred types
export type SoilUnitParameters = z.infer<typeof soilUnitParametersSchema>;
export type SoilType = z.infer<typeof SoilTypeSchema>;
export type SoilUnitSchemaType = z.infer<typeof soilUnitSchema>;
export type CreateSoilUnitSchemaType = Omit<SoilUnitSchemaType, "soil_unit_id">;

// Default values
export const soilUnitDefaultValues: CreateSoilUnitSchemaType = {
  is_default: false,
  name: "",
  color: "",
  soil_type: "CLAY",
  description: "",
  parameters: {
    uw: 14,
    Nkt: 15,
  },
};
