import * as XLSX from "xlsx";

import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { LocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";
import {
  columnNameToIndex,
  findColumFromHeader,
  indexToColumnName,
} from "src/utils/excel-import-utils";
import {
  groupByColIndexAndUnit,
  reconcileLocationGroupWithLocationGroupID,
  transformToSoilLayers,
} from "./excel-import-soil-layer";
import { ParseLocationGroupStatus, reasons } from "./parse-status-and-reasons";

const LOCATION_DETAILS_WORKSHEET_DATA_START = 2;
enum LocationDetailsWorksheetHeader {
  LOCATION_NAME = "Location_ID_ind",
  LOCATION_GROUP = "Location_ID_group",
}

type ParsingStatus = {
  status: ParseLocationGroupStatus;
  reason?: string;
};

export type ParseLocationGroupsReturn = ParsingStatus & {
  locationGroups: LocationGroup[];
  foundLocations: string[];
  missingLocations: string[];
  matchedLocationGroupCount: number;
  unMatchedLocationGroupCount: number;
  usedSoilUnits: string[];
  matchedUsedSoilUnits: string[];
  unMatchedUsedSoilUnits: string[];
};

export type ParseLocationDetailsTabReturn = {
  foundLocations: string[];
  missingLocations: string[];
  locationGroups: LocationGroup[];
};

function parseLocationDetailsTab(
  worksheet: XLSX.WorkSheet,
  locations: Location[],
): ParseLocationDetailsTabReturn {
  const data: Record<string, string>[] = XLSX.utils.sheet_to_json(worksheet, {
    header: "A",
  });

  // Find the colums in the header
  const header = data[0];
  const locationNameCol = findColumFromHeader(
    header,
    LocationDetailsWorksheetHeader.LOCATION_NAME,
  );
  const locationGroupCol = findColumFromHeader(
    header,
    LocationDetailsWorksheetHeader.LOCATION_GROUP,
  );

  if (!locationNameCol || !locationGroupCol) {
    throw new Error(
      "Could not find the location name or location group column",
    );
  }

  const slicedData = data.slice(LOCATION_DETAILS_WORKSHEET_DATA_START);

  // parse the data and filter out locations that are not found in the provided locations from FM-SIITE
  const existingLocationNames = locations.map((location) => location.name);
  const existingLocationNameToID = locations.reduce(
    (acc, cur) => {
      acc[cur.name] = cur.siite_location_id;
      return acc;
    },
    {} as Record<string, string>,
  );

  const locationGroupsExcelMapped = slicedData
    .map((row) => ({
      location: row[locationNameCol],
      locationGroup: row[locationGroupCol],
    }))
    .filter((row) => existingLocationNames.includes(row.location))
    .reduce(
      (acc, cur) => {
        if (!acc[cur.locationGroup]) {
          acc[cur.locationGroup] = [];
        }
        acc[cur.locationGroup].push(existingLocationNameToID[cur.location]);
        return acc;
      },
      {} as Record<string, string[]>,
    );

  const locationGroups = Object.entries(locationGroupsExcelMapped).map(
    ([name, location_ids]) => ({
      location_group_id: "",
      revision_id: "",
      name,
      description: "",
      location_ids,
    }),
  ) as LocationGroup[];

  const foundLocations = slicedData
    .map((row) => row[locationNameCol])
    .filter((row) => existingLocationNames.includes(row));

  const missingLocations = slicedData
    .map((row) => row[locationNameCol])
    .filter((row) => !existingLocationNames.includes(row));

  return {
    foundLocations,
    missingLocations,
    locationGroups,
  };
}

type SoilUnitMapped = {
  unit: string;
  startCol: string;
  startColIndex: number;
  endColIndex?: number;
};

type SoilLayerMapped = {
  unit: string;
  key: string;
  col: string;
  colIndex: number;
  importColName: string;
};

export function parseGlobalUnitLayersTab(
  worksheet: XLSX.WorkSheet,
  locationGroups: LocationGroup[],
): LocationGroup[] {
  const data: Record<string, string>[] = XLSX.utils.sheet_to_json(worksheet, {
    header: "A",
  });

  const soilUnitsRow = data[1];
  let soilUnitMapping: SoilUnitMapped[] = Object.entries(soilUnitsRow)
    .map(([key, value]) => ({
      unit: value,
      startCol: key,
      startColIndex: columnNameToIndex(key),
    }))
    .filter((row) => row.unit !== "Soil_unit");

  soilUnitMapping = soilUnitMapping.map((row, i) => {
    const endColIndex =
      i < soilUnitMapping.length - 1
        ? soilUnitMapping[i + 1].startColIndex - 1
        : soilUnitMapping[i].startColIndex;
    const endCol = indexToColumnName(endColIndex);
    return { ...row, endColIndex, endCol };
  });

  const soilLayerBoundaryRow = data[2];

  const soilLayerMapping: SoilLayerMapped[] = Object.entries(
    soilLayerBoundaryRow,
  ).map(([col, key]) => {
    const colIndex = columnNameToIndex(col);
    const unit =
      soilUnitMapping.find(
        (unit) =>
          colIndex >= unit.startColIndex &&
          colIndex <= (unit.endColIndex || unit.startColIndex),
      )?.unit || "";
    return {
      key,
      col,
      colIndex,
      unit,
      importColName: `${colIndex}|${unit}|${key}|${col}`,
    };
  });

  const soilLayerCols = soilLayerMapping.reduce(
    (acc, cur) => {
      acc[cur.col] = cur.importColName;
      return acc;
    },
    {} as Record<string, string>,
  );

  const layerDataExcel: Record<string, string | number>[] = data
    .slice(3)
    .map((row) => {
      const entries = Object.entries(row);
      return entries.reduce(
        (acc, cur) => {
          acc[soilLayerCols[cur[0]]] = cur[1];
          return acc;
        },
        {} as Record<string, string | number>,
      );
    });

  const layerDataExcelGrouped = layerDataExcel.map((layer) =>
    groupByColIndexAndUnit(layer),
  );

  const layerDataApp = layerDataExcelGrouped.map((layer, i) =>
    transformToSoilLayers(layer, i),
  );
  const locationsGroupNames = locationGroups.map((group) => group.name);

  const a = layerDataApp
    .filter((layer) => locationsGroupNames.includes(layer.excelName))
    .reduce(
      (acc, cur) => {
        const locationGroup = locationGroups.find(
          (group) => group.name === cur.excelName,
        );
        if (!locationGroup) {
          return acc;
        }
        acc[cur.excelName] = {
          ...locationGroup,
          max_depth: cur.max_depth,
          layers: cur.layers,
        };
        return acc;
      },
      {} as Record<string, LocationGroup>,
    );

  return Object.values(a);
}

function subsituteSoilNameBySoilUnitID(
  locationGroups: LocationGroup[],
  soilUnits: SoilUnitSchemaType[],
  soilUnitMapping: Record<string, string>,
) {
  const defaultSoilUnit = soilUnits.find((unit) => unit.is_default);

  if (!defaultSoilUnit) {
    console.error(
      "subsituteSoilNameBySoilUnitID: Could not find the default soil unit",
    );
    return locationGroups;
  }

  return locationGroups.map((group) => {
    const layers = group.layers.map((layer) => {
      const soilUnitID =
        soilUnitMapping[layer.soil_unit_id] || defaultSoilUnit.soil_unit_id;
      return { ...layer, soil_unit_id: soilUnitID };
    });
    return { ...group, layers };
  });
}

function setParsingStatus(
  locationDetailsTabData: ParseLocationDetailsTabReturn,
  locationGroups: LocationGroup[],
): ParsingStatus {
  if (locationGroups.length === 0) {
    console.error("Could not assess the locationGroups layers");
    return {
      status: "LocationGroupError",
      reason: reasons("LocationGroupError"),
    };
  }

  if (locationGroups.some((group) => group.location_group_id === "")) {
    console.warn("Some of the locationGroups were not matched");
    return {
      status: "LocationGroupWarning",
      reason: reasons("LocationGroupWarning"),
    };
  }

  return { status: "Success" };
}

export function parseLocationGroups(
  workbook: XLSX.WorkBook,
  locations: Location[],
  locationGroups: LocationGroup[],
  soilUnits: SoilUnitSchemaType[],
  soilUnitMapping: Record<string, string>,
): ParseLocationGroupsReturn {
  // Parse the location details tab to get the information about the loations and location groups
  const locationDetailsTabData = parseLocationDetailsTab(
    workbook.Sheets["location_details"],
    locations,
  );

  // Parse the global unit layers tab to get the soil layers for the location groups
  let locationGroupsLayers = parseGlobalUnitLayersTab(
    workbook.Sheets["global_unit_layers"],
    locationDetailsTabData.locationGroups,
  );

  const locationGroupMapped = locationGroups.reduce(
    (acc, cur) => {
      acc[cur.name] = cur.location_group_id;
      return acc;
    },
    {} as Record<string, string>,
  );

  // Map the location group names to the location group IDs
  locationGroupsLayers = locationGroupsLayers.map((group) =>
    reconcileLocationGroupWithLocationGroupID(group, locationGroupMapped),
  );

  let usedSoilUnits = locationGroupsLayers.reduce((acc, cur) => {
    acc.push(...cur.layers.map((layer) => layer.soil_unit_id));
    return acc;
  }, [] as string[]);

  usedSoilUnits = [...new Set(usedSoilUnits)];

  const matchedUsedSoilUnits = Object.entries(soilUnitMapping)
    .filter(([key, value]) => usedSoilUnits.includes(key) && value !== "")
    .map(([key, value]) => key);
  const unMatchedUsedSoilUnits = Object.entries(soilUnitMapping)
    .filter(([key, value]) => usedSoilUnits.includes(key) && value === "")
    .map(([key, value]) => key);

  // Map the soil unit names to the soil unit IDs
  locationGroupsLayers = subsituteSoilNameBySoilUnitID(
    locationGroupsLayers,
    soilUnits,
    soilUnitMapping,
  );

  const parsingStatus = setParsingStatus(
    locationDetailsTabData,
    locationGroupsLayers,
  );

  return {
    ...parsingStatus,
    locationGroups: locationGroupsLayers,
    missingLocations: locationDetailsTabData.missingLocations,
    foundLocations: locationDetailsTabData.foundLocations,
    matchedLocationGroupCount: locationGroupsLayers.filter(
      (group) => group.location_group_id !== "",
    ).length,
    unMatchedLocationGroupCount: locationGroupsLayers.filter(
      (group) => group.location_group_id === "",
    ).length,
    usedSoilUnits,
    matchedUsedSoilUnits,
    unMatchedUsedSoilUnits,
  };
}
