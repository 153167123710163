import { useCallback, useMemo, useState } from "react";
import { Check, Error, FileDownload } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";

import { NBaseAlert, NButton, NDialog } from "@ngi/react-component";
import { importExcel } from "src/methods/parent-file-excel-import/excel-import";
import { ParseLocationGroupsReturn } from "src/methods/parent-file-excel-import/parse-location-groups";
import { CreateOrUpdateSoilUnit } from "src/pages/revisionSoilUnits/CreateOrUpdateSoilUnit";
import {
  batchAddRevisionLocationGroupMutationQuery,
  batchCreateRevisionSoilUnitMutationQuery,
  batchUpdateRevisionLocationGroupMutationQuery,
} from "src/queries/mutations";
import {
  getRevisionLocationGroupQuery,
  getRevisionSoilUnitQuery,
} from "src/queries/queries";
import {
  soilUnitDefaultValues,
  SoilUnitSchemaType,
} from "src/schemas/unitSoilLayerSchema";
import { LocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";
import { ImportParentFileReturn } from "src/utils/excel-import";
import { sleep } from "src/utils/sleep";

type Props = {
  revision_id: string;
  soilUnits: SoilUnitSchemaType[];
  locations: Location[];
  locationGroups: LocationGroup[];
  open: boolean;
  setOpen: (v: boolean) => void;
};

export const ExcelImporter = ({
  revision_id,
  soilUnits,
  locations,
  locationGroups,
  open,
  setOpen,
}: Props) => {
  const { refetch } = useQuery(getRevisionSoilUnitQuery(revision_id));
  const { refetch: refetchLocationGroups } = useQuery(
    getRevisionLocationGroupQuery(revision_id),
  );
  const [busyStatus, setBusyStatus] = useState<
    "idle" | "mutating" | "error" | "success"
  >("idle");
  const [busyMessage, setBusyMessage] = useState<string>("");

  const [openCreateSoilUnitDialog, setOpenCreateSoilUnitDialog] =
    useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [allowImportWithMissingLocations, setAllowImportWithMissingLocations] =
    useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setBusyStatus("idle");
    setExcelFile(null);
    setActiveStep(0);
  };

  const [excelFile, setExcelFile] = useState<File | null>(null);

  const [sheetsData, setSheetsData] = useState<ImportParentFileReturn | null>(
    null,
  );
  const [soilUnitFromExcel, setSoilUnitFromExcel] = useState<
    SoilUnitSchemaType[]
  >([]);

  const [selectedSoilUnitToCreate, setSelectedSoilUnitToCreate] =
    useState<SoilUnitSchemaType>();

  const [locationGroupFromExcel, setLocationGroupFromExcel] =
    useState<ParseLocationGroupsReturn>({
      locationGroups: [],
      foundLocations: [],
      missingLocations: [],
      matchedLocationGroupCount: 0,
      unMatchedLocationGroupCount: 0,
      unMatchedUsedSoilUnits: [],
      matchedUsedSoilUnits: [],
      usedSoilUnits: [],
    });

  const [hasUnmatchedSoilUnits, setHasUnmatchedSoilUnits] = useState(false);

  const batchCreateRevisionSoilUnitMutation = useMutation(
    batchCreateRevisionSoilUnitMutationQuery(revision_id),
  );

  const batchCreateLocationGroups = useMutation(
    batchAddRevisionLocationGroupMutationQuery(revision_id),
  );

  const handleExcelParsing = useCallback(
    async (
      file: File,
      soilUnits: SoilUnitSchemaType[],
      locationGroups: LocationGroup[],
    ) => {
      if (!file) return;
      setExcelFile(file);
      try {
        const data = await importExcel(
          file,
          soilUnits,
          locationGroups,
          locations,
          allowImportWithMissingLocations,
        );
        console.log("AAA excel import", data);
        setSheetsData(data);
        if (data) {
          setSoilUnitFromExcel(data.soilUnits);
          setLocationGroupFromExcel(data.locationGroups);
          setHasUnmatchedSoilUnits(
            data.locationGroups.unMatchedUsedSoilUnits.length > 0,
          );
        }
      } catch (error) {
        console.error("Error importing file:", error);
      }
    },
    [allowImportWithMissingLocations, locations],
  );

  const handleFileInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        handleExcelParsing(file, soilUnits, locationGroups);
      }
    },
    [handleExcelParsing, locationGroups, soilUnits],
  );

  const handleChangeAllowImportMissingLocations = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAllowImportWithMissingLocations(event.target.checked);
      if (excelFile) {
        handleExcelParsing(excelFile, soilUnits, locationGroups);
      }
    },
    [excelFile, handleExcelParsing, locationGroups, soilUnits],
  );

  const handleOnCreatedSoilUnit = useCallback(async () => {
    setOpenCreateSoilUnitDialog(false);
    await sleep(1000); // INFO: need to wait for the backend to update. Maybe find a better way.
    const { data: updatedSoilUnits } = await refetch();
    if (updatedSoilUnits) {
      const soilUnit = updatedSoilUnits.find(
        (unit) => unit.name === selectedSoilUnitToCreate?.name,
      );
      if (soilUnit) {
        const updatedSoilUnitFromExcel = soilUnitFromExcel.map((unit) => {
          if (unit.name === selectedSoilUnitToCreate?.name) {
            return { ...unit, soil_unit_id: soilUnit.soil_unit_id };
          }
          return unit;
        });
        setSoilUnitFromExcel(updatedSoilUnitFromExcel);
      }
    }
  }, [refetch, selectedSoilUnitToCreate?.name, soilUnitFromExcel]);

  const handleCreateAllMissingSoilUnits = useCallback(async () => {
    const units = soilUnitFromExcel
      .filter(
        (unit) =>
          unit.soil_unit_id === "" &&
          locationGroupFromExcel.unMatchedUsedSoilUnits.includes(unit.name),
      )
      .map(({ soil_unit_id, ...unit }) => unit);

    if (units.length === 0) return;

    setBusyMessage("Creating the missing soil units");
    setBusyStatus("mutating");

    batchCreateRevisionSoilUnitMutation.mutate(
      { revision_id, payload: units },
      {
        onSuccess: async () => {
          setBusyMessage("Successfully created the missing soil units");
          setBusyStatus("success");
          if (excelFile) {
            const { data: updatedSoilUnits } = await refetch();
            if (excelFile && updatedSoilUnits) {
              await sleep(3000);
              handleExcelParsing(excelFile, updatedSoilUnits, locationGroups);
            }
          }
          setBusyStatus("idle");
        },
        onError: () => {
          setBusyStatus("error");
        },
      },
    );
  }, [
    batchCreateRevisionSoilUnitMutation,
    excelFile,
    handleExcelParsing,
    locationGroupFromExcel.unMatchedUsedSoilUnits,
    locationGroups,
    refetch,
    revision_id,
    soilUnitFromExcel,
  ]);

  const handleCreateAllMissingLocationGroups = useCallback(async () => {
    const locationGroups = locationGroupFromExcel.locationGroups
      .filter((group) => group.location_group_id === "")
      .map(({ location_group_id, ...group }) => group);

    if (locationGroups.length === 0) return;

    setBusyMessage("Creating the missing location groups");
    setBusyStatus("mutating");

    batchCreateLocationGroups.mutate(
      { revision_id, payload: { groups: locationGroups } },
      {
        onSuccess: async () => {
          setBusyMessage("Successfully created the missing location groups");
          setBusyStatus("success");
          const { data: updatedSoilUnits } = await refetch();
          const { data: updatedLocationGroups } = await refetchLocationGroups();
          if (excelFile && updatedSoilUnits && updatedLocationGroups) {
            await sleep(3000);
            handleExcelParsing(
              excelFile,
              updatedSoilUnits,
              updatedLocationGroups,
            );
          }
          setBusyStatus("idle");
        },
        onError: () => {
          setBusyStatus("error");
        },
      },
    );
  }, [
    batchCreateLocationGroups,
    excelFile,
    handleExcelParsing,
    locationGroupFromExcel.locationGroups,
    refetch,
    refetchLocationGroups,
    revision_id,
  ]);

  const actions = useMemo(
    () => (
      <>
        {["idle", "error"].includes(busyStatus) && (
          <NButton onClick={() => setOpen(false)} variant="text">
            Cancel
          </NButton>
        )}
      </>
    ),
    [busyStatus, setOpen],
  );

  const updateLocationGroups = useMutation(
    batchUpdateRevisionLocationGroupMutationQuery(revision_id),
  );

  const handleImportLayersLocationGroups = useCallback(() => {
    setBusyMessage("Importing the layers for the location groups");
    setBusyStatus("mutating");
    const groups = locationGroupFromExcel.locationGroups;

    updateLocationGroups.mutate(
      {
        revision_id,
        payload: { revision_id, groups },
      },
      {
        onError: () => setBusyStatus("error"),
        onSuccess: async () => {
          setBusyMessage(
            "Successfully imported the layers for the location groups",
          );
          setBusyStatus("success");
          await sleep(2000);
          setBusyStatus("idle");
          setOpen(false);
        },
      },
    );
  }, [
    locationGroupFromExcel.locationGroups,
    revision_id,
    setOpen,
    updateLocationGroups,
  ]);

  const stepActions = useCallback(
    ({
      index,
      enablePrev = false,
      enableNext = false,
      isLastStep = false,
    }: {
      index: number;
      enablePrev?: boolean;
      enableNext?: boolean;
      isLastStep?: boolean;
    }) => (
      <Box sx={{ mb: 0 }}>
        <Button
          variant="text"
          disabled={!enableNext}
          onClick={isLastStep ? handleImportLayersLocationGroups : handleNext}
          sx={{ mt: 1, mr: 1 }}
        >
          {isLastStep ? "Import layer data" : "Continue"}
        </Button>
        {index > 0 && (
          <Button
            variant="text"
            disabled={!enablePrev}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Back
          </Button>
        )}
      </Box>
    ),
    [handleImportLayersLocationGroups],
  );

  const isStepLocationInvalid = useMemo(() => {
    if (sheetsData?.status === "TabMissingError") return true;

    return locationGroupFromExcel.missingLocations.length > 0;
  }, [locationGroupFromExcel.missingLocations.length, sheetsData?.status]);

  const isStepSoilUnitInvalid = useMemo(() => {
    if (isStepLocationInvalid && !allowImportWithMissingLocations) return true;
    if (sheetsData?.status === "TabMissingError") return true;
    return hasUnmatchedSoilUnits;
  }, [
    allowImportWithMissingLocations,
    hasUnmatchedSoilUnits,
    isStepLocationInvalid,
    sheetsData?.status,
  ]);

  const isStepLocationGroupInvalid = useMemo(() => {
    if (isStepSoilUnitInvalid) return true;
    if (sheetsData?.status === "TabMissingError") return true;

    return locationGroupFromExcel.unMatchedLocationGroupCount > 0;
  }, [isStepSoilUnitInvalid, locationGroupFromExcel, sheetsData?.status]);

  return (
    <NDialog
      dialogTitle="Import excel parent file"
      onClose={() => setOpen(false)}
      open={open}
      maxWidth="xl"
      actions={actions}
    >
      <div className="max-w-wide w-[900px] mx-auto">
        {busyStatus !== "idle" ? (
          <div className="flex flex-col items-center justify-center w-full">
            <Box sx={{ m: 1, position: "relative" }}>
              <Fab
                aria-label="save"
                color={
                  busyStatus === "mutating"
                    ? "primary"
                    : busyStatus === "error"
                      ? "error"
                      : "success"
                }
              >
                {busyStatus === "success" ? (
                  <Check />
                ) : busyStatus === "error" ? (
                  <Error />
                ) : (
                  <FileDownload />
                )}
              </Fab>
              {busyStatus === "mutating" && (
                <CircularProgress
                  size={68}
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography
              variant="h6"
              color={
                busyStatus === "mutating"
                  ? "primary"
                  : busyStatus === "error"
                    ? "error"
                    : "success"
              }
            >
              {busyMessage}
            </Typography>
            {busyStatus === "error" && (
              <div className="mt-4">
                <NButton variant="text" onClick={() => handleReset()}>
                  Restart the import process
                </NButton>
              </div>
            )}
          </div>
        ) : (
          <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>
                  <Typography variant="h6">
                    Select excel file to import
                  </Typography>
                </StepLabel>
                <StepContent>
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileInput}
                  />
                  {stepActions({ index: 0, enableNext: sheetsData !== null })}
                </StepContent>
              </Step>
              <Step>
                <StepLabel
                  error={
                    !allowImportWithMissingLocations && isStepLocationInvalid
                  }
                >
                  <Typography
                    variant="h6"
                    color={
                      !allowImportWithMissingLocations && isStepLocationInvalid
                        ? "error"
                        : "black"
                    }
                  >
                    Review locations
                  </Typography>
                </StepLabel>
                <StepContent>
                  <div className="flex flex-col space-y-4">
                    {!sheetsData ? (
                      <NBaseAlert
                        severity="error"
                        title="No data imported"
                        description="No data imported"
                      />
                    ) : isStepLocationInvalid ? (
                      <div>
                        <div className="flex flex-row justify-start items-center space-x-2 pt-2 pb-4">
                          <NBaseAlert
                            severity={
                              allowImportWithMissingLocations ? "info" : "error"
                            }
                            title="Could not match all locations from Excel to FM-SIITE"
                            description={
                              <>
                                <Typography variant="body1">
                                  <b>
                                    {
                                      locationGroupFromExcel.foundLocations
                                        .length
                                    }
                                  </b>{" "}
                                  locations matched.
                                </Typography>
                                <Typography variant="body1">
                                  <b>
                                    {
                                      locationGroupFromExcel.missingLocations
                                        .length
                                    }
                                  </b>{" "}
                                  locations could not be matched.
                                </Typography>
                              </>
                            }
                          />
                          <FormGroup className="pl-4">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={allowImportWithMissingLocations}
                                  onChange={
                                    handleChangeAllowImportMissingLocations
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Continue import with missing locations."
                            />
                          </FormGroup>
                        </div>
                        {!allowImportWithMissingLocations && (
                          <NBaseAlert
                            severity="info"
                            title="Notes"
                            description={
                              <>
                                <Typography variant="body1">
                                  It is not possible to automatically import the
                                  missing locactions as there is guaranteed that
                                  the locations imported from FieldManager are
                                  the same as the ones in the Excel file.
                                </Typography>
                                <Typography variant="body1">
                                  You either need to only continue the import
                                  process with matched locations or cancel the
                                  import process, add the missing locations to
                                  FM-SIITE and start the excel import again.
                                </Typography>
                              </>
                            }
                          />
                        )}
                      </div>
                    ) : (
                      <NBaseAlert
                        severity="info"
                        title="Soil units"
                        description="All locations from Excel found in FM-SIITE"
                      />
                    )}
                  </div>

                  {stepActions({
                    index: 1,
                    enablePrev: true,
                    enableNext:
                      allowImportWithMissingLocations || !isStepLocationInvalid,
                  })}
                </StepContent>
              </Step>
              <Step>
                <StepLabel error={isStepSoilUnitInvalid}>
                  <Typography
                    variant="h6"
                    color={isStepSoilUnitInvalid ? "error" : "black"}
                  >
                    Review soil units
                  </Typography>
                </StepLabel>
                <StepContent>
                  <div className="flex flex-col space-y-4">
                    {!sheetsData ? (
                      <NBaseAlert
                        severity="error"
                        title="No data imported"
                        description="No data imported"
                      />
                    ) : isStepSoilUnitInvalid ? (
                      <div className="flex flex-row justify-start items-center space-x-2 pt-2 pb-4">
                        <NBaseAlert
                          severity="error"
                          title="Could not match all soilUnits from Excel to FM-SIITE"
                          description={
                            <>
                              <Typography variant="body1">
                                <b className="pl-1">
                                  {
                                    soilUnitFromExcel.filter(
                                      (unit) => unit.soil_unit_id !== "",
                                    ).length
                                  }
                                </b>{" "}
                                soilUnits matched.
                              </Typography>
                              <Typography variant="body1">
                                <b className="pl-1">
                                  {
                                    locationGroupFromExcel
                                      .unMatchedUsedSoilUnits.length
                                  }
                                </b>{" "}
                                soilUnits could not be matched.
                              </Typography>
                            </>
                          }
                        />
                        <NButton
                          variant="text"
                          onClick={handleCreateAllMissingSoilUnits}
                        >
                          Create all missing soil units
                        </NButton>
                      </div>
                    ) : (
                      <NBaseAlert
                        severity="info"
                        title="Soil units"
                        description="All soil units from Excel found in FM-SIITE"
                      />
                    )}
                  </div>

                  {stepActions({
                    index: 2,
                    enablePrev: true,
                    enableNext: !isStepSoilUnitInvalid,
                  })}
                </StepContent>
              </Step>
              <Step>
                <StepLabel error={isStepLocationGroupInvalid}>
                  <Typography
                    variant="h6"
                    color={isStepLocationGroupInvalid ? "error" : "black"}
                  >
                    Review locations and location groups
                  </Typography>
                </StepLabel>
                <StepContent>
                  <div className="flex flex-col space-y-4">
                    {isStepLocationGroupInvalid ? (
                      <div className="flex flex-row justify-evenly items-center space-x-2 pt-2 pb-4">
                        <NBaseAlert
                          severity="error"
                          title="Could not match all locationGroups from Excel to FM-SIITE"
                          description={
                            <>
                              <Typography variant="body1">
                                <b className="pl-1">
                                  {
                                    locationGroupFromExcel.matchedLocationGroupCount
                                  }
                                </b>{" "}
                                locationGroups matched.
                              </Typography>
                              <Typography variant="body1">
                                <b className="pl-1">
                                  {
                                    locationGroupFromExcel.unMatchedLocationGroupCount
                                  }
                                </b>{" "}
                                locationGroups could not be matched.
                              </Typography>
                            </>
                          }
                        />

                        <NButton
                          variant="text"
                          onClick={handleCreateAllMissingLocationGroups}
                        >
                          Create all missing location groups
                        </NButton>
                      </div>
                    ) : (
                      <>
                        <NBaseAlert
                          severity="info"
                          title="Location groups"
                          description="All location groups from Excel found in FM-SIITE"
                        />
                      </>
                    )}
                  </div>

                  {stepActions({
                    index: 3,
                    enablePrev: true,
                    enableNext: !isStepLocationGroupInvalid,
                  })}
                </StepContent>
              </Step>
              <Step>
                <StepLabel
                  error={isStepLocationGroupInvalid || isStepSoilUnitInvalid}
                >
                  <Typography
                    variant="h6"
                    color={
                      isStepLocationGroupInvalid || isStepSoilUnitInvalid
                        ? "error"
                        : "black"
                    }
                  >
                    Import the layers for the location groups
                  </Typography>
                </StepLabel>
                <StepContent>
                  <div className="flex flex-col space-y-4"></div>

                  {stepActions({
                    index: 3,
                    enablePrev: true,
                    enableNext: true,
                    isLastStep: true,
                  })}
                </StepContent>
              </Step>
            </Stepper>
            {/* {activeStep === steps.length && ( */}
            {/*   <Paper square elevation={0} sx={{ p: 3 }}> */}
            {/*     <Typography> */}
            {/*       All steps completed - you&apos;re finished */}
            {/*     </Typography> */}
            {/*     <NButton onClick={handleReset} sx={{ mt: 1, mr: 1 }}> */}
            {/*       Reset */}
            {/*     </NButton> */}
            {/*   </Paper> */}
            {/* )} */}
          </Box>
        )}
      </div>
      {openCreateSoilUnitDialog && (
        <CreateOrUpdateSoilUnit
          open={openCreateSoilUnitDialog}
          setOpen={handleOnCreatedSoilUnit}
          revision_id={revision_id}
          initialData={{
            ...soilUnitDefaultValues,
            name: selectedSoilUnitToCreate?.excelName || "",
          }}
        />
      )}
    </NDialog>
  );
};
