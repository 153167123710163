import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper, SortingState } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { CptPlotPreview } from "src/pages/revisionLocations/CptPlotPreview";
import { getAppConfig } from "src/queries/queries";
import { Location } from "src/types/locations";
import { sortData, SortOption } from "src/utils/arrays";
import { ActionCellFactory } from "./tableFeatures/ActionCell";

type Props = {
  project_id: string;
  locations: Location[];
};

const columnHelper = createColumnHelper<Location>();

export const LocationTable = ({ project_id, locations }: Props) => {
  const { data: config } = useQuery(getAppConfig);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false },
  ]);

  // Memoize sorted locations based on current sorting state
  const sortedLocations = useMemo(
    () => sortData<Location>(locations, sorting as SortOption<Location>[]),
    [locations, sorting],
  );

  const [openCptPreview, setOpenCptPreview] = useState(false);
  const [selectedSiiteLocationID, setSelectedSiiteLocationID] = useState<
    string | null
  >(null);

  const handlePreviewClick = (cpt_file_id: string) => {
    setSelectedSiiteLocationID(cpt_file_id);
    setOpenCptPreview(true);
  };

  const columns = [
    columnHelper.accessor("name", {
      cell: (row) => row.getValue(),
      header: "Location",
      sortUndefined: "last",
      sortDescFirst: false,
    }),
    columnHelper.accessor("point_easting", {
      cell: (row) => row.getValue(),
      header: "Easting",
      size: 130,
      sortUndefined: "last",
      sortDescFirst: false,
    }),
    columnHelper.accessor("point_northing", {
      cell: (row) => row.getValue(),
      header: "Northing",
      size: 130,
      sortUndefined: "last",
      sortDescFirst: false,
    }),

    columnHelper.accessor("siite_location_id", {
      size: 60,
      header: "",
      cell: ActionCellFactory(
        project_id,
        config?.env || "TEST",
        handlePreviewClick,
      ),
      enableColumnFilter: false,
      id: "Actions",
    }),
  ];

  return (
    <Box>
      <Datatable
        data={locations}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        usePagination={locations.length > 10}
        pinnedColumns={{ right: ["Actions"] }}
      />

      {openCptPreview && selectedSiiteLocationID && (
        <>
          <CptPlotPreview
            open={openCptPreview}
            setOpen={setOpenCptPreview}
            project_id={project_id}
            siite_location_id={selectedSiiteLocationID}
            locations={sortedLocations}
          />
        </>
      )}
    </Box>
  );
};
