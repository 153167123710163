import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";

import { NButton, NCard, NCardHeader } from "@ngi/react-component";

type Props = {
  setOpen: (open: boolean) => void;
};

export const ProjectPhaseHeader = ({ setOpen }: Props) => (
  <Box>
    <NCard>
      <NCardHeader
        title="Project phases"
        action={
          <NButton
            startIcon={<Add />}
            variant="text"
            onClick={() => setOpen(true)}
          >
            Create project phase
          </NButton>
        }
      />
    </NCard>
  </Box>
);
