import { Box } from "@mui/material";

import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { TableCustomCell } from "src/types/table";

export const SoilColorCell = ({
  getValue,
}: TableCustomCell<SoilUnitSchemaType, SoilUnitSchemaType["color"]>) => {
  const color = getValue() || "#000000";

  return (
    <Box className="h-8 w-8 rounded-full" sx={{ background: color }}></Box>
  );
};
