import { createContext, useContext } from "react";
import { Map } from "ol";

export const MapContext = createContext<Map>(new Map());

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMap must be used within a MyMap component");
  }
  return context;
};
