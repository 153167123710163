import { useCallback, useEffect, useMemo, useState } from "react";
import { Grading } from "@mui/icons-material";
import { AlertTitle, Avatar, Box, Chip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import {
  NButton,
  NCard,
  NCardContent,
  NDialog,
  NInlineAlert,
} from "@ngi/react-component";
import { useRowSelection } from "src/components/common/datatable/hooks/useRowSelection";
import { DrawPolygonInteraction } from "src/components/mapElement/DrawPolygonInteraction";
import { LocationLayer } from "src/components/mapElement/LocationLayer";
import { MapElement } from "src/components/mapElement/MapElement";
import { MapScale } from "src/components/mapElement/MapScale";
import { PageContentHeader } from "src/components/PageContentHeader";
import {
  MAP_MARKER_PENDING,
  MAP_MARKER_PRIMARY,
} from "src/definitions/constants";
import {
  batchAddRevisionLocationGroupMutationQuery,
  batchUpdateRevisionLocationGroupMutationQuery,
  deleteRevisionLocationGroupMutationQuery,
} from "src/queries/mutations";
import { locationGroupSchema } from "src/schemas/locationGroup";
import { LocationGroup } from "src/types/locationGroup";
import { Location, LocationNameMapping } from "src/types/locations";
import { createLocationGroup } from "src/utils/groupLocations";
import { CreateLocationGroupTable } from "./CreateLocationGroupTable";
import {
  binLocationGroupActions,
  checkLocationGroupStatus,
} from "./location-group-utils";
import { AutoGroupMode, AutoLocationGroup } from "./LocationGroupAutoGrouping";
import { LocationGroupStatusChange } from "./LocationGroupStatusChange";
import { LocationTable } from "./LocationTable";

const LOCATION_IN_GROUP_COLOR = MAP_MARKER_PRIMARY;
const LOCATION_NOT_IN_GROUP_COLOR = MAP_MARKER_PENDING;

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  revision_id: string;
  locations: Location[];
  locationNameMapping: LocationNameMapping;
  locationGroups: LocationGroup[];
};

export const CreateLocationGroupDialog = ({
  open,
  setOpen,
  revision_id,
  locations,
  locationNameMapping,
  locationGroups,
}: Props) => {
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);

  const [autoGroupMode, setAutoGroupMode] = useState<AutoGroupMode>("auto");
  const [autoGroupStringLength, setAutoGroupStringLength] = useState(2);
  const showGroupHighlight = useMemo(
    () => autoGroupMode === "name",
    [autoGroupMode],
  );

  const [initialLocationGroups, setInitialLocationGroups] = useState<
    LocationGroup[]
  >(locationGroups || []);

  const locationGroupChecker = useMemo(
    () => checkLocationGroupStatus(locationGroups, initialLocationGroups),
    [initialLocationGroups, locationGroups],
  );

  const locationGroupIsValid = useMemo(() => {
    console.log("locationGroups", initialLocationGroups);
    console.log(
      "locationGroupIsValid",
      locationGroupSchema.safeParse(initialLocationGroups),
    );
    const { success } = locationGroupSchema.safeParse(initialLocationGroups);
    return success;
  }, [initialLocationGroups]);

  const createLocationGroups = useMutation(
    batchAddRevisionLocationGroupMutationQuery(revision_id),
  );

  const updateLocationGroups = useMutation(
    batchUpdateRevisionLocationGroupMutationQuery(revision_id),
  );

  const deleteLocationGroup = useMutation(
    deleteRevisionLocationGroupMutationQuery(revision_id),
  );

  const selectedLocations = useMemo(
    () =>
      locations.filter((location) =>
        selectedLocationIds.includes(location.siite_location_id),
      ),
    [locations, selectedLocationIds],
  );

  const locationWithGroupAsIds = useMemo(
    () =>
      initialLocationGroups.reduce((acc, group) => {
        acc.push(...group.location_ids);
        return acc;
      }, [] as string[]),
    [initialLocationGroups],
  );

  const locationWithGroup = useMemo(
    () =>
      locations.filter((location) =>
        locationWithGroupAsIds.includes(location.siite_location_id),
      ),
    [locationWithGroupAsIds, locations],
  );

  const locationWithoutGroup = useMemo(
    () =>
      locations.filter(
        (location) =>
          !locationWithGroupAsIds.includes(location.siite_location_id),
      ),
    [locationWithGroupAsIds, locations],
  );

  const locationGroupBinnedActions = useMemo(() => {
    const actions = binLocationGroupActions(
      locationGroups,
      initialLocationGroups,
    );
    return actions;
  }, [initialLocationGroups, locationGroups]);

  const handleResetAllLocationGroup = useCallback(() => {
    setInitialLocationGroups([]);
  }, []);

  const handleSetLocationGroupFromMap = useCallback(
    (selectedLocations: Location[]) => {
      const newGroup = createLocationGroup(selectedLocations);
      setInitialLocationGroups([...initialLocationGroups, newGroup]);
    },
    [initialLocationGroups],
  );

  const handleSetLocationGroup = useCallback(() => {
    const actions = locationGroupBinnedActions;

    if (actions.add.length > 0) {
      createLocationGroups.mutate({
        revision_id,
        payload: { revision_id, groups: actions.add },
      });
    }

    if (actions.update.length > 0) {
      updateLocationGroups.mutate({
        revision_id,
        payload: { revision_id, groups: actions.update },
      });
    }

    if (actions.remove.length > 0) {
      for (const group of actions.remove) {
        deleteLocationGroup.mutate({
          location_group_id: group.location_group_id,
        });
      }
    }

    setOpen(false);
  }, [
    createLocationGroups,
    deleteLocationGroup,
    locationGroupBinnedActions,
    revision_id,
    setOpen,
    updateLocationGroups,
  ]);

  // TODO: do the same for the location group table
  const { rowSelection, setRowSelection, handleOnRowSelectionChange } =
    useRowSelection<Location>({
      data: locationWithoutGroup,
      rowKey: "siite_location_id",
      initialRowSelection: selectedLocationIds,
      onRowSelectionChange: setSelectedLocationIds,
    });

  useEffect(() => {
    if (selectedLocations.length === 0) {
      setRowSelection({});
    }
  }, [setRowSelection, selectedLocations.length]);

  const selectLocationActions = useMemo(
    () => (
      <Box className="flex flex-col xl:flex-row justify-between items-center w-full xl:pl-[320px] ">
        <Box className="grow flex items-center justify-center">
          {!locationGroupIsValid && locationGroups.length > 0 ? (
            <NInlineAlert severity="error">
              The location groups are invalid, please check the error messages
              in the table
            </NInlineAlert>
          ) : (
            <LocationGroupStatusChange
              locationGroupBinnedActions={locationGroupBinnedActions}
            />
          )}
        </Box>
        <Box className="w-[320px] self-end">
          <NButton
            onClick={() => handleSetLocationGroup()}
            disabled={!locationGroupIsValid}
            startIcon={<Grading />}
          >
            {initialLocationGroups.length === 0
              ? "No location group"
              : "Set location groups"}
          </NButton>
          <NButton onClick={() => setOpen(false)} variant="text">
            Cancel
          </NButton>
        </Box>
      </Box>
    ),
    [
      handleSetLocationGroup,
      initialLocationGroups.length,
      locationGroupBinnedActions,
      locationGroupIsValid,
      locationGroups.length,
      setOpen,
    ],
  );

  return (
    <NDialog
      dialogTitle="Define location groups"
      onClose={() => setOpen(false)}
      open={open}
      fullScreen
      actions={selectLocationActions}
      disableEscapeKeyDown
    >
      <div className="w-full">
        <PageContentHeader
          title="Location groups"
          actions={
            <NButton
              variant="text"
              onClick={() => handleResetAllLocationGroup()}
              disabled={initialLocationGroups.length === 0}
            >
              Reset all location groups
            </NButton>
          }
        />
        <Box className="py-4 px-2">
          <NCard className="w-full">
            <NCardContent className="!p-0">
              {locationWithGroupAsIds && locationWithGroupAsIds.length > 0 ? (
                <CreateLocationGroupTable
                  locationNameMapping={locationNameMapping}
                  locationGroups={initialLocationGroups}
                  locationGroupChecker={locationGroupChecker}
                  locationWithoutGroup={locationWithoutGroup}
                  setLocationGroups={setInitialLocationGroups}
                />
              ) : (
                <Box>
                  <NInlineAlert severity="info">
                    No location groups. Select the locations to create a group
                    in the table below.
                  </NInlineAlert>
                  <NInlineAlert severity="info">
                    Use the auto grouping features to get started with the
                    grouping.
                  </NInlineAlert>
                </Box>
              )}
            </NCardContent>
          </NCard>
        </Box>
      </div>

      <div className="flex flex-col xl:flex-row w-full">
        <div className="xl:w-1/2">
          <PageContentHeader title="Locations not in location groups" />
          <Box className="py-4 px-2">
            <NCard>
              <NCardContent className="!p-0">
                {locationWithoutGroup && locationWithoutGroup.length > 0 ? (
                  <Box className="">
                    <AutoLocationGroup
                      autoGroupMode={autoGroupMode}
                      setAutoGroupMode={setAutoGroupMode}
                      locations={locationWithoutGroup}
                      selectedLocations={selectedLocations}
                      locationGroups={initialLocationGroups}
                      setLocationGroups={setInitialLocationGroups}
                      revision_id={revision_id}
                      setSelectedLocationIds={setSelectedLocationIds}
                      autoGroupStringLength={autoGroupStringLength}
                      setAutoGroupStringLength={setAutoGroupStringLength}
                    />
                    <LocationTable
                      locations={locationWithoutGroup}
                      rowSelection={rowSelection}
                      setRowSelection={handleOnRowSelectionChange}
                      autoGroupStringLength={autoGroupStringLength}
                      showStringHighlight={showGroupHighlight}
                    />
                  </Box>
                ) : (
                  <div className="p-2">
                    <NInlineAlert severity="info">
                      All location are in a location group.
                    </NInlineAlert>
                  </div>
                )}
              </NCardContent>
            </NCard>
          </Box>
        </div>

        <div
          className="xl:w-1/2 pl-2"
          style={{ height: "100%", minHeight: "400px" }}
        >
          <MapElement>
            <LocationLayer
              locations={locationWithoutGroup}
              pointColor={LOCATION_NOT_IN_GROUP_COLOR}
            />
            <LocationLayer
              locations={locationWithGroup}
              pointColor={LOCATION_IN_GROUP_COLOR}
            />
            <MapScale
              locations={[...locationWithoutGroup, ...locationWithGroup]}
            />
            <DrawPolygonInteraction
              locations={locationWithoutGroup}
              onSelection={handleSetLocationGroupFromMap}
            />
          </MapElement>
          <Box>
            <Box className="mt-4 flex gap-4 items-center justify-center">
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: LOCATION_IN_GROUP_COLOR }}> </Avatar>
                }
                label="Locations in location groups"
              />
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: LOCATION_NOT_IN_GROUP_COLOR }}>
                    {" "}
                  </Avatar>
                }
                label="Locations not in location groups"
              />
            </Box>
            <Box className="mt-4">
              <NInlineAlert severity="info">
                <AlertTitle>Create location group from the map</AlertTitle>
                <ol className="!list-decimal">
                  <li>1. left click on the map to create a polygon</li>
                  <li>2. double click to close the polygon</li>
                  <li>
                    if there are locations that are already part of a group,
                    they will be added to a new location group.
                  </li>
                  <li>
                    To stop drawing a polygon hit the ESCAPE key or right click
                  </li>
                </ol>
              </NInlineAlert>
            </Box>
          </Box>
        </div>
      </div>
    </NDialog>
  );
};
