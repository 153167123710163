import * as d3 from "d3";
import { PlotLineStyle } from "frontend/src/components/common/plots/types";

import { DEFAULT_LINE_STYLE } from "src/components/common/plots/constants";

interface DataPoint {
  x: number;
  y: number;
}

interface CurveLayerOptions {
  style?: PlotLineStyle;
}

type RenderProps = {
  content: d3.Selection<SVGGElement, unknown, null, undefined>;
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
};

type SpanHelperProps = RenderProps & {
  data: DataPoint[];
  style: Required<PlotLineStyle>;
  className: string;
  id: string;
};

const spanHelper = ({
  content,
  data,
  xScale,
  yScale,
  style,
  id,
  className,
}: SpanHelperProps) => {
  const curveType = d3.curveLinear;

  // Create a line generator with the appropriate curve type
  const lineGenerator = d3
    .line<DataPoint>()
    .x((d) => xScale(d.x))
    .y((d) => yScale(d.y))
    .curve(curveType); // Use CatmullRom for smooth curve if curve=true, linear otherwise

  // Bind data and create the line path
  const path = content
    .selectAll<SVGPathElement, DataPoint[]>(`.${className}--${id}`) // Use the class with a unique id for selection
    .data([data], (d) => `${id}-${d[0].x}-${d[0].y}-${d[1].x}-${d[1].y}`); // Ensure unique key for each line

  path
    .join(
      (enter) =>
        enter
          .append("path")
          .attr("class", `${className} ${className}--${id}`) // Set unique class based on id
          .attr("d", lineGenerator) // Generate the initial line path
          .style("stroke", style.stroke)
          .style("fill", style.fill)
          .style("stroke-width", style.strokeWidth)
          .style("stroke-dasharray", style.strokeDasharray),
      (update) =>
        update
          .attr("d", lineGenerator) // Update the line path on zoom or data change
          .style("stroke", style.stroke)
          .style("fill", style.fill)
          .style("stroke-width", style.strokeWidth)
          .style("stroke-dasharray", style.strokeDasharray),
      (exit) => exit.remove(), // Remove the line when it is no longer in the data
    )
    .transition() // Add transitions if needed
    .style("stroke", style.stroke)
    .style("fill", style.fill)
    .style("stroke-width", style.strokeWidth)
    .style("stroke-dasharray", style.strokeDasharray);
};

type HSpanProps = {
  id: string;
  y: number;
  y2?: number;
  options?: CurveLayerOptions;
};

export const hSpan =
  ({ id, y, y2, options = {} }: HSpanProps) =>
  ({ content, xScale, yScale }: RenderProps) => {
    const domain = xScale.domain();

    const data = [
      { x: domain[0], y: y },
      { x: domain[1], y: y2 || y },
    ];

    const style = { ...DEFAULT_LINE_STYLE, ...options.style };

    spanHelper({
      content,
      data,
      xScale,
      yScale,
      id,
      className: "h-span",
      style,
    });
  };

type VSpanProps = {
  id: string;
  x: number;
  x2?: number;
  options?: CurveLayerOptions;
};

export const vSpan =
  ({ id, x, x2, options = {} }: VSpanProps) =>
  ({ content, xScale, yScale }: RenderProps) => {
    const domain = yScale.domain();

    const data = [
      { x, y: domain[0] },
      { x: x2 || x, y: domain[1] },
    ];

    const style = { ...DEFAULT_LINE_STYLE, ...options.style };

    spanHelper({
      content,
      data,
      xScale,
      yScale,
      id,
      className: "v-span",
      style,
    });
  };
