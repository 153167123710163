import * as d3 from "d3";

import { RenderLayer } from "src/components/common/plots/types";
import { getLineStroke } from "src/components/common/plots/utils/lineSroke";
import { LineStrokeStyle } from "src/types/plotElements";

interface CurveLayerOptions {
  curve?: boolean; // Optional: Render a smooth curve (CatmullRom) if true, straight line if false (default)
  style?: {
    stroke?: string; // Line color
    strokeWidth?: number; // Line width
    fill?: string; // Fill color (usually none for a line)
    strokeDasharray?: LineStrokeStyle; // Dash pattern (e.g., "5,5" for dashed lines), default is solid
  };
}

type Props<T> = {
  id: string;
  data: T[];
  xKey: keyof T;
  yKey: keyof T;
  options?: CurveLayerOptions;
};

const isDefined = (d: number | undefined | null) =>
  d !== undefined && d !== null;

export const curveLayer =
  <T>({ id, data, xKey, yKey, options = {} }: Props<T>) =>
  ({ content, xScale, yScale }: RenderLayer) => {
    const { curve = false, style = {} } = options;

    const {
      stroke = "blue", // Default stroke color
      strokeWidth = 2, // Default stroke width
      fill = "none", // Default fill color
      strokeDasharray = "solid",
    } = style;

    // Set curve type based on the curve option
    const curveType = curve ? d3.curveCatmullRom : d3.curveLinear;

    // Create a line generator with the appropriate curve type
    const lineGenerator = d3
      .line<T>()
      .x((d) => xScale(d[xKey] as number))
      .y((d) => yScale(d[yKey] as number))
      .defined((d) => isDefined(d[xKey]) && isDefined(d[yKey]))
      .curve(curveType); // Use CatmullRom for smooth curve if curve=true, linear otherwise

    // Bind data and create the line path
    const path = content.selectAll(`.line-path--${id}`).data([data]);

    path
      .join(
        (enter) =>
          enter
            .append("path")
            .attr("class", `line-path line-path--${id}`) // Set unique class based on id
            .attr("d", lineGenerator) // Generate the initial line path
            .style("stroke", stroke)
            .style("fill", fill)
            .style("stroke-width", strokeWidth)
            .style("stroke-dasharray", getLineStroke(strokeDasharray)),
        (update) => update.attr("d", lineGenerator), // Update the line path on zoom or data change
      )
      .transition() // Add transitions if needed
      .style("stroke", stroke)
      .style("fill", fill)
      .style("stroke-width", strokeWidth)
      .style("stroke-dasharray", strokeDasharray);
  };
