export function findLast<T>(
  arr: T[],
  condition: (element: T) => boolean,
): T | undefined {
  const filteredElements = arr.filter(condition);
  return filteredElements.length > 0
    ? filteredElements[filteredElements.length - 1]
    : undefined;
}

export type SortOption<T> = {
  id: keyof T;
  desc: boolean;
};

export function sortData<T>(data: T[], sorting: SortOption<T>[]): T[] {
  if (sorting.length === 0) return data;
  return [...data].sort((a, b) => {
    for (const sort of sorting) {
      const { id, desc } = sort;
      const aValue = a[id];
      const bValue = b[id];

      // Handle undefined or null values (they should always be at the end)
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      // Sorting logic
      if (aValue < bValue) return desc ? 1 : -1;
      if (aValue > bValue) return desc ? -1 : 1;
    }
    return 0;
  });
}
