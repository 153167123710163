import { DataPoint } from "src/types/data";

// Function to group an array of objects by a key
export function groupByMethodId(data: DataPoint[]): DataPoint[][] {
  const groups: { [methodId: string]: DataPoint[] } = {};

  // Group the data based on method_id
  for (const item of data) {
    if (!groups[item.method_id]) {
      groups[item.method_id] = [];
    }
    groups[item.method_id].push(item);
  }

  // Convert the grouped object into an array of arrays
  return Object.values(groups);
}
