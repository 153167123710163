import { z } from "zod";

export const locationGroupSchema = z
  .array(
    z.object({
      location_group_id: z.string(),
      name: z
        .string({ message: "Name is required" })
        .min(1, { message: "Name must have at least 1 character" }),
      revision_id: z.string(),
      description: z.string().optional().default(""),
      location_ids: z.array(z.string()).min(1),
    }),
  )
  .min(1);
