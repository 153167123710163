import { Line } from "d3";
import {
  DataDomain,
  PlotLineStyle,
} from "frontend/src/components/common/plots/types";

export function styleLine(line: Line, style: PlotLineStyle) {
  line
    .style("stroke", style.stroke)
    .style("fill", style.fill)
    .style("stroke-width", style.strokeWidth)
    .style("stroke-dasharray", style.strokeDasharray);
}

export const getDataFromDomain = (
  data: number,
  domain: DataDomain,
  scale: d3.ScaleLinear<number, number>,
) => {
  const scaleRange = scale.range();

  return domain === "data"
    ? scale(data)
    : domain === "screen"
      ? data
      : data * (scaleRange[0] + scaleRange[1]);
};
