import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { IMPORT_CPT_STATUSES } from "src/definitions/statuses";
import { getRevisionLocationsQuery } from "src/queries/queries";

type Props = {
  project_id: string;
  phase_id: string;
  revision_id: string;
};

export const useRevisionLocations = ({
  project_id,
  phase_id,
  revision_id,
}: Props) => {
  const [hasLocationCPTMissing, setHasLocationCPTMissing] = useState(true);

  const {
    isStale,
    isPending: isLocationsPending,
    isError: isLocationsError,
    data: locations,
    refetch: refetchLocations,
  } = useQuery(
    getRevisionLocationsQuery(
      project_id,
      phase_id,
      revision_id,
      hasLocationCPTMissing,
    ),
  );

  const handleRefetchLocations = useCallback(() => {
    setHasLocationCPTMissing(true);
    refetchLocations();
  }, [refetchLocations]);

  const hasMissingCPT = useMemo(() => {
    let hasMissingCPT = false;
    if (!locations || locations.length === 0) {
      hasMissingCPT = false;
    } else {
      if (locations.length === 0) {
        hasMissingCPT = true;
      } else {
        hasMissingCPT = locations.some(
          (location) =>
            !location.import_status ||
            location.import_status === IMPORT_CPT_STATUSES.WAITING,
        );
      }
    }
    setHasLocationCPTMissing(hasMissingCPT);
    return hasMissingCPT;
  }, [locations]);

  useEffect(() => {
    if (isStale && hasMissingCPT) {
      refetchLocations();
    }
  }, [locations, isStale, refetchLocations, hasMissingCPT]);

  return {
    hasLocationCPTMissing,
    isLocationsPending,
    isLocationsError,
    locations,
    refetchLocations,
    handleRefetchLocations,
  };
};
