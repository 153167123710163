import { Alert, AlertTitle, Box } from "@mui/material";

export const ErrorComponent = () => (
  <Box className="mt-24 h-ful flex flex-col justify-center items-center">
    <Alert severity="error" variant="outlined" className="w-96">
      <AlertTitle>Error</AlertTitle>
      Something went wrong while loading the page...
    </Alert>
  </Box>
);
