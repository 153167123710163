import { Box, Typography } from "@mui/material";

type Props = {
  error: Error;
};

export const ErrorComponent = ({ error }: Props) => (
  <Box className="mt-8 flex flex-col justify-start">
    <Typography variant="h6">Error: Something went wrong</Typography>
    <Typography variant="body1">{error.message}</Typography>
  </Box>
);
