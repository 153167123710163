import { DataDomain } from "frontend/src/components/common/plots/types";

import { TEXT_STYLE } from "src/components/common/plots/constants";
import { getDataFromDomain } from "./utils";

type DataPointDomain = {
  x: DataDomain;
  y: DataDomain;
};

interface DataPoint {
  x: number;
  y: number;
  domain?: DataPointDomain;
}

type Props = {
  text: string;
  pos: DataPoint;
  anchor?: "start" | "middle" | "end";
  rotate?: number;
  style?: React.CSSProperties; // Optional style prop for text styling
};

export const textLayer =
  ({ text, pos, anchor = "start", rotate = 0, style = {} }: Props) =>
  ({
    content,
    xScale,
    yScale,
  }: {
    content: d3.Selection<SVGGElement, unknown, null, undefined>;
    xScale: d3.ScaleLinear<number, number>;
    yScale: d3.ScaleLinear<number, number>;
  }) => {
    const textStyle = { ...TEXT_STYLE, ...style };

    const x = getDataFromDomain(pos.x, pos.domain?.x || "data", xScale);
    const y = getDataFromDomain(pos.y, pos.domain?.y || "data", yScale);

    content
      .append("text")
      .attr("x", x)
      .attr("y", y)
      .attr(
        "transform",
        `rotate(${rotate}, ${x}, ${y})`, // Apply rotation around the (x, y) point
      )
      .attr("text-anchor", anchor) // Apply text anchor for alignment
      // .attr("text-anchor", textStyle.align) // Apply text anchor for alignment
      .style("font-size", textStyle.fontSize || "12px") // Apply optional styles
      .style("font-family", textStyle.fontFamily || "sans-serif")
      .style("fill", textStyle.color || "black")
      .style("font-weight", textStyle.fontWeight || "normal")
      .text(text);
  };
