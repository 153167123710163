import { useEffect,useState } from "react";

export const useResizeObserver = (ref: React.RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    const observerTarget = ref.current;
    if (!observerTarget) return;

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });

    resizeObserver.observe(observerTarget);

    return () => {
      if (observerTarget) resizeObserver.unobserve(observerTarget);
    };
  }, [ref]);

  return dimensions;
};
