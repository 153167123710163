import { ProjectPhase } from "src/types/projectPhase";
import { ProjectPhaseItem } from "./ProjectPhaseItem";

export const ProjectPhases = ({
  project_id,
  phases,
}: {
  project_id: string;
  phases: ProjectPhase[];
}) => (
  <div className="flex flex-col gap-4">
    {phases &&
      phases.map((phase) => (
        <ProjectPhaseItem
          project_id={project_id}
          phase={phase}
          key={phase.phase_id}
        />
      ))}
  </div>
);
