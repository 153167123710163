import { useCallback, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { NButton } from "@ngi/react-component";
import { CreateLocationGroup, LocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";
import {
  autoGroupLocations,
  createLocationGroup,
  groupLocationByRelativeDistance,
  groupLocationsByNameSimilarity,
} from "src/utils/groupLocations";
import { setLocationGroupForTable } from "./location-group-utils";

export type AutoGroupMode =
  | "auto"
  | "manual"
  | "distance"
  | "name"
  | "1location1group";

const autoGroupModeOptions = [
  { label: "Auto", value: "auto" },
  { label: "Manual", value: "manual" },
  { label: "Distance", value: "distance" },
  { label: "Name similarity", value: "name" },
  { label: "1 location - 1 Group", value: "1location1group" },
];

type Props = {
  autoGroupMode: AutoGroupMode;
  setAutoGroupMode: (mode: AutoGroupMode) => void;
  locations: Location[];
  selectedLocations: Location[];
  setSelectedLocationIds: (locations: string[]) => void;
  locationGroups: LocationGroup[];
  setLocationGroups: (locations: LocationGroup[]) => void;
  revision_id: string;
  autoGroupStringLength: number;
  setAutoGroupStringLength: (value: number) => void;
};

export const AutoLocationGroup = ({
  autoGroupMode,
  setAutoGroupMode,
  revision_id,
  locations,
  selectedLocations,
  locationGroups,
  setLocationGroups,
  setSelectedLocationIds,
  autoGroupStringLength,
  setAutoGroupStringLength,
}: Props) => {
  const [distance, setDistance] = useState(10);

  const locationForGrouping =
    selectedLocations.length > 0 ? selectedLocations : locations;

  const handleSetLocationGroups = useCallback(
    (groups: CreateLocationGroup[]) => {
      const groupWithRevisionId = groups.map((g) => ({ ...g, revision_id }));
      console.log(
        "handleSetLocationGroups groupWithRevisionId:",
        groupWithRevisionId,
      );
      const newGroups = [...locationGroups, ...groupWithRevisionId].sort(
        (a, b) => a.name.localeCompare(b.name),
      ) as LocationGroup[];
      setLocationGroups(newGroups);
      setSelectedLocationIds([]);
    },
    [locationGroups, revision_id, setLocationGroups, setSelectedLocationIds],
  );

  const handleGroupLocation = useCallback(() => {
    let groups: CreateLocationGroup[] = [];

    switch (autoGroupMode) {
      case "auto":
        groups = autoGroupLocations(locationForGrouping);
        break;
      case "manual":
        groups = [createLocationGroup(selectedLocations)];
        break;
      case "distance":
        groups = groupLocationByRelativeDistance(locationForGrouping, distance);
        break;
      case "name":
        groups = groupLocationsByNameSimilarity(
          locationForGrouping,
          autoGroupStringLength,
        );
        break;
      case "1location1group":
        groups = setLocationGroupForTable(locationForGrouping, revision_id);
        break;
      default:
        groups = setLocationGroupForTable(locationForGrouping, revision_id);
    }
    console.log("handleAutoGroup groups:", groups);
    handleSetLocationGroups(groups);
  }, [
    autoGroupMode,
    autoGroupStringLength,
    distance,
    handleSetLocationGroups,
    locationForGrouping,
    revision_id,
    selectedLocations,
  ]);

  return (
    <div className="bg-gray-100 px-1 py-2 grid grid-cols-3 gap-4">
      <FormControl fullWidth>
        <InputLabel id="auto-group-mode-label" className="bg-gray-100">
          Grouping mode
        </InputLabel>
        <Select
          labelId="auto-group-mode-label"
          className="SelectCell bg-white"
          id="auto-group-mode"
          value={autoGroupMode}
          displayEmpty
          onChange={(e) => setAutoGroupMode(e.target.value as AutoGroupMode)}
        >
          {autoGroupModeOptions.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {autoGroupMode === "distance" && (
        <TextField
          value={distance}
          label="Max distance between locations"
          onChange={(e) => setDistance(parseInt(e.target.value))}
          prefix="m"
          className="bg-white"
        />
      )}
      {autoGroupMode === "name" && (
        <TextField
          value={autoGroupStringLength}
          label="Minimum length to match names"
          onChange={(e) => setAutoGroupStringLength(parseInt(e.target.value))}
          className="bg-white"
        />
      )}

      <div className="col-start-3">
        <NButton
          variant="text"
          onClick={handleGroupLocation}
          disabled={selectedLocations.length === 0}
        >
          Group locations
        </NButton>
      </div>
    </div>
  );
};
