import { z } from "zod";

export const projectPhaseRevisionSchema = z.object({
  name: z
    .string({ message: "Name is required" })
    .min(1, { message: "Name must have at least 1 character" }),
  description: z.string().optional().default(""),
  use_location_group: z.boolean(),
});

export type ProjectPhaseRevisionSchemaType = z.infer<
  typeof projectPhaseRevisionSchema
>;
