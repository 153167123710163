import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { EditableTextCellFactory } from "src/components/common/datatable/features/editableCells/EditableTextCell";
import { LocationGroup } from "src/types/locationGroup";
import { Location, LocationNameMapping } from "src/types/locations";
import { CheckLocationGroupReturnType } from "./location-group-utils";
import { EditableLocationNameCellFactory } from "./tableFeatures/EditableLocationNameCell";
import { LocationGroupActionCell } from "./tableFeatures/LocationGroupActionCell";
import { LocationGroupStatusCellFactory } from "./tableFeatures/LocationGroupStatusCell";

type Props = {
  locationNameMapping: LocationNameMapping;
  locationGroups: LocationGroup[];
  setLocationGroups: React.Dispatch<React.SetStateAction<LocationGroup[]>>;
  locationWithoutGroup: Location[];
  locationGroupChecker: (
    locationGroup: LocationGroup,
  ) => CheckLocationGroupReturnType;
};

const tableColumnHelper = createColumnHelper<LocationGroup>();

export const CreateLocationGroupTable = ({
  locationNameMapping,
  locationGroups,
  setLocationGroups,
  locationWithoutGroup,
  locationGroupChecker,
}: Props) => {
  const handleTableRowUpdate = useCallback(
    (index: number, key: string, value: string) => {
      setLocationGroups((prev: LocationGroup[]) => {
        const newLocationGroups = [...prev];
        newLocationGroups[index] = {
          ...newLocationGroups[index],
          [key]: value,
        };
        return newLocationGroups;
      });
    },
    [setLocationGroups],
  );

  const columns = useMemo(
    () => [
      tableColumnHelper.accessor("name", {
        cell: EditableTextCellFactory({
          updateData: handleTableRowUpdate,
          field: "name",
        }),
        header: "Name",
        size: 100,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
      }),
      tableColumnHelper.accessor("description", {
        cell: EditableTextCellFactory({
          updateData: handleTableRowUpdate,
          field: "description",
        }),
        // cell: EditableTextCell,
        header: "description",
        size: 200,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
      }),
      tableColumnHelper.accessor("location_ids", {
        cell: EditableLocationNameCellFactory(
          locationNameMapping,
          locationWithoutGroup,
        ),
        header: "Locations",
        size: 400,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false,
      }),
      tableColumnHelper.accessor("location_group_id", {
        cell: LocationGroupStatusCellFactory(locationGroupChecker),
        header: "Status",
        size: 100,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false,
      }),
      tableColumnHelper.accessor("location_group_id", {
        cell: LocationGroupActionCell,
        header: "Actions",
        size: 20,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false,
      }),
    ],
    [
      handleTableRowUpdate,
      locationGroupChecker,
      locationNameMapping,
      locationWithoutGroup,
    ],
  );

  return (
    <Box>
      <Datatable
        data={locationGroups}
        columns={columns}
        usePagination
        editable
        setData={setLocationGroups}
      />
    </Box>
  );
};
