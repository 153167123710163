import axios from "axios";

import { API_BASE_URL } from "src/definitions/constants";

type AuthResponse = {
  valid: boolean;
  url?: string;
};

axios.defaults.withCredentials = true;

export const getAuthSession = async (): Promise<AuthResponse> => {
  const url = `${API_BASE_URL}/api/auth/session`;
  const response = await axios.get(url, { withCredentials: true });
  if (response.data["valid"] === false && "url" in response.data) {
    window.location.href =
      response.data["url"] +
      `&state=${window.location.pathname + window.location.search}`;
  }
  return response.data["valid"];
};

export const getAccessToken = async (): Promise<string> => {
  const url = `${API_BASE_URL}/api/auth/access_token`;
  const response = await axios.get(url, { withCredentials: true });
  return response.data;
};
