import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App.tsx";

//          ╭─────────────────────────────────────────────────────────╮
//          │                    Sentry monitoring                    │
//          ╰─────────────────────────────────────────────────────────╯
// if (import.meta.env.VITE_IS_DEV === "false") {
//   console.info("Sentry is enabled");
//   Sentry.init({
//     dsn: "https://e29364dbc9188cb0d9ae9ace179053f3@o4508018657198080.ingest.de.sentry.io/4508403293487184",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.browserProfilingIntegration(),
//       Sentry.replayIntegration(),
//       Sentry.captureConsoleIntegration({
//         levels: ["log", "info", "warn", "error", "debug", "assert"],
//       }),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [
//       "localhost:5173",
//       /^https:\/\/siite\.(test\.)?geobub\.io\//,
//     ],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     // Reference: https://github.com/getsentry/sentry-javascript/issues/3440
//     // ignoreErrors: ["Non-Error promise rejection captured with value: true"],
//   });
// } else {
//   console.info("Sentry is disabled in development mode");
// }

//          ╭─────────────────────────────────────────────────────────╮
//          │                     React app part                      │
//          ╰─────────────────────────────────────────────────────────╯
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
