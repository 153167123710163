import { diff } from "@opentf/obj-diff";
import { v4 as uuidv4 } from "uuid";

import {
  CreateLocationGroup,
  LocationGroup,
  LocationGroupActions,
} from "src/types/locationGroup";
import { Location } from "src/types/locations";

export const setLocationGroupForTable = (
  locations: Location[],
  revision_id: string,
): CreateLocationGroup[] =>
  locations.map((location) => ({
    location_group_id: uuidv4(),
    name: location.name,
    description: "",
    revision_id,
    location_ids: [location.siite_location_id],
  }));

export const StatusEnum = {
  NOTHING: "NOTHING",
  NEW: "NEW",
  UPDATED: "UPDATED",
  REMOVED: "REMOVED",
  ERROR: "ERROR",
} as const;

export type StatusEnumType = (typeof StatusEnum)[keyof typeof StatusEnum];
export type CheckLocationGroupReturnType = {
  status: StatusEnumType;
  message: string;
};

export const prepareNewLocationGroupPayload = (
  locationGroup: LocationGroup,
): CreateLocationGroup =>
  ({
    // revision_id,
    // location_group_id: uuidv4(),
    name: locationGroup.name,
    description: locationGroup.description,
    location_ids: locationGroup.location_ids,
    layers: [],
  }) as CreateLocationGroup;

export const checkLocationGroupStatus =
  (previousGroups: LocationGroup[], currentGroups: LocationGroup[]) =>
  (group: LocationGroup): CheckLocationGroupReturnType => {
    const previousGroupState = previousGroups.find(
      (locationGroup) =>
        locationGroup.location_group_id === group.location_group_id,
    );
    const isInPreviousGroups = previousGroupState ? true : false;

    const currentGroupState = currentGroups.find(
      (locationGroup) =>
        locationGroup.location_group_id === group.location_group_id,
    );

    const isInCurrentGroups = currentGroupState ? true : false;

    if (isInPreviousGroups && !isInCurrentGroups) {
      return { status: StatusEnum.REMOVED, message: "deleted" };
    }

    if (!isInPreviousGroups && isInCurrentGroups) {
      return { status: StatusEnum.NEW, message: "New" };
    }

    if (isInPreviousGroups && isInCurrentGroups) {
      const groupHasChanged = previousGroupState
        ? diff(previousGroupState, group).length > 0
        : false;

      if (groupHasChanged) {
        return { status: StatusEnum.UPDATED, message: "Updated" };
      }
      return { status: StatusEnum.NOTHING, message: "Nothing" };
    }

    return { status: StatusEnum.NOTHING, message: "Nothing" };
  };

export const getUniqueLocationGroups = (
  previousGroups: LocationGroup[],
  currentGroups: LocationGroup[],
): LocationGroup[] => {
  const allGroupsMap = new Map<string, LocationGroup>();

  [...previousGroups, ...currentGroups].forEach((group) => {
    allGroupsMap.set(group.location_group_id, group);
  });

  return Array.from(allGroupsMap.values());
};

export const binLocationGroupActions = (
  previousGroups: LocationGroup[],
  currentGroups: LocationGroup[],
) => {
  const allGroups = getUniqueLocationGroups(previousGroups, currentGroups);
  const actions = allGroups.reduce(
    (acc, group) => {
      const currentGroupStatus = checkLocationGroupStatus(
        previousGroups,
        currentGroups,
      )(group);
      switch (currentGroupStatus.status) {
        case StatusEnum.NEW:
          acc.add.push(prepareNewLocationGroupPayload(group));
          break;
        case StatusEnum.UPDATED:
          acc.update.push(group);
          break;
        case StatusEnum.REMOVED:
          acc.remove.push(group);
          break;
        default:
          break;
      }
      return acc;
    },
    { add: [], update: [], remove: [] } as LocationGroupActions,
  );
  return actions;
};
