import { Add, Delete, FolderOpen } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import { NButton } from "@ngi/react-component";
import {
  addProjectMutationQuery,
  deleteProjectMutationQuery,
} from "src/queries/mutations";
import { Project } from "src/types/api";

import "./ProjectListItem.scss";

type Props = { project: Project };

export const ProjectListItem = ({ project }: Props) => {
  const deleteProjectMutation = useMutation(deleteProjectMutationQuery());

  return (
    <Box>
      <Paper className="ProjectListItem">
        <Box className="Info">
          <Typography className="ProjectExternalId" variant="h6">
            {project.project_external_id}
          </Typography>
          <Typography variant="h6">{project.name}</Typography>
        </Box>
        <Box className="Actions">
          <Link
            to={`/project/${project.project_id}`}
            params={{ project_id: project.project_id }}
          >
            <NButton startIcon={<FolderOpen />} variant="text">
              Open
            </NButton>
          </Link>
          <NButton
            startIcon={<Delete />}
            onClick={() =>
              deleteProjectMutation.mutate({ project_id: project.project_id })
            }
            variant="text"
            /* @ts-expect-error the color props exists on Mui but not on NGI-reac-component button */
            color="error"
          >
            Delete
          </NButton>
        </Box>
      </Paper>
    </Box>
  );
};

export const ProjectListNotInSiiteItem = ({ project }: Props) => {
  const addProjectMutation = useMutation(addProjectMutationQuery());

  return (
    <Box>
      <Paper className="ProjectListItem">
        <Box className="Info">
          <Typography className="ProjectExternalId" variant="h6">
            {project.project_external_id}
          </Typography>
          <Typography variant="h6">{project.name}</Typography>
        </Box>
        <Box className="Actions">
          <NButton
            startIcon={<Add />}
            onClick={() =>
              addProjectMutation.mutate({
                project_id: project.project_id,
              })
            }
            variant="text"
          >
            Add to Siite
          </NButton>
        </Box>
      </Paper>
    </Box>
  );
};
