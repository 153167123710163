export const keys = {
  auth: ["auth"] as const,
  config: ["config"] as const,
  allProjects: ["projects"] as const,
  project: (project_id: string) => ["project", project_id] as const,
  projectPhases: (project_id: string) =>
    ["project", project_id, "phases"] as const,
  projectFieldManagerLocations: (project_id: string) =>
    ["project", project_id, "fieldmanager", "locations"] as const,
  projectPhaseRevisionLocations: (
    project_id: string,
    phase_id: string,
    revision_id: string,
  ) =>
    [
      "project",
      project_id,
      "phase",
      phase_id,
      "revision",
      revision_id,
      "locations",
    ] as const,
  projectPhaseRevisions: (project_id: string, phase_id: string) =>
    ["project", project_id, "phase", phase_id, "revisions"] as const,
  projectPhaseRevision: (
    project_id: string,
    phase_id: string,
    revision_id: string,
  ) =>
    [
      "project",
      project_id,
      "phase",
      phase_id,
      "revision",
      revision_id,
    ] as const,
  // TODO: maybe we do not need the phase_id r even the project_id?
  locationCptRawFile: (project_id: string, siite_location_id: string) =>
    ["project", project_id, "siiite_location", siite_location_id] as const,
  revisionLocationGroups: (revision_id: string) =>
    ["revision", revision_id, "location_group"] as const,
  revisionSoilUnits: (revision_id: string) =>
    ["revision", revision_id, "soil_units"] as const,
  revisionLocationGroupProcessedCPTs: (
    revision_id: string,
    location_group_id: string,
  ) =>
    [
      "revision",
      revision_id,
      "location_group",
      location_group_id,
      "processedCpts",
    ] as const,
  revisionParentFileExcelExport: (revision_id: string) =>
    ["revision", revision_id, "parentFileExcelExport"] as const,
  revisionGlobalUnitLayerFileExcelExport: (revision_id: string) =>
    ["revision", revision_id, "globalUnitLayerFileExcelExport"] as const,
};
