import { GeoParameter } from "src/types/geoParameters";

type MakeGeoParameter = Pick<GeoParameter, "key" | "name"> &
  Partial<Omit<GeoParameter, "key" | "name">>;

export function makeGeoParameter(props: MakeGeoParameter): GeoParameter {
  const unit = props.unit || "-";
  const axisLabel = props.axisLabel || `${props.name} (${unit})`;
  const valueType = props.valueType || "number";
  const legendLabel = props.legendLabel || props.name;
  return { ...props, unit, axisLabel, valueType, legendLabel };
}
