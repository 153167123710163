import * as d3 from "d3";

import {
  DEFAULT_CIRCLE_STYLE,
  SELECTED_CIRCLE_STYLE,
} from "src/components/common/plots/constants";
import {
  PlotCircleStyle,
  RenderLayer,
} from "src/components/common/plots/types";
import { D3Event } from "src/types/plotInteractions";

interface DataPoint {
  x: number;
  y: number;
}

export type FormattedData<T> = {
  id: number;
  x: number;
  y: number;
  pt: [number, number];
  data: T;
};

export type DragCondition<T> = (
  current: FormattedData<T>,
  circles: FormattedData<T>[],
  event: D3Event<MouseEvent, SVGElement>,
) => boolean;

interface PointOptions<T> {
  style?: PlotCircleStyle;
  selectedStyle?: PlotCircleStyle;
  conditionalStyle?: (
    currentPoint: FormattedData<T>,
    points: FormattedData<T>[],
  ) => PlotCircleStyle;
}

type Props<T> = {
  data: T[];
  updateData: (data: T[]) => void;
  xDataScreen?: number;
  yDataScreen?: number;
  xKey: keyof T;
  yKey: keyof T;
  dragConditions?: DragCondition<T>;
  allowDragOn?: "x" | "y" | "xy";
  options?: PointOptions<T>;
  onRightClick?: (event: MouseEvent, dataPoint: FormattedData<T>) => void;
};

const getDataPercentage = (
  percentage: number,
  scale: d3.ScaleLinear<number, number>,
) => {
  const range = scale.range();
  return (range[1] - range[0]) * percentage;
};

export const editableScatterLayer =
  <T>({
    data,
    updateData,
    xKey,
    yKey,
    allowDragOn = "xy",
    dragConditions,
    xDataScreen,
    yDataScreen,
    onRightClick,
    options = {},
  }: Props<T>) =>
  ({ content, xScale, yScale, width, height }: RenderLayer) => {
    const style = { ...DEFAULT_CIRCLE_STYLE, ...options.style };

    const selectedStyle: Required<PlotCircleStyle> = {
      ...SELECTED_CIRCLE_STYLE,
      ...options.selectedStyle,
    };

    const localData = data.map((d, i) => ({
      id: i,
      pt: [d[xKey], d[yKey]],
      x: xDataScreen
        ? getDataPercentage(xDataScreen, xScale)
        : xScale(d[xKey] as number),
      y: yDataScreen
        ? getDataPercentage(yDataScreen, yScale)
        : yScale(d[yKey] as number),
      index: i,
      data: d,
    })) as FormattedData<T>[];

    let abortDrag = false;

    const getStyle = (point: FormattedData<T>) => {
      const st = options.conditionalStyle
        ? options.conditionalStyle(point, localData)
        : style;
      return { ...style, ...st };
    };

    const handleUpdateData = (data: FormattedData<T>[]) => {
      updateData(
        data.map((p) => ({ ...p.data, [xKey]: p.pt[0], [yKey]: p.pt[1] })),
      );
    };
    const handleRightClick = (event: MouseEvent, d: FormattedData<T>) => {
      event.preventDefault(); // Prevent the default context menu
      event.stopPropagation(); // Prevent interference with dblclick
      if (onRightClick) {
        onRightClick(event, d); // Call the passed function with the event and data point
      }
    };

    const handleAddPoint = (event: MouseEvent) => {
      const [x, y] = d3.pointer(event);
      const localX = x; // because we click on the content area, if clicked on the svg area, need to remove margin.left
      const localY = y; // because we click on the content area, if clicked on the svg area, need to remove margin.top
      const dataX = xScale.invert(localX);
      const dataY = yScale.invert(localY);

      const newDataPoint = {
        id: Math.random(),
        x: localX,
        y: localY,
        pt: [dataX, dataY],
      };

      const newData = [...localData, newDataPoint].map((d, i) => ({
        ...d,
        index: i,
      }));

      handleUpdateData(newData);
    };
    const handleDoubleClick = (event: MouseEvent) => {
      event.stopPropagation(); // Prevent interference with contextmenu
      handleAddPoint(event);
    };

    // Create a local clickable area to capture double-clicks without interfering with other components
    const localClickableArea = content
      .selectAll(".local-clickable-area")
      .data([null]);
    localClickableArea
      .enter()
      .append("rect")
      .attr("class", "local-clickable-area")
      .style("fill", "transparent")
      .style("border", "red")
      .style("border-width", "2px")
      .style("pointer-events", "all")
      .on("dblclick", handleDoubleClick)
      .merge(localClickableArea)
      .attr("width", width)
      .attr("height", height);

    const points = content
      .selectAll<SVGCircleElement, FormattedData<T>>(".editableScatter")
      .data(localData, (d: FormattedData<T>) => d.id.toString()) // Use unique id as key
      .join(
        (enter) =>
          enter
            .append("circle")
            .attr("stroke", (d) => getStyle(d).stroke)
            .attr("stroke-width", (d) => getStyle(d).strokeWidth)
            .attr("fill", (d) => getStyle(d).fill)
            .attr("r", (d) => getStyle(d).radius),
        // Handle update correctly to avoid duplication
        (update) =>
          update
            .attr("stroke", (d) => getStyle(d).stroke)
            .attr("stroke-width", (d) => getStyle(d).strokeWidth)
            .attr("fill", (d) => getStyle(d).fill)
            .attr("r", (d) => getStyle(d).radius),
        (exit) => exit.remove(), // Remove points not in the data
      )
      .attr("class", "editableScatter")
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .on("contextmenu", handleRightClick); // Attach right-click event

    // points.raise();

    const getPoint = (d: FormattedData<T>) => points.filter((p) => p === d);

    const dragstarted = (
      event: D3Event<MouseEvent, SVGElement>,
      d: FormattedData<DataPoint>,
    ) => {
      if (abortDrag) return;
      const point = getPoint(d);
      if (event.sourceEvent.shiftKey) {
        abortDrag = true;
        const subject = event.subject;
        const newData = localData.filter((d) => d.id !== subject.id);
        handleUpdateData(newData);
      } else {
        abortDrag = false;
        point
          .raise()
          .attr("stroke", selectedStyle.stroke)
          .attr("stroke-width", selectedStyle.strokeWidth)
          .attr("fill", selectedStyle.fill)
          .attr("r", selectedStyle.radius);
      }
    };

    const dragged = (
      event: D3Event<MouseEvent, SVGElement>,
      d: FormattedData<T>,
    ) => {
      if (abortDrag) return;
      const subject = event.subject;
      let x = event.x;
      let y = event.y;

      if (!["x", "xy"].includes(allowDragOn)) x = subject.x;
      if (!["y", "xy"].includes(allowDragOn)) y = subject.y;
      //
      // Check drag conditions
      const conditionsMet = dragConditions
        ? dragConditions(d, localData, event)
        : true;
      if (!conditionsMet) return; // Abort drag if any condition fails

      const point = getPoint(d);

      d.x = x;
      d.y = y;
      d.pt = [xScale.invert((d.x = x)), yScale.invert((d.y = y))];

      point.attr("cx", xScale(d.pt[0])).attr("cy", yScale(d.pt[1]));
    };

    const dragended = (
      event: D3Event<MouseEvent, SVGElement>,
      d: FormattedData<T>,
    ) => {
      if (abortDrag) return;
      const point = getPoint(d);
      point
        .attr("stroke", (d) => getStyle(d).stroke)
        .attr("stroke-width", (d) => getStyle(d).strokeWidth)
        .attr("fill", (d) => getStyle(d).fill)
        .attr("r", (d) => getStyle(d).radius),
        handleUpdateData(localData);
    };

    const clicked = (event, d) => {
      console.log("clicked");
      if (event.defaultPrevented) return; // dragged
      const point = getPoint(d);

      point
        .transition()
        .attr("fill", "black")
        .attr("r", 10 * 2)
        .transition()
        .attr("r", 10)
        .attr("fill", d3.schemeCategory10[d.index % 10]);
    };

    // Bind data and create circles

    // Filter for dragging only on mouse down, not double-clicks
    const drag = d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);

    // points.raise();
    points.on("click", clicked).call(drag);

    // clickableArea.on("dblclick", handleDoubleClick);
  };
