import { Delete } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { NIconButton } from "@ngi/react-component";
import { LocationGroup } from "src/types/locationGroup";
import { TableCustomCell } from "src/types/table";

export const LocationGroupActionCell = ({
  row,
  table,
}: TableCustomCell<
  LocationGroup,
  LocationGroup["location_group_id"]
>) => {
  const meta = table.options.meta;

  return (
    <Box className="flex gap-2 items-center justify-start">
      <Tooltip title="Delete location group" placement="right-end">
        <NIconButton
          color="error"
          onClick={() => {
            meta?.removeRow(row.index);
          }}
        >
          <Delete />
        </NIconButton>
      </Tooltip>
    </Box>
  );
};
