export function findMinMaxForKeys<T>(
  data: T[],
  keys: Array<keyof T>,
): [number, number] {
  let min = Number.MAX_VALUE;
  let max = -Number.MAX_VALUE;

  data.forEach((item) => {
    keys.forEach((key) => {
      if (item[key] && item[key] < min) min = item[key];
      if (item[key] && item[key] > max) max = item[key];
    });
  });

  return [min, max];
}
