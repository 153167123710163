import { ContentPaste, CopyAll, Troubleshoot } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { NIconButton } from "@ngi/react-component";
import { LocationGroup } from "src/types/locationGroup";
import { TableCustomCell } from "src/types/table";

export const LocationGroupCPTPreviewCellFactory = (
  handleLocationGroupPreview: (
    location_group_id: string,
    triggerOpendialog: boolean,
  ) => void,
  handleLocationGroupForCopy: (location_group_id: string) => void,
  copyMode: "copy" | "paste" | "pending",
) => {
  const Component = ({
    getValue,
  }: TableCustomCell<LocationGroup, LocationGroup["location_group_id"]>) => {
    const location_group_id = getValue() || "";

    return (
      <div className="grid grid-cols-2 gap-4">
        <Tooltip title="Preview CPT" placement="right-end">
          <NIconButton
            onClick={() => {
              handleLocationGroupPreview(location_group_id, true);
            }}
          >
            <Troubleshoot />
          </NIconButton>
        </Tooltip>
        {copyMode === "pending" ? (
          <Tooltip title="Copy layers" placement="right-end">
            <NIconButton
              onClick={() => {
                handleLocationGroupForCopy(location_group_id);
              }}
            >
              <CopyAll />
            </NIconButton>
          </Tooltip>
        ) : copyMode === "paste" ? (
          <Tooltip title="paste layers" placement="right-end">
            <NIconButton
              onClick={() => {
                handleLocationGroupForCopy(location_group_id);
              }}
            >
              <ContentPaste />
            </NIconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  Component.displayName = "LocationGroupCPTPreviewCell";
  return Component;
};
