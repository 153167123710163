import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AlignHorizontalRight,
  ExpandMore,
  Fullscreen,
  FullscreenExit,
  Help,
  Layers,
  Settings,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import * as d3 from "d3";

import { NButton, NDialog, NInlineAlert } from "@ngi/react-component";
import { FormattedData } from "src/components/common/plots/elements/editableScatter";
import { Legend } from "src/components/common/plots/elements/Legend";
import { PlotSettings } from "src/components/common/plots/PlotSetting";
import { HORIZON_COLOR } from "src/definitions/constants";
import { PROCESSED_CPT_PLOTS } from "src/definitions/plotConstants";
import { updateRevisionLocationGroupMutationQuery } from "src/queries/mutations";
import {
  getCPTQuery,
  getRevisionLocationGroupProcessedCPTsQuery,
  getRevisionSoilUnitQuery,
} from "src/queries/queries";
import { LayerTypeSchema, SoilLayerType } from "src/schemas/soilLayerSchema";
import { DataPoint } from "src/types/data";
import { LocationGroup, LocationGroupNote } from "src/types/locationGroup";
import { LocationNameMapping } from "src/types/locations";
import { PlotLegendItem } from "src/types/plotElements";
import { GeoPlot } from "src/types/plots";
import { SaveStatus } from "src/types/status";
import { EditableLayerDialogCloseConfirm } from "./EditableLayerDialogCloseConfirm";
import { EditableLayeringCptPlot } from "./EditableLayerPlot";
import { EditableLayerPlotHelp } from "./EditableLayerPlotHelp";
import { EditableLayerTable } from "./EditableLayerTable";
import { HorizonTable } from "./HorizonTable";
import { SaveSnackbar } from "./SaveNotification";
import {
  assignSoilUnitIdToSubLayers,
  reconcileNewSoilLayersFromSoilLayers,
} from "./soil-layering-utils";

type Props = {
  project_id: string;
  locationGroup: LocationGroup;
  locationGroups: LocationGroup[];
  locationNameMapping: LocationNameMapping;
  open: boolean;
  setOpen: (b: boolean) => void;
  setLocationGroup: (locationGroup: LocationGroup) => void;
};

export const EditableLayerDialog = ({
  project_id,
  locationGroup,
  locationGroups,
  locationNameMapping,
  open,
  setOpen,
  setLocationGroup,
}: Props) => {
  const [plotContainerOrder, setPlotContainerOrder] = useState<number>(1);

  const containerOrder = useMemo(() => {
    const isPlotFirst = plotContainerOrder === 1;
    return {
      plotContainer: isPlotFirst ? "order-1" : "order-2",
      tableContainer: isPlotFirst ? "order-2" : "order-1",
    };
  }, [plotContainerOrder]);

  const hasHorizons = useMemo(
    () => locationGroup.notes?.some((note) => note.note_type === "HORIZON"),
    [locationGroup.notes],
  );

  const [openPlotSettings, setOpenPlotSettings] = useState<boolean>(false);
  const [openPlotHelp, setOpenPlotHelp] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [shouldRecompute, setShouldRecompute] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const [showLayers, setshowLayers] = useState(true);
  const [showHorizons, setShowHorizons] = useState(true);
  const [dataSavedStatus, setDataSavedStatus] = useState<SaveStatus>("IDLE");
  const [confirmCloseUnsavedChanges, setConfirmCloseUnsavedChanges] =
    useState(false);

  const zoomDispatch = useRef(d3.dispatch("zoom")).current;
  const defaultSelectedPlots = PROCESSED_CPT_PLOTS.filter(
    (plot) => plot.default,
  );
  const [selectedPlots, setSelectedPlots] = useState<GeoPlot[]>(
    PROCESSED_CPT_PLOTS.filter((plot) => plot.default),
  );
  const [numPlotPerRow, setNumPlotPerRow] = useState<number>(
    defaultSelectedPlots.length,
  );
  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const plotSettingsHasChanged = useMemo(
    () =>
      defaultSelectedPlots.length !== selectedPlots.length ||
      defaultSelectedPlots.some((plot, i) => plot.id !== selectedPlots[i].id) ||
      numPlotPerRow !== defaultSelectedPlots.length,
    [defaultSelectedPlots, numPlotPerRow, selectedPlots],
  );

  const resetPlotSettings = useCallback(() => {
    setSelectedPlots(defaultSelectedPlots);
    setNumPlotPerRow(defaultSelectedPlots.length);
  }, [defaultSelectedPlots]);

  const currentIndex = useMemo(
    () =>
      locationGroups.findIndex(
        (group) => group.location_group_id === locationGroup.location_group_id,
      ),
    [locationGroups, locationGroup.location_group_id],
  );

  const prevLocationGroup = useMemo(
    () => locationGroups[currentIndex - 1] || null,
    [locationGroups, currentIndex],
  );

  const nextLocationGroup = useMemo(
    () => locationGroups[currentIndex + 1] || null,
    [locationGroups, currentIndex],
  );

  const { data: processedCpts, refetch } = useQuery(
    getRevisionLocationGroupProcessedCPTsQuery(
      locationGroup.revision_id,
      locationGroup.location_group_id,
      { layers: locationGroup.layers },
    ),
  );

  const updateLocationGroupMutation = useMutation(
    updateRevisionLocationGroupMutationQuery(
      locationGroup.revision_id,
      locationGroup.location_group_id,
      setDataSavedStatus,
    ),
  );

  const flattenedCptData = useMemo(() => {
    if (!processedCpts) return [];

    return processedCpts.data.reduce((prev: DataPoint[], cur) => {
      prev.push(...cur.data);
      return prev;
    }, [] as DataPoint[]);
  }, [processedCpts]);

  const max_depth = useMemo(
    () => Math.max(...flattenedCptData.map((d) => d.depth)),
    [flattenedCptData],
  );

  // Prefetch the previous and next locationGroup CPTs
  useQueries({
    queries: prevLocationGroup
      ? prevLocationGroup.location_ids.map((siite_location_id) =>
          getCPTQuery(project_id, siite_location_id),
        )
      : [],
  });
  useQueries({
    queries: nextLocationGroup
      ? nextLocationGroup.location_ids.map((siite_location_id) =>
          getCPTQuery(project_id, siite_location_id),
        )
      : [],
  });

  const { data: soilUnits } = useQuery(
    getRevisionSoilUnitQuery(locationGroup.revision_id),
  );

  const handleGoToGroup = useCallback(
    (locationGroup: LocationGroup) => {
      setLocationGroup(locationGroup);
    },
    [setLocationGroup],
  );

  useEffect(() => {
    if (shouldRecompute) {
      console.log("Recomputing cpt process...");
      refetch();
      setShouldRecompute(false);
    }
  }, [refetch, shouldRecompute]);

  const handleSaveLocationGroup = useCallback(() => {
    if (dataHasChanged) {
      setDataHasChanged(false);
      updateLocationGroupMutation.mutate({
        location_group_id: locationGroup.location_group_id,
        payload: locationGroup,
      });
      setDataSaved(true);
    }
  }, [dataHasChanged, locationGroup, updateLocationGroupMutation]);

  const handleOnClose = useCallback(() => {
    if (dataHasChanged) {
      setConfirmCloseUnsavedChanges(true);
    } else {
      setOpen(false);
    }
  }, [dataHasChanged, setOpen]);

  const handleOnSaveAndClose = useCallback(() => {
    if (dataHasChanged) {
      handleSaveLocationGroup();
      setConfirmCloseUnsavedChanges(true);
      setOpen(false);
    }
  }, [dataHasChanged, handleSaveLocationGroup, setOpen]);

  const handleOnNotSaveAndClose = useCallback(() => {
    if (dataHasChanged) {
      setConfirmCloseUnsavedChanges(true);
      setOpen(false);
    }
  }, [dataHasChanged, setOpen]);

  const handleSetToMaxDepth = useCallback(
    (depth: number) => {
      setLocationGroup({
        ...locationGroup,
        max_depth: depth,
      });
      setDataHasChanged(true);
      setShouldRecompute(true);
    },
    [locationGroup, setLocationGroup],
  );

  const actions = useMemo(
    () => (
      <>
        <NButton
          onClick={() => handleSaveLocationGroup()}
          variant="text"
          disabled={!dataHasChanged}
        >
          Save changes
        </NButton>
        <NButton
          onClick={() => handleGoToGroup(prevLocationGroup)}
          variant="text"
          disabled={!prevLocationGroup || dataHasChanged}
        >
          Previous group
        </NButton>
        <NButton
          onClick={() => handleGoToGroup(nextLocationGroup)}
          variant="text"
          disabled={!nextLocationGroup || dataHasChanged}
        >
          Next group
        </NButton>
        <NButton onClick={handleOnClose} variant="text">
          Close
        </NButton>
      </>
    ),
    [
      dataHasChanged,
      handleGoToGroup,
      handleOnClose,
      handleSaveLocationGroup,
      nextLocationGroup,
      prevLocationGroup,
    ],
  );

  const handleUpdateDataFromPlot = useCallback(
    (newData: SoilLayerType[]) => {
      const sortedNewData = newData.sort((a, b) => a.depth - b.depth);

      const newLayers = reconcileNewSoilLayersFromSoilLayers(
        sortedNewData,
        locationGroup.layers,
      );

      // prevent modifying the first depth
      newLayers[0].depth = 0;

      setLocationGroup({ ...locationGroup, layers: newLayers });
      setDataHasChanged(true);
      setShouldRecompute(true);
    },
    [locationGroup, setLocationGroup],
  );

  const handleUpdateDataFromTable = useCallback(
    (updateFn: (layers: SoilLayerType[]) => SoilLayerType[]) => {
      let newLayers = updateFn(locationGroup.layers);
      newLayers = assignSoilUnitIdToSubLayers(newLayers);
      setLocationGroup({ ...locationGroup, layers: newLayers });
      setDataHasChanged(true);
      setShouldRecompute(true);
    },
    [locationGroup, setLocationGroup],
  );

  const handleUpdateDataFromRightClickMenu = useCallback(
    (newData: SoilLayerType) => {
      let newLayers = locationGroup.layers.map((layer) => {
        if (layer.depth === newData.depth) {
          return newData;
        }
        return layer;
      });
      newLayers = assignSoilUnitIdToSubLayers(newLayers);
      setLocationGroup({ ...locationGroup, layers: newLayers });
      setDataHasChanged(true);
      setShouldRecompute(true);
    },
    [locationGroup, setLocationGroup],
  );
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const [rightClickTarget, setRightClickTarget] =
    useState<FormattedData<SoilLayerType> | null>(null);

  const handleRightClick = (
    event: MouseEvent,
    dataPoint: FormattedData<SoilLayerType>,
  ) => {
    setAnchorPosition({ top: event.clientY, left: event.clientX });
    setRightClickTarget(dataPoint);
  };

  const rightClickMenu = useMemo(() => {
    if (!soilUnits) return null;

    const options = soilUnits.map((soilUnit) => ({
      value: soilUnit.soil_unit_id,
      label: soilUnit.name,
    }));
    const setLayerType = (layerType: string) => {
      if (!rightClickTarget) return;
      const updatedLayer = {
        ...rightClickTarget.data,
        layer_type: layerType,
      } as SoilLayerType;
      setRightClickTarget({ ...rightClickTarget, data: updatedLayer });
      handleUpdateDataFromRightClickMenu(updatedLayer);
      setAnchorPosition(null);
    };

    const setSoilUnit = (soil_unit_id: string) => {
      if (!rightClickTarget) return;
      const updatedLayer = { ...rightClickTarget.data, soil_unit_id };
      setRightClickTarget({ ...rightClickTarget, data: updatedLayer });
      handleUpdateDataFromRightClickMenu(updatedLayer);
      setAnchorPosition(null);
    };

    return (
      <Menu
        open={Boolean(anchorPosition)}
        onClose={() => setAnchorPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition
            ? { top: anchorPosition.top, left: anchorPosition.left }
            : undefined
        }
      >
        <div className="w-64 p-4 flex flex-col gap-4">
          <Typography variant="h6">Adjust current point</Typography>
          <Typography variant="caption">Layer type</Typography>
          <Select
            value={rightClickTarget?.data.layer_type}
            onChange={(e) => {
              setLayerType(e.target.value);
            }}
          >
            <MenuItem value={LayerTypeSchema.Values.LAYER}>Layer</MenuItem>
            <MenuItem value={LayerTypeSchema.Values.SUBLAYER}>
              Sublayer
            </MenuItem>
          </Select>
          <Typography variant="caption">Soil unit</Typography>
          <Select
            value={rightClickTarget?.data.soil_unit_id}
            onChange={(e) => {
              setSoilUnit(e.target.value);
            }}
            disabled={
              rightClickTarget?.data.layer_type ===
              LayerTypeSchema.Values.SUBLAYER
            }
          >
            {options.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <NButton variant="text" onClick={() => setAnchorPosition(null)}>
            Close
          </NButton>
        </div>
      </Menu>
    );
  }, [
    anchorPosition,
    handleUpdateDataFromRightClickMenu,
    rightClickTarget,
    soilUnits,
  ]);

  const legendItems: PlotLegendItem[] = useMemo(() => {
    const items = locationGroup.location_ids.map((location_id, i) => ({
      id: location_id,
      label: locationNameMapping[location_id],
      lineColor: d3.schemeCategory10[i % 10],
      lineWidth: 2,
      lineStrokeStyle: "solid",
    }));
    if (hasHorizons) {
      items.push({
        id: "horizons",
        label: "Horizons",
        lineColor: HORIZON_COLOR,
        lineWidth: 2,
        lineStrokeStyle: "solid",
      });
    }
    return items;
  }, [hasHorizons, locationGroup.location_ids, locationNameMapping]);

  return (
    <NDialog
      dialogTitle={`Location group ${locationGroup.name} - Layering`}
      fullScreen
      onClose={handleOnClose}
      disableEscapeKeyDown
      open={open}
      actions={actions}
    >
      <div className="h-full">
        {!processedCpts ? (
          <NInlineAlert severity="loading">
            Loading the CPT data...
          </NInlineAlert>
        ) : processedCpts.data.length === 0 ? (
          <NInlineAlert severity="warning">No CPT data to display</NInlineAlert>
        ) : (
          <div className="EditableLayerDialogContent min-h-full grid gap-4 grid-cols-1 wider:grid-cols-6 grid-flow-row-dense">
            <div
              className={`LayerPLots bg-white shadow px-2 rounded min-h-[900px] h-full grow flex wider:order-2 wider:col-span-4 ${
                isFullscreen
                  ? "!fixed top-0 left-0 right-0 bottom-0 z-[9999]"
                  : "h-full"
              } ${containerOrder.plotContainer}`}
              style={{
                maxHeight: isFullscreen
                  ? "100vh"
                  : "calc(100vh - 64px - 120px)",
              }}
            >
              <div className="grow h-full flex flex-col">
                <div className="px-2 flex justify-end">
                  <Tooltip title="Show/Hide layers">
                    <IconButton
                      color={showLayers ? "primary" : "default"}
                      onClick={() => setshowLayers(!showLayers)}
                    >
                      <Layers />
                    </IconButton>
                  </Tooltip>
                  {hasHorizons && (
                    <Tooltip title="Show/Hide horizons">
                      <IconButton
                        color={showHorizons ? "primary" : "default"}
                        onClick={() => setShowHorizons(!showHorizons)}
                      >
                        <AlignHorizontalRight />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Plot settings">
                    <IconButton
                      color="primary"
                      onClick={() => setOpenPlotSettings(true)}
                    >
                      <Settings />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Plot help">
                    <IconButton
                      color="primary"
                      onClick={() => setOpenPlotHelp(true)}
                    >
                      <Help />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Toggle fullscreen">
                    <IconButton color="primary" onClick={toggleFullscreen}>
                      {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </Tooltip>
                </div>

                <div
                  className={`grow grid grid-cols-${numPlotPerRow} auto-rows-fr`}
                >
                  {selectedPlots.map((plot) => (
                    <EditableLayeringCptPlot
                      key={plot.id}
                      location_group={locationGroup}
                      data={processedCpts.data}
                      flattenedData={flattenedCptData}
                      updateLayers={handleUpdateDataFromPlot}
                      soilUnits={soilUnits}
                      showHorizons={showHorizons}
                      showLayers={showLayers}
                      plot={plot}
                      xKeys={plot.series.x}
                      yKey={plot.series.y}
                      zoomDispatch={zoomDispatch}
                      onRightClick={handleRightClick}
                    />
                  ))}
                </div>
              </div>
              {rightClickMenu}
            </div>
            <div
              className={`${containerOrder.tableContainer} grid grid-cols-1 gap-4 wider:order-1 wider:flex wider:flex-col wider:col-span-2`}
            >
              <div className="EditableLayerInfo grid grid-cols-1 xl:grid-cols-2 wider:grid-cols-1 wider:col-span-2 gap-4 w-full">
                <Paper className="flex gap-4 justify-start items-center px-2 py-4">
                  <Typography variant="h6">Legend:</Typography>
                  <Legend
                    items={legendItems}
                    classname="flex flex-row flex-wrap gap-4"
                  />
                </Paper>
                <Paper className="p-2 flex gap-4 items-center">
                  <TextField
                    label="Max depth"
                    value={locationGroup.max_depth}
                    onChange={(e) =>
                      handleSetToMaxDepth(parseFloat(e.target.value))
                    }
                  />
                  <div className="flex items-center">
                    <NButton
                      variant="text"
                      onClick={() => handleSetToMaxDepth(max_depth)}
                    >
                      Set to max CPT depth
                    </NButton>
                    <Typography variant="caption">
                      ({max_depth.toFixed(2)} m)
                    </Typography>
                  </div>
                </Paper>
              </div>
              <div className="EditableLayerTables grid gap-4 w-full">
                <Accordion defaultExpanded className="!my-0">
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="soil-layering-content"
                    id="soil-layering-header"
                    className="!px-2"
                  >
                    <Typography variant="h6">Soil layering</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="!px-0">
                    <div>
                      <EditableLayerTable
                        layers={locationGroup.layers}
                        updateLayers={handleUpdateDataFromTable}
                        soilUnits={soilUnits || []}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {hasHorizons && (
                  <Accordion className="!mt-0 !mb-4">
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="horizons-content"
                      id="horizons-header"
                      className="!px-2"
                    >
                      <Typography variant="h6">Geophysical horizons</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="!px-0">
                      <div>
                        <HorizonTable
                          notes={locationGroup.notes as LocationGroupNote[]}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <SaveSnackbar
        open={dataSaved}
        setOpen={setDataSaved}
        status={dataSavedStatus}
      />
      {confirmCloseUnsavedChanges && (
        <EditableLayerDialogCloseConfirm
          open={confirmCloseUnsavedChanges}
          onCancel={() => setConfirmCloseUnsavedChanges(false)}
          onSaveAndClose={handleOnSaveAndClose}
          onNotSaveAndClose={handleOnNotSaveAndClose}
        />
      )}
      {openPlotSettings && (
        <NDialog
          dialogTitle="Plot settings"
          onClose={() => setOpenPlotSettings(false)}
          open={open}
          maxWidth="md"
          actions={
            <>
              <NButton
                variant="text"
                disabled={!plotSettingsHasChanged}
                onClick={() => resetPlotSettings()}
              >
                Reset to default settings
              </NButton>
              <NButton
                variant="text"
                onClick={() => setOpenPlotSettings(false)}
              >
                Close
              </NButton>
            </>
          }
        >
          <PlotSettings
            numPlotPerRow={numPlotPerRow}
            setNumPlotPerRow={setNumPlotPerRow}
            selectedPlots={selectedPlots}
            setSelectedPlots={setSelectedPlots}
            plots={PROCESSED_CPT_PLOTS}
          />
        </NDialog>
      )}
      {openPlotHelp && (
        <EditableLayerPlotHelp open={openPlotHelp} setOpen={setOpenPlotHelp} />
      )}
    </NDialog>
  );
};
