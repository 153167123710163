import { Box } from "@mui/material";

import { Project } from "src/types/api";
import { ProjectListItem } from "./ProjectListItem";

import "./ProjectList.scss";

type Props = {
  projects: Project[];
};

export const ProjectList = ({ projects }: Props) => (
  <Box className="ProjectList pl-2">
    {projects &&
      projects.map((project) => (
        <ProjectListItem key={project.project_id} project={project} />
      ))}
  </Box>
);
