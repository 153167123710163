import { Box } from "@mui/material";
import { QueryClient, useQuery } from "@tanstack/react-query";
import {
  createRootRouteWithContext,
  Outlet,
  redirect,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";

import { AppHeader } from "src/components/app/AppHeader";
import {
  getAppConfig,
  getAuthSessionQuery,
  getProjectQuery,
} from "src/queries/queries";

interface RouterContext {
  auth: boolean | undefined;
  queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RouterComponent,
  beforeLoad: async (opts) => {
    await opts.context.queryClient.ensureQueryData(getAuthSessionQuery);

    if ("project_id" in opts.params) {
      const project = await opts.context.queryClient.ensureQueryData(
        getProjectQuery(opts.params.project_id as string),
      );
      if (!project || !project.in_siite) {
        throw redirect({ to: "/" });
      }
    }
  },
});

function RouterComponent() {
  const { data: config } = useQuery(getAppConfig);
  return (
    <Box>
      <AppHeader />
      <Outlet />
      {config && config.env === "DEV" && <TanStackRouterDevtools />}
    </Box>
  );
}
