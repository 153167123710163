import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { NButton, NDialog } from "@ngi/react-component";
import { addProjectMutationQuery } from "src/queries/mutations";
import { Project } from "src/types/api";
import { AddProjectTable } from "./AddProjectTable";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  projectsNotInSiite: Project[];
};

export const AddProjectDialog = ({
  open,
  setOpen,
  projectsNotInSiite,
}: Props) => {
  const [selectedProjectsToAdd, setSelectedProjectsToAdd] = useState<string[]>(
    [],
  );

  const addProjectMutation = useMutation(addProjectMutationQuery());

  const isValid = useMemo(
    () => selectedProjectsToAdd.length > 0,
    [selectedProjectsToAdd.length],
  );

  const handleAddProjects = useCallback(() => {
    for (const project_id of selectedProjectsToAdd) {
      addProjectMutation.mutate({ project_id });
    }
    setSelectedProjectsToAdd([]);
    setOpen(false);
  }, [addProjectMutation, selectedProjectsToAdd, setOpen]);

  const text = useMemo(() => {
    if (selectedProjectsToAdd.length === 0) return "No project selected";
    const endText = selectedProjectsToAdd.length > 1 ? "s" : "";
    return `Add ${selectedProjectsToAdd.length} project${endText}`;
  }, [selectedProjectsToAdd]);

  const actions = useMemo(() => {
    const disabled = !isValid;
    return (
      <>
        <NButton onClick={handleAddProjects} disabled={disabled}>
          {text}
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    );
  }, [handleAddProjects, isValid, setOpen, text]);

  return (
    <NDialog
      dialogTitle="Add project from FieldManager"
      onClose={() => setOpen(false)}
      open={open}
      actions={actions}
      fullWidth
      maxWidth="lg"
    >
      <Box className="min-h-[400px]">
        <AddProjectTable
          projects={projectsNotInSiite}
          selectedProjectsToAdd={selectedProjectsToAdd}
          setSelectedProjectsToAdd={setSelectedProjectsToAdd}
        />
      </Box>
    </NDialog>
  );
};
