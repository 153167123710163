import * as React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";

import { LocationGroup } from "src/types/locationGroup";
import { Location, LocationNameMapping } from "src/types/locations";
import { TableCustomCell } from "src/types/table";

export const EditableLocationNameCellFactory = (
  locationNameMapping: LocationNameMapping,
  locationWithoutGroup: Location[],
) => {
  const Component = ({
    getValue,
    row,
    column,
    table,
  }: TableCustomCell<LocationGroup, LocationGroup["location_ids"]>) => {
    const updateData = table.options.meta?.updateData;
    const location_ids = getValue() || [];

    const locationNames = location_ids.map(
      (id) => locationNameMapping[id] || id,
    );

    const handleOnChange = (_: React.SyntheticEvent, value: string[]) => {
      if (updateData) {
        updateData(row.index, column.id, value);
      }
    };

    const options = [
      ...location_ids,
      ...locationWithoutGroup.map((location) => location.siite_location_id),
    ];

    return (
      <Box className="flex gap-4">
        {locationNames && (
          <Autocomplete
            multiple
            id="locations"
            options={options}
            getOptionLabel={(location_id: string) =>
              locationNameMapping[location_id]
            }
            disableCloseOnSelect
            defaultValue={location_ids}
            onChange={handleOnChange}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select locations"
              />
            )}
          />
        )}
      </Box>
    );
  };

  Component.displayName = "EditableLocationNameCell";
  return Component;
};
