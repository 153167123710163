import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { getAuthSessionQuery } from "src/queries/queries";

type Props = {
  navigation?: React.ReactNode;
  children: React.ReactNode;
};

export const AppContent = ({ children, navigation }: Props) => {
  useQuery(getAuthSessionQuery);

  return (
    <Box sx={{ flexGrow: 1, mt: "8px" }}>
      {navigation && navigation}
      <Box sx={{ p: 3 }}>{children}</Box>
    </Box>
  );
};
