import {
  createRevisionLocations,
  CreateRevisionLocationsProps,
} from "src/api/location";
import {
  batchCreateLocationGroups,
  BatchCreateLocationsGroupProps,
  batchUpdateLocationGroups,
  BatchUpdateLocationsGroupProps,
  createLocationGroup,
  CreateLocationsGroupProps,
  deleteLocationGroup,
  DeleteLocationGroupProps,
  updateLocationGroup,
  UpdateLocationsGroupProps,
} from "src/api/locationGroup";
import {
  addProject,
  AddProjectProps,
  deleteProject,
  DeleteProjectProps,
} from "src/api/project";
import {
  createProjectPhase,
  CreateProjectPhaseProps,
  deleteProjectPhase,
  DeletProjectPhaseProps,
  updateProjectPhase,
  UpdateProjectPhaseProps,
} from "src/api/projectPhase";
import {
  createProjectPhaseRevision,
  CreateProjectPhaseRevisionProps,
  deleteProjectPhaseRevision,
  DeleteProjectPhaseRevisionProps,
  duplicateProjectPhaseRevision,
  DuplicateProjectPhaseRevisionProps,
  updateProjectPhaseRevision,
  UpdateProjectPhaseRevisionProps,
} from "src/api/projectPhaseRevision";
import {
  batchCreateSoilUnit,
  BatchCreateSoilUnitProps,
  createSoilUnit,
  CreateSoilUnitProps,
  deleteSoilUnit,
  DeleteSoilUnitProps,
  setDefaultSoilUnit,
  SetDefaultSoilUnitProps,
  updateSoilUnit,
  UpdateSoilUnitProps,
} from "src/api/soilUnit";
import { queryClient } from "src/queryClient";
import { SaveStatus } from "src/types/status";
import { mutationOptions } from "src/utils/mutations";
import { keys } from "./keys";

export const addProjectMutationQuery = () =>
  mutationOptions<AddProjectProps>({
    mutationFn: ({ project_id }) => addProject({ project_id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.allProjects });
    },
  });

export const deleteProjectMutationQuery = () =>
  mutationOptions<DeleteProjectProps>({
    mutationFn: ({ project_id }) => deleteProject({ project_id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.allProjects });
    },
  });

export const addProjectPhaseMutationQuery = (project_id: string) =>
  mutationOptions<CreateProjectPhaseProps>({
    mutationFn: (payload) => createProjectPhase(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhases(project_id),
      }),
  });

export const updateProjectPhaseMutationQuery = (project_id: string) =>
  mutationOptions<UpdateProjectPhaseProps>({
    mutationFn: (payload) => updateProjectPhase(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhases(project_id),
      }),
  });

export const deleteProjectPhaseMutationQuery = (project_id: string) =>
  mutationOptions<DeletProjectPhaseProps>({
    mutationFn: ({ phase_id }) => deleteProjectPhase({ phase_id }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhases(project_id),
      }),
  });

export const addRevisionLocationsMutationQuery = (
  project_id: string,
  phase_id: string,
  revision_id: string,
) =>
  mutationOptions<CreateRevisionLocationsProps>({
    mutationFn: (payload) => createRevisionLocations(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhaseRevision(project_id, phase_id, revision_id),
      }),
  });

export const addProjectPhaseRevisionMutationQuery = (
  project_id: string,
  phase_id: string,
) =>
  mutationOptions<CreateProjectPhaseRevisionProps>({
    mutationFn: (payload) => createProjectPhaseRevision(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhaseRevisions(project_id, phase_id),
      }),
  });

export const duplicateProjectPhaseRevisionMutationQuery = (
  project_id: string,
  phase_id: string,
) =>
  mutationOptions<DuplicateProjectPhaseRevisionProps>({
    mutationFn: (payload) => duplicateProjectPhaseRevision(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhaseRevisions(project_id, phase_id),
      }),
  });

export const updateProjectPhaseRevisionMutationQuery = (
  project_id: string,
  phase_id: string,
) =>
  mutationOptions<UpdateProjectPhaseRevisionProps>({
    mutationFn: (payload) => updateProjectPhaseRevision(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhaseRevisions(project_id, phase_id),
      }),
  });

export const deleteProjectPhaseRevisionMutationQuery = (
  project_id: string,
  phase_id: string,
) =>
  mutationOptions<DeleteProjectPhaseRevisionProps>({
    mutationFn: (payload) => deleteProjectPhaseRevision(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.projectPhaseRevisions(project_id, phase_id),
      }),
  });

export const addRevisionLocationGroupMutationQuery = (revision_id: string) =>
  mutationOptions<CreateLocationsGroupProps>({
    mutationFn: (payload) => createLocationGroup(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionLocationGroups(revision_id),
      }),
  });

export const updateRevisionLocationGroupMutationQuery = (
  revision_id: string,
  location_group_id: string,
  triggerNotification: (status: SaveStatus) => void,
) =>
  mutationOptions<UpdateLocationsGroupProps>({
    mutationFn: (payload) => updateLocationGroup(payload),
    onSuccess: () => {
      triggerNotification("SAVED");
      queryClient.invalidateQueries({
        queryKey: keys.revisionLocationGroups(revision_id),
      });
      queryClient.invalidateQueries({
        queryKey: keys.revisionLocationGroupProcessedCPTs(
          revision_id,
          location_group_id,
        ),
      });
    },
    onError: () => {
      triggerNotification("ERROR");
    },
  });

export const deleteRevisionLocationGroupMutationQuery = (revision_id: string) =>
  mutationOptions<DeleteLocationGroupProps>({
    mutationFn: (payload) => deleteLocationGroup(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionLocationGroups(revision_id),
      }),
  });

export const batchAddRevisionLocationGroupMutationQuery = (
  revision_id: string,
) =>
  mutationOptions<BatchCreateLocationsGroupProps>({
    mutationFn: (payload) => batchCreateLocationGroups(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionLocationGroups(revision_id),
      }),
  });

export const batchUpdateRevisionLocationGroupMutationQuery = (
  revision_id: string,
) =>
  mutationOptions<BatchUpdateLocationsGroupProps>({
    mutationFn: (payload) => batchUpdateLocationGroups(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionLocationGroups(revision_id),
      }),
  });

export const addRevisionSoilUnitMutationQuery = (revision_id: string) =>
  mutationOptions<CreateSoilUnitProps>({
    mutationFn: (payload) => createSoilUnit(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionSoilUnits(revision_id),
      }),
  });

export const batchCreateRevisionSoilUnitMutationQuery = (revision_id: string) =>
  mutationOptions<BatchCreateSoilUnitProps>({
    mutationFn: (payload) => batchCreateSoilUnit(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionSoilUnits(revision_id),
      }),
  });

export const updateRevisionSoilUnitMutationQuery = (revision_id: string) =>
  mutationOptions<UpdateSoilUnitProps>({
    mutationFn: (payload) => updateSoilUnit(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionSoilUnits(revision_id),
      }),
  });

export const setDefaultRevisionSoilUnitMutationQuery = (revision_id: string) =>
  mutationOptions<SetDefaultSoilUnitProps>({
    mutationFn: (payload) => setDefaultSoilUnit(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionSoilUnits(revision_id),
      }),
  });
export const deleteRevisionSoilUnitMutationQuery = (revision_id: string) =>
  mutationOptions<DeleteSoilUnitProps>({
    mutationFn: (payload) => deleteSoilUnit(payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: keys.revisionSoilUnits(revision_id),
      }),
  });
