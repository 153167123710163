import axios from "axios";

import { FieldManagerLocation, Location } from "src/types/locations";
import { setURL } from "src/utils/api";

type GetRevisionLocationsProps = {
  revision_id: string;
};

export const getRevisionLocations = async ({
  revision_id,
}: GetRevisionLocationsProps): Promise<Location[]> => {
  const url = setURL(`revision/${revision_id}/locations`);
  const response = await axios.get(url);
  return response.data;
};

export type CreateRevisionLocationsProps = {
  revision_id: string;
  locations: FieldManagerLocation[];
};
export const createRevisionLocations = async ({
  revision_id,
  locations,
}: CreateRevisionLocationsProps) => {
  const url = setURL(`revision/${revision_id}/locations`);
  await axios.post(url, locations);
};

export const queryMapLocations = async ({
  revision_id,
  longMin,
  latMin,
  longMax,
  latMax,
}: {
  revision_id: string;
  longMin: number;
  latMin: number;
  longMax: number;
  latMax: number;
}): Promise<
  {
    cluster_id: number;
    num_points: number;
    location_names: string[];
    centroid: { long: number; lat: number };
  }[]
> => {
  const url = setURL(
    `revision/${revision_id}/locations/map?long_min=${longMin}&lat_min=${latMin}&long_max=${longMax}&lat_max=${latMax}`,
  );
  const response = await axios.get(url);
  return response.data;
};
