export enum PasteOption {
  COPY_LAYERS = "copy layers",
  COPY_MAX_DEPTH = "copy max depth",
  COPY_LAYERS_AND_MAX_DEPTH = "copy layers and max depth",
}

// Enum for clip options when copying layers
export enum ClipOption {
  CLIP_LAYERS_TO_FIT_MAX_DEPTH = "clip layers to fit max depth",
  ADJUST_MAX_DEPTH_TO_FIT_LAYERS = "adjust max depth to fit layers",
}
