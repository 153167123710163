import { useCallback, useMemo, useState } from "react";
import {
  AlignHorizontalRight,
  Description,
  LineAxis,
  LocationOn,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import {
  createColumnHelper,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";

import { NButton, NCard, NCardContent } from "@ngi/react-component";
import { Datatable } from "src/components/common/datatable/Datatable";
import { rowSelectionColumn } from "src/components/common/datatable/features/rowSelection";
import { batchUpdateRevisionLocationGroupMutationQuery } from "src/queries/mutations";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { LocationGroup } from "src/types/locationGroup";
import { LocationNameMapping } from "src/types/locations";
import { sortData, SortOption } from "src/utils/arrays";
import { EditableLayerDialog } from "./EditableLayerDialog";
import { ClipOption, PasteOption } from "./soil-layering-definitions";
import { handleCopyPasteLayersAndMaxDepth } from "./soil-layering-utils";
import { LayerBarChartCellFactory } from "./tableFeatures/LayerBarChartCell";
import { LocationGroupCPTPreviewCellFactory } from "./tableFeatures/LocationGroupCPTPreviewCell";
import { LocationGroupStatusCell } from "./tableFeatures/LocationGroupStatusCell";
import { LocationNameCellFactory } from "./tableFeatures/LocationNameCell";

type Props = {
  project_id: string;
  revision_id: string;
  locationNameMapping: LocationNameMapping;
  locationGroups: LocationGroup[];
  soilUnits: SoilUnitSchemaType[] | undefined;
};

const tableColumnHelper = createColumnHelper<LocationGroup>();

export const LocationGroupWithLayerTable = ({
  project_id,
  revision_id,
  locationNameMapping,
  locationGroups,
  soilUnits,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [pasteOption, setPasteOption] = useState<PasteOption>(
    PasteOption.COPY_LAYERS,
  );
  const [clipOption, setClipOption] = useState<ClipOption>(
    ClipOption.CLIP_LAYERS_TO_FIT_MAX_DEPTH,
  );

  const [showDescriptionColumn, setShowDescriptionColumn] =
    useState<boolean>(false);

  const [showLocationsColumn, setShowLocationsColumn] = useState<boolean>(true);
  const [showHorizons, setShowHorizons] = useState(true);
  const [useGlobalMaxDepth, setUseGlobalMaxDepth] = useState<boolean>(true);

  const [selectedLocationGroup, setSelectedLocationGroup] =
    useState<LocationGroup>(locationGroups[0]);

  const [selectedLocationGroupForCopy, setSelectedLocationGroupForCopy] =
    useState<LocationGroup | null>(null);

  const [selectedLocationGroupIdForPaste, setSelectedLocationGroupIdForPaste] =
    useState<RowSelectionState>({});

  const selectedLocationGroupForPaste = useMemo(() => {
    if (!selectedLocationGroupIdForPaste) return null;
    return Object.entries(selectedLocationGroupIdForPaste)
      .filter((row) => row[1])
      .map((row) => row[0]);
  }, [selectedLocationGroupIdForPaste]);

  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false },
  ]);

  const batchUpdateLocationGroupMutation = useMutation(
    batchUpdateRevisionLocationGroupMutationQuery(revision_id),
  );

  // Memoize sorted location groups based on current sorting state
  const sortedLocationGroups = useMemo(
    () =>
      sortData<LocationGroup>(
        locationGroups,
        sorting as SortOption<LocationGroup>[],
      ).filter(
        (group) =>
          group.location_group_id !==
          selectedLocationGroupForCopy?.location_group_id,
      ),
    [locationGroups, selectedLocationGroupForCopy?.location_group_id, sorting],
  );

  const max_depth_all_group = useMemo(
    () => Math.max(...locationGroups.map((group) => group.max_depth)),
    [locationGroups],
  );

  const handleSelectLocationGroupPreview = useCallback(
    (location_group_id: string, triggerOpendialog: boolean = false) => {
      const locationGroup = locationGroups.find(
        (group) => group.location_group_id === location_group_id,
      );
      if (locationGroup) {
        setSelectedLocationGroup(locationGroup);
        if (triggerOpendialog) {
          setOpen(true);
        }
      }
    },
    [locationGroups],
  );

  const handleSelectLocationGroupForCopy = useCallback(
    (location_group_id: string) => {
      const locationGroup = locationGroups.find(
        (group) => group.location_group_id === location_group_id,
      );
      if (locationGroup) {
        setSelectedLocationGroupForCopy(locationGroup);
      }
    },
    [locationGroups],
  );

  const handleSelectLocationGroupPaste = useCallback(
    (location_group_ids: string[]) => {
      if (!selectedLocationGroupForCopy || location_group_ids.length === 0)
        return;

      const locationGroupsToPaste = locationGroups.filter((group) =>
        location_group_ids.includes(group.location_group_id),
      );

      if (locationGroupsToPaste) {
        const updatedLocationGroups = handleCopyPasteLayersAndMaxDepth(
          selectedLocationGroupForCopy,
          locationGroupsToPaste,
          { pasteOption, clipOption },
        );
        batchUpdateLocationGroupMutation.mutate({
          revision_id,
          payload: { revision_id, groups: updatedLocationGroups },
        });
        setSelectedLocationGroupIdForPaste({});
      }
    },
    [
      batchUpdateLocationGroupMutation,
      clipOption,
      locationGroups,
      pasteOption,
      revision_id,
      selectedLocationGroupForCopy,
    ],
  );

  const handleSingleLocationGroupPaste = useCallback(
    (location_group_id: string) => {
      if (!selectedLocationGroupForCopy) return;
      handleSelectLocationGroupPaste([location_group_id]);
    },
    [handleSelectLocationGroupPaste, selectedLocationGroupForCopy],
  );

  // Memoized columns to avoid re-creation on each render
  const columns = useMemo(
    () => [
      tableColumnHelper.accessor("name", { header: "Name", size: 140 }),
      ...(showDescriptionColumn
        ? [
            tableColumnHelper.accessor("description", {
              header: "Description",
              size: 200,
            }),
          ]
        : []),
      ...(showLocationsColumn
        ? [
            tableColumnHelper.accessor("location_ids", {
              cell: LocationNameCellFactory(locationNameMapping),
              header: "Locations",
              size: 560,
            }),
          ]
        : []),
      tableColumnHelper.accessor("max_depth", {
        header: "Max depth [m]",
        size: 150,
      }),
      tableColumnHelper.accessor("notes", {
        cell: LocationGroupStatusCell,
        header: "Horizons",
        size: 30,
        enableColumnFilter: false,
      }),
      tableColumnHelper.accessor("layers", {
        cell: LayerBarChartCellFactory(
          soilUnits || [],
          useGlobalMaxDepth ? max_depth_all_group : 0,
          showHorizons,
        ),
        header: "Layering preview",
        enableColumnFilter: false,
      }),
    ],
    [
      showDescriptionColumn,
      showLocationsColumn,
      locationNameMapping,
      soilUnits,
      useGlobalMaxDepth,
      max_depth_all_group,
      showHorizons,
    ],
  );

  const copyTableColumns = useMemo(
    () => [
      ...columns.map((column) => ({ ...column, enableColumnFilter: false })),
      tableColumnHelper.accessor("location_group_id", {
        cell: LocationGroupCPTPreviewCellFactory(
          handleSelectLocationGroupPreview,
          handleSelectLocationGroupForCopy,
          "copy",
        ),
        header: "",
        size: 70,
        enableColumnFilter: false,
        enablePinning: true,
        id: "Actions",
      }),
    ],
    [
      columns,
      handleSelectLocationGroupForCopy,
      handleSelectLocationGroupPreview,
    ],
  );

  const targetTableColumns = useMemo(
    () => [
      ...(selectedLocationGroupForCopy ? [rowSelectionColumn()] : []),
      // rowSelectionColumn(),
      ...columns,
      tableColumnHelper.accessor("location_group_id", {
        cell: LocationGroupCPTPreviewCellFactory(
          handleSelectLocationGroupPreview,
          selectedLocationGroupForCopy
            ? handleSingleLocationGroupPaste
            : handleSelectLocationGroupForCopy,
          selectedLocationGroupForCopy ? "paste" : "pending",
        ),
        header: "",
        size: 70,
        enableColumnFilter: false,
        enablePinning: true,
        id: "Actions",
      }),
    ],
    [
      columns,
      handleSelectLocationGroupForCopy,
      handleSelectLocationGroupPreview,
      handleSingleLocationGroupPaste,
      selectedLocationGroupForCopy,
    ],
  );

  const tableSettings = useMemo(
    () => (
      <div className="px-2 pb-2 flex justify-end">
        <Tooltip title="Show/Hide locations">
          <div className="flex justify-center">
            <Switch
              checked={showLocationsColumn}
              onChange={() => setShowLocationsColumn(!showLocationsColumn)}
              inputProps={{ "aria-label": "controlled" }}
            />

            <IconButton
              color="primary"
              className="!pl-0"
              onClick={() => setShowLocationsColumn(!showLocationsColumn)}
            >
              <LocationOn
                color={showLocationsColumn ? "primary" : "disabled"}
              />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title="Show/Hide location_group description">
          <div className="flex justify-center">
            <Switch
              checked={showDescriptionColumn}
              onChange={() => setShowDescriptionColumn(!showDescriptionColumn)}
              inputProps={{ "aria-label": "controlled" }}
            />

            <IconButton
              color="primary"
              className="!pl-0"
              onClick={() => setShowDescriptionColumn(!showDescriptionColumn)}
            >
              <Description
                color={showDescriptionColumn ? "primary" : "disabled"}
              />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title="Use global or local max depth for the preview">
          <div className="flex justify-center">
            <Switch
              checked={useGlobalMaxDepth}
              onChange={() => setUseGlobalMaxDepth(!useGlobalMaxDepth)}
              inputProps={{ "aria-label": "controlled" }}
            />

            <IconButton
              color="primary"
              className="!pl-0"
              onClick={() => setUseGlobalMaxDepth(!useGlobalMaxDepth)}
            >
              <LineAxis color={useGlobalMaxDepth ? "primary" : "disabled"} />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title="Show/hide geophysical horizons">
          <div className="flex justify-center">
            <Switch
              checked={showHorizons}
              onChange={() => setShowHorizons(!showHorizons)}
              inputProps={{ "aria-label": "controlled" }}
            />

            <IconButton
              color="primary"
              className="!pl-0"
              onClick={() => setShowHorizons(!showHorizons)}
            >
              <AlignHorizontalRight
                color={showHorizons ? "primary" : "disabled"}
              />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    ),
    [
      showDescriptionColumn,
      showHorizons,
      showLocationsColumn,
      useGlobalMaxDepth,
    ],
  );

  const form = useMemo(
    () => (
      <NCard>
        <NCardContent>
          <Typography variant="h6" className="pb-4">
            Copy/Paste mode
          </Typography>
          <div className="flex gap-4 content-center px-8 max-w-[1300px] mx-auto">
            <FormControl fullWidth margin="normal">
              <Typography variant="body1">Paste Behavior</Typography>
              <Select
                value={pasteOption}
                onChange={(e) => setPasteOption(e.target.value as PasteOption)}
              >
                <MenuItem value={PasteOption.COPY_LAYERS}>Copy Layers</MenuItem>
                <MenuItem value={PasteOption.COPY_MAX_DEPTH}>
                  Copy Max Depth
                </MenuItem>
                <MenuItem value={PasteOption.COPY_LAYERS_AND_MAX_DEPTH}>
                  Copy Layers and Max Depth
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="" margin="normal">
              <Typography variant="body1">Layer Adjustment Option</Typography>
              <Select
                value={clipOption}
                onChange={(e) => setClipOption(e.target.value as ClipOption)}
                disabled={pasteOption !== PasteOption.COPY_LAYERS}
              >
                <MenuItem value={ClipOption.CLIP_LAYERS_TO_FIT_MAX_DEPTH}>
                  Clip layers to fit max depth
                </MenuItem>
                <MenuItem value={ClipOption.ADJUST_MAX_DEPTH_TO_FIT_LAYERS}>
                  Adjust max depth to fit layers
                </MenuItem>
              </Select>
            </FormControl>

            <div className="flex gap-4 w-full justify-center items-end pb-4">
              <NButton
                variant="text"
                disabled={
                  !selectedLocationGroupForPaste ||
                  selectedLocationGroupForPaste.length === 0
                }
                onClick={() =>
                  handleSelectLocationGroupPaste(
                    selectedLocationGroupForPaste || [],
                  )
                }
              >
                Batch paste
              </NButton>
              <NButton
                variant="text"
                onClick={() => setSelectedLocationGroupForCopy(null)}
              >
                Exit copy/paste mode
              </NButton>
            </div>
          </div>
        </NCardContent>
      </NCard>
    ),
    [
      pasteOption,
      clipOption,
      selectedLocationGroupForPaste,
      handleSelectLocationGroupPaste,
    ],
  );

  return (
    <Box className="m-4">
      {selectedLocationGroupForCopy ? (
        <div className="flex flex-col gap-4">
          {form}
          <NCard>
            <NCardContent className="!p-0">
              <div className="flex w-full items-center">
                <Typography variant="h6" className="grow px-2 py-4">
                  Source location group
                </Typography>
                {tableSettings}
              </div>
              <Datatable
                data={[selectedLocationGroupForCopy]}
                columns={copyTableColumns}
                sorting={sorting}
                setSorting={setSorting}
                pinnedColumns={{ right: ["Actions"] }}
              />
            </NCardContent>
          </NCard>

          <NCard>
            <NCardContent className="!p-0">
              <div className="flex w-full items-center">
                <Typography variant="h6" className="grow px-2 py-4">
                  Target location groups
                </Typography>
                {tableSettings}
              </div>
              <Datatable
                data={sortedLocationGroups}
                columns={targetTableColumns}
                sorting={sorting}
                setSorting={setSorting}
                usePagination={sortedLocationGroups.length > 10}
                pinnedColumns={{ right: ["Actions"] }}
                useRowSelection
                rowSelection={selectedLocationGroupIdForPaste}
                setRowSelection={setSelectedLocationGroupIdForPaste}
                getRowId={(row: LocationGroup) => row.location_group_id}
              />
            </NCardContent>
          </NCard>
        </div>
      ) : (
        <NCard>
          <NCardContent className="!p-0">
            {tableSettings}
            <Datatable
              data={locationGroups}
              columns={targetTableColumns}
              sorting={sorting}
              setSorting={setSorting}
              usePagination={locationGroups.length > 10}
              pinnedColumns={{ right: ["Actions"] }}
            />
          </NCardContent>
        </NCard>
      )}
      {open && (
        <EditableLayerDialog
          project_id={project_id}
          locationGroup={selectedLocationGroup}
          locationGroups={sortedLocationGroups}
          locationNameMapping={locationNameMapping}
          open={open}
          setOpen={setOpen}
          setLocationGroup={setSelectedLocationGroup}
        />
      )}
    </Box>
  );
};
