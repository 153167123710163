import { z } from "zod";

export const LayerTypeSchema = z.enum(["LAYER", "SUBLAYER"]);

export const soilLayerSchema = z.object({
  depth: z.number().gte(0),
  soil_unit_id: z.string(),
  description_overide: z.string().optional(),
  layer_type: LayerTypeSchema,
});

// Inferred types
export type SoilLayerType = z.infer<typeof soilLayerSchema>;

// Default values
export const soilLayerDefaultValues: SoilLayerType = {
  depth: 0,
  description_overide: "",
  soil_unit_id: "",
  layer_type: "LAYER",
};
