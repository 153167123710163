import { FM_PROD_URL, FM_TEST_URL } from "src/definitions/constants";
import { ENV } from "src/types/env";

type CreateLinkProps = {
  project_id: string;
  location_id?: string;
  env: ENV;
};

export const createFMLink = ({
  project_id,
  location_id,
  env,
}: CreateLinkProps) => {
  const baseURL = env === "PROD" ? FM_PROD_URL : FM_TEST_URL;
  const project_url = `${baseURL}/project/${project_id}`;
  if (!location_id) return project_url;
  const location_url = `${project_url}/locations/${location_id}`;
  return location_url;
};
