import { Done } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { TableCustomCell } from "src/types/table";

export const IsDefaultCell = ({
  getValue,
}: TableCustomCell<SoilUnitSchemaType, SoilUnitSchemaType["is_default"]>) => {
  const is_default = getValue() || false;

  return (
    <Box className="h-8 w-8 rounded-full">
      {is_default && (
        <Tooltip title="Default soil unit" placement="right-end">
          <Done color="primary" />
        </Tooltip>
      )}
    </Box>
  );
};
