import {
  DataDomain,
  RenderLayer,
} from "frontend/src/components/common/plots/types";

import { getDataFromDomain } from "./utils";

type DataPointDomain = {
  x1: DataDomain;
  x2: DataDomain;
  y1: DataDomain;
  y2: DataDomain;
};
type Data = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  domain?: DataPointDomain;
};

type RectangleLayerOptions = {
  style?: {
    stroke?: string; // Rectangle border color
    strokeWidth?: number; // Border width
    fill?: string; // Fill color
    strokeDasharray?: string; // Dash pattern for the border (optional)
  };
};

type RectangleProps = {
  id: string;
  data: Data;
  options?: RectangleLayerOptions;
};

export const rectangleLayer =
  ({ id, data, options = {} }: RectangleProps) =>
  ({ content, xScale, yScale }: RenderLayer) => {
    const { style = {} } = options;

    const x1 = getDataFromDomain(data.x1, data.domain?.x1 || "data", xScale);
    const x2 = getDataFromDomain(data.x2, data.domain?.x2 || "data", xScale);
    const y1 = getDataFromDomain(data.y1, data.domain?.y1 || "data", yScale);
    const y2 = getDataFromDomain(data.y2, data.domain?.y2 || "data", yScale);

    const {
      stroke = "red", // Default border color
      strokeWidth = 2, // Default border width
      fill = "none", // Default fill color
      strokeDasharray = "none", // Default: solid border
    } = style;

    // Bind data and create rectangles
    const rects = content.selectAll(`.rect--${id}`).data([data]);

    rects.join(
      (enter) =>
        enter
          .append("rect")
          .attr("class", `rect rect--${id}`) // Set unique class for rectangles
          .attr("x", x1) // X position
          .attr("y", y1)
          .attr("width", x2 - x1)
          .attr("height", y2 - y1)
          .style("stroke", stroke)
          .style("fill", fill)
          .style("stroke-width", strokeWidth)
          .style("stroke-dasharray", strokeDasharray),
      (update) =>
        update
          .attr("x", x1) // X position
          .attr("y", y1)
          .attr("width", x2 - x1)
          .attr("height", y2 - y1)
          .style("stroke", stroke)
          .style("fill", fill)
          .style("stroke-width", strokeWidth)
          .style("stroke-dasharray", strokeDasharray),
    );
  };
