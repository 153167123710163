// Define the SoilBehavior model
export type SoilBehavior = {
  label: string;
  boundary: [number, number];
  label_pos: number;
};

// Define the individual soil zones based on the updated model
const zone1: SoilBehavior = {
  label: "Gravelly Sands",
  boundary: [0, 1.3],
  label_pos: 0.7,
};

const zone2: SoilBehavior = {
  label: "Sands",
  boundary: [1.3, 2.05],
  label_pos: 1.7,
};

const zone3: SoilBehavior = {
  label: "Sand Mixtures",
  boundary: [2.05, 2.6],
  label_pos: 2.4,
};

const zone4: SoilBehavior = {
  label: "Silt Mixtures",
  boundary: [2.6, 2.95],
  label_pos: 2.8,
};

const zone5: SoilBehavior = {
  label: "Clays",
  boundary: [2.95, 3.55],
  label_pos: 3.3,
};

const zone6: SoilBehavior = {
  label: "Organic Soils",
  boundary: [3.55, 4.5],
  label_pos: 4.0,
};

// Example array of zones if needed
export const SOIL_ZONES_SBT: SoilBehavior[] = [
  zone1,
  zone2,
  zone3,
  zone4,
  zone5,
  zone6,
];
