import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { AppContent } from "src/components/app/AppContent";
import { ErrorComponent } from "src/components/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { AddProjectDialog } from "src/pages/projectList/AddProjectDialog";
import { NoProject } from "src/pages/projectList/NoProject";
import { ProjectList } from "src/pages/projectList/ProjectList";
import { ProjectListHeader } from "src/pages/projectList/ProjectListHeader";
import { getAllProjectsQuery } from "src/queries/queries";

export const Route = createFileRoute("/")({
  component: ProjectPage,
});

function ProjectPage() {
  const [openAddProject, setOpenAddProject] = useState(false);
  const { isPending, isError, data: projects } = useQuery(getAllProjectsQuery);

  const projectsInSiite = useMemo(
    () => (projects ? projects.filter((project) => project.in_siite) : []),
    [projects],
  );
  const projectsNotInSiite = useMemo(
    () => (projects ? projects.filter((project) => !project.in_siite) : []),
    [projects],
  );

  if (isPending) {
    return <LoadingComponent />;
  }
  if (isError) {
    return <ErrorComponent />;
  }

  return (
    <AppContent>
      <Box className="max-w-wider mx-auto w-full h-full flex flex-col">
        <ProjectListHeader openAddProjectDialog={setOpenAddProject} />
        <Box className="px-4 py-2">
          {projectsInSiite.length > 0 ? (
            <ProjectList projects={projectsInSiite} />
          ) : (
            <NoProject setOpenAddProject={setOpenAddProject} />
          )}
        </Box>
        {openAddProject && (
          <AddProjectDialog
            open={openAddProject}
            setOpen={setOpenAddProject}
            projectsNotInSiite={projectsNotInSiite}
          />
        )}
      </Box>
    </AppContent>
  );
}
