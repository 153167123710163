import { Box, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";

import { NButton, NCard, NCardContent } from "@ngi/react-component";
import { LocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";

type Props = {
  project_id: string;
  phase_id: string;
  revision_id: string;
  locations: Location[];
  locationGroups: LocationGroup[];
  scope: "locations" | "locationGroups" | "soilLayering";
};

export const RevisionNotEnoughData = ({
  project_id,
  phase_id,
  revision_id,
  locations,
  locationGroups,
  scope,
}: Props) => (
  <NCard className="m-4">
    <NCardContent className="!p-4 text-center flex items-center justify-center">
      {locations.length === 0 ? (
        <>
          <Typography variant="body1">
            No locations found. You need to import locations first
          </Typography>
          <Link
            to="/project/$project_id/phase/$phase_id/revision/$revision_id/locations"
            params={{ project_id, phase_id, revision_id }}
          >
            <NButton variant="text">here</NButton>
          </Link>
        </>
      ) : locationGroups.length === 0 && scope !== "locationGroups" ? (
        <>
          <Typography variant="body1">
            No location groups found. Create first location groups
          </Typography>
          <Link
            to="/project/$project_id/phase/$phase_id/revision/$revision_id/location-groups"
            params={{ project_id, phase_id, revision_id }}
          >
            <NButton variant="text">here</NButton>
          </Link>
        </>
      ) : locationGroups.length === 0 ? (
        <Box className="p-2">
          <Typography variant="body1">
            No location groups found. Create first location groups by clicking
            the button above.
          </Typography>
        </Box>
      ) : null}
    </NCardContent>
  </NCard>
);
