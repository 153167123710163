import axios from "axios";

import { CreateLocationGroup, LocationGroup } from "src/types/locationGroup";
import { setURL } from "src/utils/api";

export type CreateLocationsGroupProps = {
  payload: Omit<LocationGroup, "location_group_id">;
};

export const createLocationGroup = async ({
  payload,
}: CreateLocationsGroupProps) => {
  const url = setURL(`location_group`);
  await axios.post(url, payload);
};

export type UpdateLocationsGroupProps = {
  location_group_id: string;
  payload: Omit<LocationGroup, "location_group_id">;
};

export const updateLocationGroup = async ({
  location_group_id,
  payload,
}: UpdateLocationsGroupProps) => {
  const url = setURL(`location_group/${location_group_id}`);
  await axios.put(url, payload);
};

export type DeleteLocationGroupProps = {
  location_group_id: string;
};

export const deleteLocationGroup = async ({
  location_group_id,
}: DeleteLocationGroupProps) => {
  const url = setURL(`location_group/${location_group_id}`);
  await axios.delete(url);
};

export type BatchCreateLocationsGroupProps = {
  revision_id: string;
  payload: {
    groups: CreateLocationGroup[];
  };
};

export const batchCreateLocationGroups = async ({
  revision_id,
  payload,
}: BatchCreateLocationsGroupProps) => {
  const url = setURL(`revision/${revision_id}/location_groups`);
  await axios.post(url, payload);
};

export type BatchUpdateLocationsGroupProps = {
  revision_id: string;
  payload: { revision_id: string; groups: LocationGroup[] };
};

export const batchUpdateLocationGroups = async ({
  revision_id,
  payload,
}: BatchUpdateLocationsGroupProps) => {
  const url = setURL(`revision/${revision_id}/location_groups`);
  await axios.put(url, payload);
};

type GetRevisionLocationsGroupProps = {
  revision_id: string;
};

export const getRevisionLocationGroups = async ({
  revision_id,
}: GetRevisionLocationsGroupProps) => {
  const url = setURL(`revision/${revision_id}/location_groups`);
  const response = await axios.get(url);

  if (response.status === 200) {
    return response.data.location_groups;
  }
  return response.data;
};
