import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { LayerTypeSchema, SoilLayerType } from "src/schemas/soilLayerSchema";
import { TableCustomCell } from "src/types/table";

export const LayerTypeCell = ({
  getValue,
  row,
  column,
  table,
}: TableCustomCell<SoilLayerType, SoilLayerType["layer_type"]>) => {
  const type = getValue() || LayerTypeSchema.Values.LAYER;
  const updateData = table.options.meta?.updateData;

  const handleChange = (event: SelectChangeEvent) => {
    if (updateData) {
      updateData(row.index, column.id, event.target.value as string);
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        className="SelectCell"
        id="demo-simple-select"
        value={type}
        displayEmpty
        onChange={handleChange}
        // sx={borderOnlyOnHover}
      >
        <MenuItem value={LayerTypeSchema.Values.LAYER}>Layer</MenuItem>
        <MenuItem value={LayerTypeSchema.Values.SUBLAYER}>Sub-layer</MenuItem>
      </Select>
    </FormControl>
  );
};
