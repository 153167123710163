import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { NCard, NCardContent } from "@ngi/react-component";
import { AppContent } from "src/components/app/AppContent";
import { ProjectNavigation } from "src/components/app/AppNavigation";
import { ErrorComponent } from "src/components/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { CreateOrUpdateProjectPhase } from "src/pages/projectPhase/CreateOrUpdateProjectPhase";
import { ProjectPhaseHeader } from "src/pages/projectPhase/ProjectPhaseHeader";
import { ProjectPhases } from "src/pages/projectPhase/ProjectPhases";
import { getProjectPhasesQuery } from "src/queries/queries";

export const Route = createFileRoute("/project/$project_id/")({
  component: ProjectPage,
});

function ProjectPage() {
  const { project_id } = Route.useParams();
  const [openCreateProjectPhase, setOpenCreateProjectPhase] = useState(false);

  const {
    isPending,
    isError,
    data: projectPhases,
  } = useQuery(getProjectPhasesQuery(project_id));

  if (isPending) {
    return <LoadingComponent />;
  }
  if (isError) {
    return <ErrorComponent />;
  }

  return (
    <>
      <ProjectNavigation project_id={project_id} />
      <AppContent>
        <Box className="max-w-wider mx-auto">
          <ProjectPhaseHeader setOpen={setOpenCreateProjectPhase} />
          <Box className="flex flex-col py-4 px-4 gap-4">
            {projectPhases.length > 0 ? (
              <ProjectPhases project_id={project_id} phases={projectPhases} />
            ) : (
              <NCard className="m-4">
                <NCardContent className="!p-8">
                  <div className="flex gap-4 justify-center items-center">
                    <Typography variant="body1">
                      Not project phases found. Click on the button above to
                      create a project phase.
                    </Typography>
                  </div>
                </NCardContent>
              </NCard>
            )}
          </Box>
        </Box>
        <CreateOrUpdateProjectPhase
          open={openCreateProjectPhase}
          setOpen={setOpenCreateProjectPhase}
          project_id={project_id}
        />
      </AppContent>
    </>
  );
}
