export function findCommonSubstring(strings: string[]): string {
  if (strings.length === 0) return "";

  let commonSubstring = strings[0];

  for (let i = 1; i < strings.length; i++) {
    commonSubstring = getCommonSubstring(commonSubstring, strings[i]);

    if (commonSubstring === "") {
      break;
    }
  }

  return sanitizeCommonSubstring(commonSubstring);
}

function getCommonSubstring(str1: string, str2: string): string {
  let maxLength = 0;
  let longestSubstring = "";

  for (let i = 0; i < str1.length; i++) {
    for (let j = 0; j < str2.length; j++) {
      let lcsTemp = 0;
      let tempSubstring = "";

      while (
        i + lcsTemp < str1.length &&
        j + lcsTemp < str2.length &&
        str1[i + lcsTemp] === str2[j + lcsTemp]
      ) {
        tempSubstring += str1[i + lcsTemp];
        lcsTemp++;
      }

      if (tempSubstring.length > maxLength) {
        maxLength = tempSubstring.length;
        longestSubstring = tempSubstring;
      }
    }
  }

  // Ensure we return a meaningful substring
  if (maxLength < 2) {
    return "";
  }

  return longestSubstring;
}

function sanitizeCommonSubstring(substring: string): string {
  let sanitized = substring.trim();
  sanitized = sanitized.replace(/[\s.,!?;:]+$/, "");
  return sanitized;
}
