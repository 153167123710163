import { useState } from "react";
import { Delete, Edit, FolderOpen } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";

import { NIconButton } from "@ngi/react-component";
import { ProjectPhase } from "src/types/projectPhase";
import { ProjectPhaseRevision } from "src/types/revision";
import { CreateOrUpdateProjectPhaseRevision } from "./CreateOrUpdateProjectPhaseRevision";
import { DeleteProjectPhaseRevision } from "./DeleteProjectPhaseRevision";

type Props = {
  project_id: string;
  phase_id: string;
  revision: ProjectPhaseRevision;
  projectPhases: ProjectPhase[];
};

export const ProjectPhaseRevisionItem = ({
  project_id,
  phase_id,
  revision,
  projectPhases,
}: Props) => {
  const navigate = useNavigate();
  const [openCreateProjectPhaseRevision, setOpenCreateProjectPhaseRevision] =
    useState(false);
  const [openDeleteProjectPhaseRevision, setOpenDeleteProjectPhaseRevision] =
    useState(false);

  return (
    <div>
      <div className="flex w-full items-center ">
        <div className="Info h-10 flex flex-row grow items-center gap-4 px-2">
          <Typography variant="h6" className="text-neutral-700">
            {revision.name}
          </Typography>
          <Typography
            variant="body1"
            className="hidden sm:block sm:truncate sm:..."
          >
            {revision.description}
          </Typography>
        </div>
        <div className="Actions">
          <Tooltip title="Open phase" placement="right-end">
            <NIconButton
              onClick={() => {
                navigate({
                  to: "/project/$project_id/phase/$phase_id/revision/$revision_id",
                  params: {
                    project_id,
                    phase_id,
                    revision_id: revision.revision_id,
                  },
                });
              }}
            >
              <FolderOpen />
            </NIconButton>
          </Tooltip>
          <Tooltip title="Edit revision" placement="right-end">
            <NIconButton
              onClick={() => setOpenCreateProjectPhaseRevision(true)}
            >
              <Edit />
            </NIconButton>
          </Tooltip>

          <Tooltip title="Delete revision" placement="right-end">
            <NIconButton
              onClick={() => setOpenDeleteProjectPhaseRevision(true)}
            >
              <Delete />
            </NIconButton>
          </Tooltip>
        </div>
      </div>

      {openCreateProjectPhaseRevision && (
        <CreateOrUpdateProjectPhaseRevision
          open={openCreateProjectPhaseRevision}
          setOpen={setOpenCreateProjectPhaseRevision}
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision.revision_id}
          initialData={revision}
          projectPhases={projectPhases}
        />
      )}

      {openDeleteProjectPhaseRevision && (
        <DeleteProjectPhaseRevision
          open={openDeleteProjectPhaseRevision}
          setOpen={setOpenDeleteProjectPhaseRevision}
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision.revision_id}
        />
      )}
    </div>
  );
};
