import { useCallback, useMemo, useRef, useState } from "react";
import { Settings } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import * as d3 from "d3";

import { NButton, NDialog, NInlineAlert } from "@ngi/react-component";
import { Legend } from "src/components/common/plots/elements/Legend";
import { PlotSettings } from "src/components/common/plots/PlotSetting";
import { RAW_CPT_PLOTS } from "src/definitions/plotConstants";
import { getCPTQuery } from "src/queries/queries";
import { DataPoint } from "src/types/data";
import { LocationGroup } from "src/types/locationGroup";
import { LocationNameMapping } from "src/types/locations";
import { PlotLegendItem } from "src/types/plotElements";
import { GeoPlot } from "src/types/plots";
import { groupByMethodId } from "src/utils/groupCPTs";
import { LocationGroupPlot } from "./LocationGroupPlot";

type Props = {
  project_id: string;
  locationGroup: LocationGroup;
  locationGroups: LocationGroup[];
  locationNameMapping: LocationNameMapping;
  open: boolean;
  setOpen: (b: boolean) => void;
  setLocationGroup: (location_group_id: string) => void;
};

export const LocationGroupPlotPreview = ({
  project_id,
  locationGroup,
  locationGroups,
  locationNameMapping,
  open,
  setOpen,
  setLocationGroup,
}: Props) => {
  const [openPlotSettings, setOpenPlotSettings] = useState<boolean>(false);
  const zoomDispatch = useRef(d3.dispatch("zoom")).current;
  const defaultSelectedPlots = RAW_CPT_PLOTS.filter((plot) => plot.default);
  const [selectedPlots, setSelectedPlots] = useState<GeoPlot[]>(
    RAW_CPT_PLOTS.filter((plot) => plot.default),
  );
  const [numPlotPerRow, setNumPlotPerRow] = useState<number>(
    defaultSelectedPlots.length,
  );

  const plotSettingsHasChanged = useMemo(
    () =>
      defaultSelectedPlots.length !== selectedPlots.length ||
      defaultSelectedPlots.some((plot, i) => plot.id !== selectedPlots[i].id) ||
      numPlotPerRow !== defaultSelectedPlots.length,
    [defaultSelectedPlots, numPlotPerRow, selectedPlots],
  );

  const resetPlotSettings = useCallback(() => {
    setSelectedPlots(defaultSelectedPlots);
    setNumPlotPerRow(defaultSelectedPlots.length);
  }, [defaultSelectedPlots]);

  const currentIndex = useMemo(
    () =>
      locationGroups.findIndex(
        (group) => group.location_group_id === locationGroup.location_group_id,
      ),
    [locationGroups, locationGroup.location_group_id],
  );

  const prevLocationGroup = useMemo(
    () => locationGroups[currentIndex - 1] || null,
    [locationGroups, currentIndex],
  );

  const nextLocationGroup = useMemo(
    () => locationGroups[currentIndex + 1] || null,
    [locationGroups, currentIndex],
  );

  // TODO: can we get the status when having multiple queries?
  const cptQueries = useQueries({
    queries: locationGroup.location_ids.map((siite_location_id) =>
      getCPTQuery(project_id, siite_location_id),
    ),
    combine: (results) =>
      results.map((result) => result.data?.data).filter(Boolean),
  });

  // Prefetch the previous and next locationGroup CPTs
  useQueries({
    queries: prevLocationGroup
      ? prevLocationGroup.location_ids.map((siite_location_id) =>
          getCPTQuery(project_id, siite_location_id),
        )
      : [],
  });
  useQueries({
    queries: nextLocationGroup
      ? nextLocationGroup.location_ids.map((siite_location_id) =>
          getCPTQuery(project_id, siite_location_id),
        )
      : [],
  });

  const handleGoToGroup = useCallback(
    (locationGroup: LocationGroup) => {
      setLocationGroup(locationGroup.location_group_id);
    },
    [setLocationGroup],
  );

  const flattenedData = useMemo(() => {
    if (!cptQueries) return [[]];
    const ft = cptQueries.flat();
    return ft;
  }, [cptQueries]);

  const actions = useMemo(
    () => (
      <>
        <NButton
          onClick={() => handleGoToGroup(prevLocationGroup)}
          variant="text"
          disabled={!prevLocationGroup}
        >
          Previous group
        </NButton>
        <NButton
          onClick={() => handleGoToGroup(nextLocationGroup)}
          variant="text"
          disabled={!nextLocationGroup}
        >
          Next group
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Close
        </NButton>
      </>
    ),
    [handleGoToGroup, nextLocationGroup, prevLocationGroup, setOpen],
  );

  const margins = { top: 50, left: 50, bottom: 50, right: 50 };

  const legendItems: PlotLegendItem[] = useMemo(
    () =>
      locationGroup.location_ids.map((location_id, i) => ({
        id: location_id,
        label: locationNameMapping[location_id],
        lineColor: d3.schemeCategory10[i % 10],
        lineWidth: 2,
        lineStrokeStyle: "solid",
      })),
    [locationGroup.location_ids, locationNameMapping],
  );

  return (
    <NDialog
      dialogTitle={`Location group ${locationGroup.name} - Raw CPT preview`}
      fullScreen
      onClose={() => setOpen(false)}
      open={open}
      actions={actions}
    >
      <Box className="h-full">
        {!cptQueries ? (
          <NInlineAlert severity="loading">
            Loading the CPT data...
          </NInlineAlert>
        ) : cptQueries.length === 0 ? (
          <NInlineAlert severity="warning">No CPT data to display</NInlineAlert>
        ) : (
          <div className="h-full flex flex-col">
            <div className="px-2 flex justify-end">
              <Tooltip title="Plot settings">
                <IconButton
                  color="primary"
                  onClick={() => setOpenPlotSettings(true)}
                >
                  <Settings />
                </IconButton>
              </Tooltip>
            </div>

            <div
              className={`grow grid grid-cols-${numPlotPerRow} auto-rows-fr`}
            >
              {selectedPlots.map((plot) => (
                <LocationGroupPlot
                  key={plot.id}
                  id={`${plot.id}`}
                  data={cptQueries as DataPoint[][]}
                  flattenedData={flattenedData as DataPoint[]}
                  xKeys={plot.series.x}
                  yKey={plot.series.y}
                  margins={margins}
                  zoomDispatch={zoomDispatch}
                />
              ))}
            </div>
            <Box className="flex gap-4 justify-center pt-4">
              <Typography variant="h6">Locations</Typography>
              <Legend items={legendItems} classname="flex flex-row gap-4" />
            </Box>
          </div>
        )}
      </Box>
      {openPlotSettings && (
        <NDialog
          dialogTitle="Plot settings"
          onClose={() => setOpenPlotSettings(false)}
          open={open}
          maxWidth="md"
          actions={
            <>
              <NButton
                variant="text"
                disabled={!plotSettingsHasChanged}
                onClick={() => resetPlotSettings()}
              >
                Reset to default settings
              </NButton>
              <NButton
                variant="text"
                onClick={() => setOpenPlotSettings(false)}
              >
                Close
              </NButton>
            </>
          }
        >
          <PlotSettings
            numPlotPerRow={numPlotPerRow}
            setNumPlotPerRow={setNumPlotPerRow}
            selectedPlots={selectedPlots}
            setSelectedPlots={setSelectedPlots}
            plots={RAW_CPT_PLOTS}
          />
        </NDialog>
      )}
    </NDialog>
  );
};
