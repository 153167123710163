import * as XLSX from "xlsx";

import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { LocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";
import {
  parseLocationGroups,
  ParseLocationGroupsReturn,
} from "./parse-location-groups";
import { parseSoilUnits } from "./parse-soil-units";

export type ParentFileExcel = {
  soil_unit_parameters: {
    SoilUnit: string;
  };
};

export type ImportParentFileReturn = {
  status:
    | "Success"
    | "SoilUnitError"
    | "LocationGroupError"
    | "TabMissingError";
  locationGroups: ParseLocationGroupsReturn;
  soilUnits: SoilUnitSchemaType[];
  soilUnitMapping: Record<string, string>;
  message?: string;
};

export async function importExcel(
  file: File,
  soilUnits: SoilUnitSchemaType[],
  locationGroups: LocationGroup[],
  locations: Location[],
  allowImportWithMissingLocations: boolean,
): Promise<ImportParentFileReturn> {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { type: "array", cellStyles: true });
  const worksheets = workbook.SheetNames;

  if (!worksheets.includes("location_details")) {
    return {
      status: "TabMissingError",
      message: "Missing location_details sheet",
      soilUnits: [],
      soilUnitMapping: {},
      locationGroups: { group: [], missingLocations: [] },
    };
  }

  if (!worksheets.includes("soil_unit_parameters")) {
    return {
      status: "TabMissingError",
      message: "Missing soil_unit_parameters sheet",
      soilUnits: [],
      soilUnitMapping: {},
      locationGroups: { group: [], missingLocations: [] },
    };
  }

  if (!worksheets.includes("global_unit_layers")) {
    return {
      status: "TabMissingError",
      message: "Missing global_unit_layers sheet",
      soilUnits: [],
      soilUnitMapping: {},
      locationGroups: { group: [], missingLocations: [] },
    };
  }

  // Get the soil unit names
  let status: ImportParentFileReturn["status"] = "Success";
  let message: string = "";

  const soilUnitExcel = parseSoilUnits(
    workbook.Sheets["soil_unit_parameters"],
    soilUnits,
  );

  const soilUnitMapping = soilUnitExcel.reduce(
    (acc, cur) => {
      acc[cur.name] = cur.soil_unit_id;
      return acc;
    },
    {} as Record<string, string>,
  );

  if (
    soilUnitExcel.length === 0 ||
    soilUnitExcel.some((unit) => unit.soil_unit_id === "")
  ) {
    console.error("Could not match the soil unit names");
    status = "SoilUnitError";
    message = "Could not match the soil unit names";
  }

  const locationGroupsLayers = parseLocationGroups(
    workbook,
    locations,
    locationGroups,
    soilUnits,
    soilUnitMapping,
  );

  if (
    locationGroupsLayers.locationGroups.length === 0 ||
    locationGroupsLayers.locationGroups.some(
      (group) => group.location_group_id === "",
    )
  ) {
    console.error("Could not assess the locationGroups layers");
    status = "LocationGroupError";
    message = "Could not assess the locationGroups layers";
  }

  return {
    status,
    message,
    soilUnits: soilUnitExcel,
    soilUnitMapping,
    locationGroups: locationGroupsLayers,
  };
}
