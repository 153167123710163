import { Box } from "@mui/material";

import { NCheckbox } from "@ngi/react-component";

export const setInitialSelectedRows = (
  initialSelectedRows: number[] | undefined,
) => {
  if (!initialSelectedRows) {
    return {};
  }
  const selectedRows: Record<number, boolean> = {};
  initialSelectedRows.forEach((index) => {
    selectedRows[index] = true;
  });
  return selectedRows;
};

export const rowSelectionColumn = () => ({
  id: "select",
  size: 20,
  header: ({ table }) => (
    <NCheckbox
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />
  ),
  cell: ({ row }) => (
    <Box sx={{ mx: 1 }}>
      <NCheckbox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    </Box>
  ),
});
