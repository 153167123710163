import {
  Alert,
  AlertTitle,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";

export const LoadingComponent = ({
  label = "Loading...",
}: {
  label?: string;
}) => (
  <div className="mt-24 flex flex-col justify-center items-center">
    <div className="w-96">
      <LinearProgress />
    </div>
    <div>{label}</div>
  </div>
);

export const InlineLoadingComponent = () => (
  <div className="p-4 flex flex-col justify-center items-center">
    <div className="w-96">
      <LinearProgress />
    </div>
  </div>
);

type LoadingErrorComponentProps = {
  label: string;
  description?: string;
};

export const InlineLoadingErrorComponent = ({
  label,
  description,
}: LoadingErrorComponentProps) => (
  <div className="p-2 flex flex-col justify-center items-center">
    <div className="w-96">
      <Alert severity="error">
        {description ? (
          <>
            <AlertTitle>{label}</AlertTitle>
            {description}
          </>
        ) : (
          label
        )}
      </Alert>
    </div>
  </div>
);
