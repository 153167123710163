import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import { getAppConfig } from "src/queries/queries";

import "./AppHeader.scss";

export const AppHeader = () => {
  const { data: config } = useQuery(getAppConfig);

  return (
    <Box
      className="AppHeader"
      sx={{ flexGrow: 1, height: "50px", paddingBottom: "8px" }}
    >
      <AppBar color="secondary" position="fixed">
        <Toolbar>
          <Typography className="AppTitle" variant="h6" component="div">
            <Link to="/">FM-SIITE</Link>
          </Typography>

          {config && config.env !== "PROD" && (
            <div className={`env ${config.env}`}>{config.env} ENVIRONMENT</div>
          )}

          <div className="flex-grow" />

          {config && config.version && <div>Version {config.version}</div>}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
