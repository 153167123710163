import axios from "axios";

import {
  projectPhaseRevisionSchema,
  ProjectPhaseRevisionSchemaType,
} from "src/schemas/projectPhaseRevisionSchema";
import { setURL } from "src/utils/api";

type GetProjectPhaseRevisionProps = {
  revision_id: string;
};

export const getProjectPhaseRevision = async ({
  revision_id,
}: GetProjectPhaseRevisionProps) => {
  const url = setURL(`revision/${revision_id}`);
  const response = await axios.get(url);
  return response.data;
};

export type CreateProjectPhaseRevisionProps = {
  phase_id: string;
  payload: ProjectPhaseRevisionSchemaType;
};

export const createProjectPhaseRevision = async ({
  phase_id,
  payload,
}: CreateProjectPhaseRevisionProps) => {
  const parsed_payload = projectPhaseRevisionSchema.safeParse(payload);
  if (!parsed_payload.success) {
    console.error(
      "createProjectPhaseRevision : Error parsing payload",
      parsed_payload.error,
    );
  } else {
    const url = setURL(`phase/${phase_id}/revision`);
    await axios.post(url, parsed_payload.data);
  }
};

export type DuplicateProjectPhaseRevisionProps = {
  phase_id: string;
  payload: ProjectPhaseRevisionSchemaType & { source_revision_id: string };
};

export const duplicateProjectPhaseRevision = async ({
  phase_id,
  payload,
}: DuplicateProjectPhaseRevisionProps) => {
  const parsed_payload = projectPhaseRevisionSchema.safeParse(payload);
  if (!parsed_payload.success) {
    console.error(
      "createProjectPhaseRevision : Error parsing payload",
      parsed_payload.error,
    );
  } else {
    const url = setURL(`phase/${phase_id}/revision/duplicate`);
    await axios.post(url, {
      target_revision: parsed_payload.data,
      source_revision_id: payload.source_revision_id,
    });
  }
};

export type UpdateProjectPhaseRevisionProps = {
  revision_id: string;
  payload: ProjectPhaseRevisionSchemaType;
};

export const updateProjectPhaseRevision = async ({
  revision_id,
  payload,
}: UpdateProjectPhaseRevisionProps) => {
  const parsed_payload = projectPhaseRevisionSchema.safeParse(payload);
  // TODO: refactor the logic to be less verbose
  if (!parsed_payload.success) {
    console.error(
      "createProjectPhaseRevision : Error parsing payload",
      parsed_payload.error,
    );
  } else {
    const url = setURL(`revision/${revision_id}`);
    console.log("payload", payload);
    console.log("utdat", parsed_payload.data);
    const { name, description } = parsed_payload.data;
    await axios.patch(url, { name, description });
  }
};

type GetProjectPhaseRevisionsProps = {
  phase_id: string;
};

export const getProjectPhaseRevisions = async ({
  phase_id,
}: GetProjectPhaseRevisionsProps) => {
  const url = setURL(`phase/${phase_id}/revisions`);
  const response = await axios.get(url);
  return response.data;
};

export type DeleteProjectPhaseRevisionProps = {
  revision_id: string;
};

export const deleteProjectPhaseRevision = async ({
  revision_id,
}: DeleteProjectPhaseRevisionProps) => {
  const url = setURL(`revision/${revision_id}`);
  await axios.delete(url);
};

type ExportFileRevisionProps = {
  revision_id: string;
};

export const exportParentFileRevision = async ({
  revision_id,
}: ExportFileRevisionProps) => {
  const url = setURL(`revision/${revision_id}/export/excel/parent_file`);
  const response = await axios.get(url, {
    responseType: "blob", // Important to receive the file as a blob
  });
  return response.data;
};

export const exportGlobalUnitLayerFileRevision = async ({
  revision_id,
}: ExportFileRevisionProps) => {
  const url = setURL(`revision/${revision_id}/export/excel/global_unit_layer`);
  const response = await axios.get(url, {
    responseType: "blob", // Important to receive the file as a blob
  });
  return response.data;
};
