import { Delete } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { NIconButton } from "@ngi/react-component";
import { SoilLayerType } from "src/schemas/soilLayerSchema";
import { TableCustomCell } from "src/types/table";

export const EditableLayerActionCell = ({
  row,
  table,
}: TableCustomCell<SoilLayerType, SoilLayerType["depth"]>) => {
  const meta = table.options.meta;

  return (
    <Box className="flex gap-2 items-center justify-start">
      {row.index > 0 && (
        <Tooltip title="Delete layer?" placement="right-end">
          <NIconButton
            color="error"
            onClick={() => {
              meta?.removeRow(row.index);
            }}
          >
            <Delete />
          </NIconButton>
        </Tooltip>
      )}
    </Box>
  );
};
