export enum IMPORT_CPT_STATUSES {
  WAITING = "WAITING",
  SUCCESS = "SUCCESS",
  EXPIRED_ERROR = "EXPIRED_ERROR",
  FM_ACCESS_ERROR = "FM_ACCESS_ERROR",
  FM_ERROR = "FM_ERROR",
  CPT_DATA_ERROR = "CPT_DATA_ERROR",
  LOCATION_STALE_ERROR = "LOCATION_STALE_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export const ERRORS_STATUSES = [
  IMPORT_CPT_STATUSES.FM_ERROR,
  IMPORT_CPT_STATUSES.EXPIRED_ERROR,
  IMPORT_CPT_STATUSES.CPT_DATA_ERROR,
  IMPORT_CPT_STATUSES.UNKNOWN_ERROR,
  IMPORT_CPT_STATUSES.FM_ACCESS_ERROR,
  IMPORT_CPT_STATUSES.LOCATION_STALE_ERROR,
];
