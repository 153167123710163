import { makeGeoParameter } from "src/utils/geoParameters";

export const GEO = {
  depth: makeGeoParameter({ key: "depth", name: "Depth", unit: "m" }),
  qc: makeGeoParameter({
    key: "qc",
    name: "Cone resistance",
    unit: "MPa",
    legendLabel: "qc",
  }),
  fs: makeGeoParameter({ key: "fs", name: "Friction sleeve", unit: "MPa" }),
  u0: makeGeoParameter({ key: "u0", name: "u0", unit: "kPa" }),
  u2: makeGeoParameter({ key: "u2", name: "Pore pressure", unit: "MPa" }),
  temperature: makeGeoParameter({
    key: "temperature",
    name: "Temperature",
    unit: "degC",
  }),
  tilt: makeGeoParameter({ key: "tilt", name: "tilt", unit: "deg" }),
  penetration_force: makeGeoParameter({
    key: "penetration_force",
    name: "Penetration force",
    unit: "kN",
  }),
  penetration_rate: makeGeoParameter({
    key: "penetration_rate",
    name: "Penetration rate",
    unit: "m/s",
  }),
  sigV: makeGeoParameter({ key: "sigV", name: "Vertical stress", unit: "kPa" }),
  sigVtEff: makeGeoParameter({
    key: "sigVtEff",
    name: "Effective vertical stress",
    unit: "kPa",
    legendLabel: "sigVtEff",
    axisLabel: "Vertical stress (kPa)",
  }),
  sigVtTot: makeGeoParameter({
    key: "sigVtTotal",
    name: "Total vertical stress",
    unit: "kPa",
    legendLabel: "sigVt",
  }),
  uw: makeGeoParameter({ key: "uw", name: "uw", unit: "kN/m3" }),
  Nkt: makeGeoParameter({ key: "Nkt", name: "Nkt", unit: "-" }),
  qn: makeGeoParameter({ key: "qn", name: "qn", unit: "MPa" }),
  qt: makeGeoParameter({ key: "qt", name: "qt", unit: "MPa" }),
  Qt: makeGeoParameter({ key: "Qt", name: "Qt", unit: "-" }),
  Qtn: makeGeoParameter({ key: "Qtn", name: "Qtn", unit: "-" }),
  Rf: makeGeoParameter({ key: "Rf", name: "Rf", unit: "-" }),
  Fr: makeGeoParameter({ key: "Fr", name: "Fr", unit: "-" }),
  Bq: makeGeoParameter({ key: "Bq", name: "Bq", unit: "-" }),
  Icn: makeGeoParameter({
    key: "Icn",
    name: "Normalized soil behaviour index",
    unit: "-",
    legendLabel: "SBT",
    axisLabel: "SBT (-)",
  }),
};
