import { Error, NewReleases, Update } from "@mui/icons-material";
import { Box } from "@mui/material";

import { NChip } from "@ngi/react-component";
import {
  CheckLocationGroupReturnType,
  StatusEnum,
  StatusEnumType,
} from "src/pages/revisionLocationGroups/location-group-utils";
import { LocationGroup } from "src/types/locationGroup";
import { TableCustomCell } from "src/types/table";

type StatusComponentProps = {
  status: StatusEnumType;
  label: string;
};
const StatusComponent = ({ status, label }: StatusComponentProps) => {
  let icon;
  switch (status) {
    case StatusEnum.NEW:
      icon = <NewReleases />;
      break;
    case StatusEnum.UPDATED:
      icon = <Update />;
      break;
    case StatusEnum.ERROR:
      icon = <Error />;
      break;
    default:
      return null;
  }
  if (icon) {
    return (
      <NChip
        icon={icon}
        className="w-fit"
        label={label}
        color={status === StatusEnum.ERROR ? "error" : "primary"}
        variant="outlined"
      />
    );
  }
  return null;
};

export const LocationGroupStatusCellFactory = (
  locationGroupChecker: (
    locationGroup: LocationGroup,
  ) => CheckLocationGroupReturnType,
) => {
  const Component = ({
    row,
  }: TableCustomCell<
    LocationGroup,
    LocationGroup["location_group_id"]
  >) => {
    const data = row.original;
    const { status, message } = locationGroupChecker(data);

    return (
      <Box className="flex gap-2 items-center justify-start">
        <StatusComponent status={status} label={message} />
      </Box>
    );
  };

  Component.displayName = "LocationGroupStatusCell";
  return Component;
};
