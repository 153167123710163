import { useCallback, useMemo, useState } from "react";
import { Grading } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { NButton, NCard, NCardContent, NDialog } from "@ngi/react-component";
import { ErrorComponent } from "src/components/app/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { getProjectFieldManagerLocationsQuery } from "src/queries/queries";
import { FieldManagerLocation, Location } from "src/types/locations";
import { SelectLocationTable } from "./SelectLocationTable";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  project_id: string;
  phase_id: string;
  revision_id: string;
  revisionLocations: Location[];
  onAddLocations: (fmLocationIDs: FieldManagerLocation[]) => void;
};

export const SelectLocationDialog = ({
  open,
  setOpen,
  project_id,
  revisionLocations,
  onAddLocations,
}: Props) => {
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const {
    status,
    error,
    data: fmLocations,
  } = useQuery(getProjectFieldManagerLocationsQuery(project_id));

  const fmLocationNotInRevision = useMemo(
    () =>
      fmLocations?.filter(
        (loc) =>
          !revisionLocations.find(
            (rloc) => rloc.location_id === loc.location_id,
          ),
      ),
    [fmLocations, revisionLocations],
  );

  const handleAddLocations = useCallback(() => {
    if (fmLocations) {
      const locations = fmLocations.filter((loc) =>
        selectedLocations.includes(loc.location_id),
      );
      if (locations) {
        onAddLocations(locations);
      }
      setOpen(false);
    }
  }, [fmLocations, onAddLocations, selectedLocations, setOpen]);

  const selectLocationActions = useMemo(
    () => (
      <>
        <NButton
          onClick={handleAddLocations}
          disabled={selectedLocations.length === 0}
          startIcon={<Grading />}
        >
          {selectedLocations.length === 0
            ? "No location selected"
            : `Select ${selectedLocations.length} locations`}
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    ),
    [handleAddLocations, selectedLocations.length, setOpen],
  );

  if (status === "pending") {
    return <LoadingComponent />;
  }
  if (status === "error") {
    return <ErrorComponent error={error} />;
  }

  return (
    <NDialog
      dialogTitle="Select locations from FieldManager"
      onClose={() => setOpen(false)}
      open={open}
      fullScreen
      actions={selectLocationActions}
    >
      <Box>
        {fmLocationNotInRevision && fmLocationNotInRevision.length > 0 ? (
          <>
            <Typography variant="body1" className="pb-4">
              Select below the locations from FieldManager that will be used for
              this phase. Note that once selected, the locations cannot be
              changed for this phase.
            </Typography>
            <SelectLocationTable
              project_id={project_id}
              locations={fmLocationNotInRevision}
              selectedLocations={selectedLocations}
              onSelectedLocationChange={setSelectedLocations}
            />
          </>
        ) : (
          <NCard className="m-4">
            <NCardContent className="!p-8">
              <div className="flex gap-4 justify-center items-center">
                <Typography variant="body1">
                  There is no additional locations from FieldManager. It seems
                  all the locations have already been imported.
                </Typography>
              </div>
            </NCardContent>
          </NCard>
        )}
      </Box>
    </NDialog>
  );
};
