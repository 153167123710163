import Papa from "papaparse";

import { LocationGroup, LocationGroupNote } from "src/types/locationGroup";

type HorizonMetadata = {
  Loc_ID: string;
  Easting: number;
  Northing: number;
  Water_Base: number;
  Water_Top: number;
};

type Horizon = {
  label: string;
  depth: number;
};

type HorizonLocation = HorizonMetadata & {
  horizons: Horizon[];
};

type ParsedData = HorizonMetadata & {
  Loc_ID: string;
  Easting: string;
  Northing: string;
  Water_Base: string;
  Water_Top: string;
  [key: string]: string | number;
};

const horizonMetadataKeys = [
  "Loc_ID",
  "Easting",
  "Northing",
  "Water_Base",
  "Water_Top",
];

function parseHorizonData(data: ParsedData[]): HorizonLocation[] {
  const locations: HorizonLocation[] = [];
  data.forEach((row) => {
    const horizons: Horizon[] = [];
    Object.entries(row).forEach(([key, value]) => {
      if (!horizonMetadataKeys.includes(key)) {
        const depth = parseFloat(value as string);
        if (!isNaN(depth)) {
          horizons.push({ label: key as string, depth });
        }
      }
    });

    horizons.sort((a: Horizon, b: Horizon) => a.depth - b.depth);

    locations.push({
      Loc_ID: row.Loc_ID,
      Easting: parseFloat(row.Easting),
      Northing: parseFloat(row.Northing),
      Water_Top: parseFloat(row.Water_Top),
      Water_Base: parseFloat(row.Water_Base),
      horizons,
    });
  });
  return locations;
}

export type ParsedHorizonReturnData = {
  status: "Success" | "Error";
  data: HorizonLocation[];
  message?: string;
};

export async function parseCSV(
  csvContent: string,
): Promise<ParsedHorizonReturnData> {
  console.log("file", csvContent);
  const result = await Papa.parse<ParsedData>(csvContent);
  console.log("result after parsing", result);

  const isError = result.errors.length > 0;

  const headers = result.data[0];
  const data = result.data.slice(1).map((row) => {
    const obj: ParsedData = {};
    headers.forEach((header, index) => {
      obj[header] = row[index];
    });
    return obj;
  });
  console.log("data", data);

  const p = parseHorizonData(data);

  console.log("parsed", p);

  return {
    status: isError ? "Error" : "Success",
    message: isError ? result.errors[0].message : "",
    data: p,
  };
}

const makeHorizonNote = (horizon: Horizon): LocationGroupNote => ({
  title: horizon.label,
  depth: horizon.depth,
  note_type: "HORIZON",
});

export type MatchLocationGroupReturn = {
  locationGroups: LocationGroup[];
  matchedCount: number;
};

export function matchLocationGroupBaseOnName(
  horizonData: HorizonLocation[],
  locationGroups: LocationGroup[],
): MatchLocationGroupReturn {
  const horizonDataMapping = horizonData.reduce(
    (acc, cur) => {
      acc[cur.Loc_ID] = cur;
      return acc;
    },
    {} as Record<string, HorizonLocation>,
  );

  const matchedLocationGroupCount = locationGroups.reduce((acc, group) => {
    const horizonLocation = horizonDataMapping[group.name];
    if (horizonLocation) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const matchedLocationGroups = locationGroups
    .map((group) => {
      const horizonLocation = horizonDataMapping[group.name];
      if (horizonLocation) {
        return {
          ...group,
          notes: horizonLocation.horizons.map(makeHorizonNote),
        };
      }
      return group;
    })
    .filter((group) => group.notes && group.notes.length > 0);

  console.log("BBB matchedLocationGroups", matchedLocationGroups);

  return {
    locationGroups: matchedLocationGroups,
    matchedCount: matchedLocationGroupCount,
  };
}
