import { Box } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { LocationGroupNote } from "src/types/locationGroup";

type Props = {
  notes: LocationGroupNote[];
};

const columnHelper = createColumnHelper<LocationGroupNote>();
const columns = [
  columnHelper.accessor("depth", {
    cell: (row) => row.getValue(),
    header: "Depth [m]",
    size: 60,
    enableColumnFilter: true,
  }),
  columnHelper.accessor("title", {
    cell: (row) => row.getValue(),
    header: "Title",
    size: 300,
    enableColumnFilter: true,
  }),
];

export const HorizonTable = ({ notes }: Props) => (
  <Box>
    <Datatable
      data={notes}
      columns={columns}
      usePagination={notes.length > 10}
    />
  </Box>
);
