import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { setDefaultRevisionSoilUnitMutationQuery } from "src/queries/mutations";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { CreateOrUpdateSoilUnit } from "./CreateOrUpdateSoilUnit";
import { DeleteSoilUnitDialog } from "./DeleteSoilUnit";
import { ActionCellFactory } from "./tableFeatures/ActionCell";
import { IsDefaultCell } from "./tableFeatures/IsDefaultCell";
import { SoilColorCell } from "./tableFeatures/SoilColorCell";

type Props = {
  revision_id: string;
  data: SoilUnitSchemaType[];
};

const columnHelper = createColumnHelper<SoilUnitSchemaType>();

export const SoilUnitTable = ({ revision_id, data }: Props) => {
  // Sort the soil units so that the default soil unit is at the top
  const localData = useMemo(
    () =>
      data.sort((a, b) => {
        if (a.is_default && !b.is_default) return -1;
        if (!a.is_default && b.is_default) return 1;
        return a.name.localeCompare(b.name);
      }),
    [data],
  );

  const [selectedSoilUnit, setSelectedSoilUnit] =
    useState<SoilUnitSchemaType | null>(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const setDefaultRevisionSoilUnitMutation = useMutation(
    setDefaultRevisionSoilUnitMutationQuery(revision_id),
  );

  const handleSetSelectedSoilUnit = useCallback(
    (soil_unit_id: string): boolean => {
      const soilUnit = data.find(
        (soilUnit) => soilUnit.soil_unit_id === soil_unit_id,
      );
      if (soilUnit) {
        setSelectedSoilUnit(
          data.find(
            (soilUnit) => soilUnit.soil_unit_id === soil_unit_id,
          ) as SoilUnitSchemaType,
        );
        return true;
      }
      return false;
    },
    [data],
  );

  const handleUpdateSoilUnit = useCallback(
    (soil_unit_id: string) => {
      const status = handleSetSelectedSoilUnit(soil_unit_id);
      if (status) {
        setUpdateDialogOpen(true);
      }
    },
    [handleSetSelectedSoilUnit],
  );

  const handleSetDefaultSoilUnit = useCallback(
    (soil_unit_id: string) => {
      const status = handleSetSelectedSoilUnit(soil_unit_id);
      if (status) {
        setDefaultRevisionSoilUnitMutation.mutate({
          soil_unit_id,
        });
      }
    },
    [handleSetSelectedSoilUnit, setDefaultRevisionSoilUnitMutation],
  );

  const handleDeleteSoilUnit = useCallback(
    (soil_unit_id: string) => {
      const status = handleSetSelectedSoilUnit(soil_unit_id);
      if (status) {
        setDeleteDialogOpen(true);
      }
    },
    [handleSetSelectedSoilUnit],
  );

  const columns = [
    columnHelper.accessor("is_default", {
      cell: IsDefaultCell,
      header: "",
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("color", {
      cell: SoilColorCell,
      header: "Color",
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("soil_type", {
      cell: (row) => row.getValue(),
      header: "Soil type",
      size: 100,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("name", {
      cell: (row) => row.getValue(),
      header: "Name",
      size: 300,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("description", {
      cell: (row) => row.getValue(),
      header: "Description",
      enableColumnFilter: false,
      // size: 100,
    }),
    columnHelper.accessor("parameters.uw", {
      cell: (row) => row.getValue(),
      header: "UW [kN/m3]",
      size: 150,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("parameters.Nkt", {
      cell: (row) => row.getValue(),
      header: "Nkt [-]",
      size: 150,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("soil_unit_id", {
      cell: ActionCellFactory(
        handleUpdateSoilUnit,
        handleDeleteSoilUnit,
        handleSetDefaultSoilUnit,
      ),
      header: "Actions",
      size: 200,
      enableColumnFilter: false,
    }),
  ];

  return (
    <Box>
      <Datatable
        data={localData}
        columns={columns}
        usePagination={localData.length > 10}
      />
      {updateDialogOpen && (
        <CreateOrUpdateSoilUnit
          open={updateDialogOpen}
          setOpen={setUpdateDialogOpen}
          revision_id={revision_id}
          soil_unit_id={selectedSoilUnit?.soil_unit_id || ""}
          initialData={selectedSoilUnit as SoilUnitSchemaType}
        />
      )}
      {deleteDialogOpen && (
        <DeleteSoilUnitDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          revision_id={revision_id}
          soilUnit={selectedSoilUnit as SoilUnitSchemaType}
        />
      )}
    </Box>
  );
};
