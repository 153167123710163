import { z } from "zod";

export const projectPhaseSchema = z.object({
  name: z
    .string({ message: "Name is required" })
    .min(1, { message: "Name must have at least 1 character" }),
  description: z.string().optional().default(""),
});

export type ProjectPhaseSchemaType = z.infer<typeof projectPhaseSchema>;
