import { useCallback } from "react";
import { Column, Table } from "@tanstack/react-table";

import { DebouncedInput } from "src/components/DebouncedTextField";

export function ColumnFilter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const handleTextChange = useCallback(
    (value: string | number) => {
      column.setFilterValue(value);
    },
    [column],
  );

  const handleNumberChange = useCallback(
    (value: string | number, valueType: "min" | "max") => {
      const newValue = value === "" ? undefined : parseFloat(value as string);
      column.setFilterValue((old: [number, number]) =>
        valueType === "min" ? [newValue, old?.[1]] : [old?.[0], newValue],
      );
    },
    [column],
  );

  const handleNumberMinChange = useCallback(
    (value: string | number) => {
      handleNumberChange(value, "min");
    },
    [handleNumberChange],
  );
  const handleNumberMaxChange = useCallback(
    (value: string | number) => {
      handleNumberChange(value, "max");
    },
    [handleNumberChange],
  );

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <DebouncedInput
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={handleNumberMinChange}
        placeholder=" Min"
        className="w-16 border shadow rounded"
        debounce={500}
      />
      <DebouncedInput
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={handleNumberMaxChange}
        placeholder=" Max"
        className="w-16 border shadow rounded"
        debounce={500}
      />
    </div>
  ) : (
    <DebouncedInput
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={handleTextChange}
      placeholder=" Search..."
      className="w-36 border shadow rounded"
      debounce={200}
    />
  );
}
