import { Delete, Edit, PublishedWithChanges } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { NIconButton } from "@ngi/react-component";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { TableCustomCell } from "src/types/table";

export const ActionCellFactory = (
  updateSoilUnit: (soil_unit_id: string) => void,
  deleteSoilUnit: (soil_unit_id: string) => void,
  makeSoilUnitDefault: (soil_unit_id: string) => void,
) => {
  const Component = ({
    getValue,
    row,
  }: TableCustomCell<
    SoilUnitSchemaType,
    SoilUnitSchemaType["soil_unit_id"]
  >) => {
    const soil_unit_id = getValue() || "";
    const { is_default } = row.original;

    return (
      <Box>
        <Tooltip title="Update soil unit" placement="right-end">
          <NIconButton
            onClick={() => {
              updateSoilUnit(soil_unit_id);
            }}
          >
            <Edit />
          </NIconButton>
        </Tooltip>
        <Tooltip title="Set as default soil unit" placement="right-end">
          <span>
            <NIconButton
              onClick={() => {
                makeSoilUnitDefault(soil_unit_id);
              }}
              disabled={is_default}
            >
              <PublishedWithChanges />
            </NIconButton>
          </span>
        </Tooltip>
        <Tooltip title="Delete soil unit" placement="right-end">
          <span>
            <NIconButton
              onClick={() => {
                deleteSoilUnit(soil_unit_id);
              }}
              disabled={is_default}
            >
              <Delete />
            </NIconButton>
          </span>
        </Tooltip>
      </Box>
    );
  };

  Component.displayName = "ActionCell";
  return Component;
};
