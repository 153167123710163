import { CSSProperties, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  Column,
  ColumnDef,
  ColumnPinningState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowData,
  RowSelectionState,
  SortingState,
  TableOptions,
  TableState,
  useReactTable,
} from "@tanstack/react-table";

import { debugUpdateData, defaultSetData, useSkipper } from "./datatable-utils";
import { DEFAULT_COLUMN_SIZE } from "./DatatableConfig";
import { ColumnFilter } from "./features/ColumnFilter";
import TablePaginationActions from "./features/TablePaginationActions";

import "./Datatable.scss";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    removeRow: (row: number) => void;
    updateData: <K extends keyof TData>(
      rowIndex: number,
      columnId: string,
      value: TData[K],
    ) => void;
  }
}

type Props<T> = Omit<TableOptions<T>, "getCoreRowModel"> & {
  data: T[];
  setData?: (updateFn: (layers: T[]) => T[]) => void;
  columns: ColumnDef<T, keyof T>[];
  editable?: boolean;
  debug?: boolean;
  usePagination?: boolean;
  useRowSelection?: boolean;
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  sorting?: SortingState;
  setSorting?: OnChangeFn<SortingState>;
  getRowId?: (row: T) => string;
  pinnedColumns?: ColumnPinningState;
};

export const Datatable = <T,>({
  data,
  columns,
  editable = false,
  setData = defaultSetData,
  debug = false,
  usePagination = false,
  useRowSelection = false,
  rowSelection,
  setRowSelection,
  sorting,
  setSorting,
  getRowId,
  pinnedColumns = { left: [], right: [] },
}: Props<T>) => {
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const [columnPinning, setColumnPinning] =
    useState<ColumnPinningState>(pinnedColumns);

  const state: Partial<TableState> = {
    columnPinning,
  };

  if (sorting) {
    state["sorting"] = sorting;
  }

  if (useRowSelection) {
    state["rowSelection"] = rowSelection;
  }

  const tableOptions: TableOptions<T> = {
    data,
    columns,
    defaultColumn: { ...DEFAULT_COLUMN_SIZE },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enablePinning: true,
    // onGlobalFilterChange: setGlobalFilter,
    getRowId: getRowId,
    state: state,
    autoResetPageIndex,
    meta: {
      removeRow(rowIndex: number) {
        const setFilterFunc = (old: T[]) =>
          old.filter((_row: T, index: number) => index !== rowIndex);
        setData(setFilterFunc);
        // setOriginalData(setFilterFunc);
      },
      updateData(rowIndex, columnId, value) {
        if (debug) debugUpdateData(rowIndex, columnId, value);

        skipAutoResetPageIndex(); // Skip page index reset until after next rerender

        if (editable) {
          setData((old: T[]) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex]!,
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
        }
      },
    },
    debugTable: debug,
    // initialState: {
    //   columnPinning: { right: ["Actions"] },
    // },
  };

  const table = useReactTable(tableOptions);

  const { pageSize, pageIndex } = table.getState().pagination;
  //These are the important styles to make sticky column pinning work!
  //Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
  //View the index.css file for more needed styles such as border-collapse: separate
  const getCommonPinningStyles = (column: Column<T>): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
      isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn =
      isPinned === "right" && column.getIsFirstColumn("right");

    return {
      boxShadow: isLastLeftPinnedColumn
        ? "-4px 0 4px -5px gray inset"
        : isFirstRightPinnedColumn
          ? "4px 0 4px -5px gray inset"
          : undefined,
      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned ? "sticky" : "relative",
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      backgroundColor: "white",
    };
  };

  return (
    <>
      <div>
        <Table
          className="Datatable"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead className="Datatable-Header">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="Datatable-Row" key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => (
                  <TableCell
                    className="Datatable-HeaderCell"
                    key={`thead-row-${header.id}-${i}`}
                    colSpan={header.colSpan}
                    sx={{
                      width:
                        header.getSize() === DEFAULT_COLUMN_SIZE.size
                          ? null
                          : header.getSize(),
                    }}
                    style={{ ...getCommonPinningStyles(header.column) }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div>
                          <TableSortLabel
                            active={header.column.getIsSorted() !== false}
                            direction={header.column.getIsSorted() || "asc"}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getIsSorted() ? (
                              <Box component="span" sx={visuallyHidden}>
                                {header.column.getIsSorted() === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <ColumnFilter
                              column={header.column}
                              table={table}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody className="Datatable-Body">
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                className={
                  "Datatable-Row " + (row.getIsSelected() ? " Selected" : "")
                }
                key={`tbody-row-${row.id}-${i}`}
              >
                {row.getVisibleCells().map((cell, i) => (
                  <TableCell
                    className="Datatable-Cell"
                    key={`tbody-cell-${cell.id}-${i}`}
                    style={{ ...getCommonPinningStyles(cell.column) }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {usePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
          component="div"
          count={table.getFilteredRowModel().rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          slotProps={{
            select: {
              inputProps: { "aria-label": "rows per page" },
              native: true,
            },
          }}
          onPageChange={(_, page) => {
            table.setPageIndex(page);
          }}
          onRowsPerPageChange={(e) => {
            const size = e.target.value ? Number(e.target.value) : 10;
            table.setPageSize(size);
          }}
          ActionsComponent={TablePaginationActions}
        />
      )}
      {debug && (
        <div>
          <label>Row Selection State:</label>
          <pre>{JSON.stringify(table.getState().rowSelection, null, 2)}</pre>
          <label>Table data State:</label>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </>
  );
};
