import * as XLSX from "xlsx";

import {
  SoilType,
  SoilTypeSchema,
  SoilUnitSchemaType,
} from "src/schemas/unitSoilLayerSchema";
import {
  findColumFromHeader,
  getCellFillColor,
} from "src/utils/excel-import-utils";

const SOIL_UNIT_PARAMETERS_WORKSHEET_DATA_START = 2;

enum soilUnitParametersWorksheetHeader {
  SOIL_UNIT = "soil_unit",
  SOIL_TYPE = "soil_type",
  UW = "T_UW",
}

const SoilUnitParametersWorksheetSoilType: Record<string, SoilType> = {
  C: SoilTypeSchema.Values.CLAY,
  Si: SoilTypeSchema.Values.SILT,
  S: SoilTypeSchema.Values.SAND,
};

const matchSoilType = (value: string): SoilType =>
  SoilUnitParametersWorksheetSoilType[value] || "OTHER";

export function parseSoilUnits(
  worksheet: XLSX.WorkSheet,
  soilUnits: SoilUnitSchemaType[],
): SoilUnitSchemaType[] {
  const data: Record<string, string>[] = XLSX.utils.sheet_to_json(worksheet, {
    header: "A",
  });

  // Find the colums in the header
  const headerEnum = soilUnitParametersWorksheetHeader;
  const header = data[0];
  const soilUnitCol = findColumFromHeader(header, headerEnum.SOIL_UNIT);
  const soilTypeCol = findColumFromHeader(header, headerEnum.SOIL_TYPE);
  const soilParamUW = findColumFromHeader(header, headerEnum.UW);

  let soilUnitFromExcelParsed: SoilUnitSchemaType[] = data
    .slice(SOIL_UNIT_PARAMETERS_WORKSHEET_DATA_START)
    .map((row) => ({
      is_default: false,
      name: soilUnitCol ? row[soilUnitCol] : "",
      soil_type: soilTypeCol ? matchSoilType(row[soilTypeCol]) : "OTHER",
      parameters: { uw: soilParamUW ? parseFloat(row[soilParamUW]) : 10 },
      color: "",
      soil_unit_id: "",
    }));

  // get the default soil unit
  const defaultSoilUnit = soilUnitFromExcelParsed.find(
    (unit) => unit.name.toLowerCase() === "default",
  );

  if (!defaultSoilUnit) {
    throw new Error("Could not find the default soil unit");
  }

  // for now set the soil type of the default soil unit to CLAY
  defaultSoilUnit.is_default = true;
  defaultSoilUnit.soil_type = SoilTypeSchema.Values.CLAY;

  // assign missing soil parameters to the units
  soilUnitFromExcelParsed = soilUnitFromExcelParsed.map((unit, i) => ({
    ...unit,
    parameters: {
      uw: unit.parameters.uw || defaultSoilUnit?.parameters.uw,
    },
    color: getCellFillColor(
      worksheet,
      soilUnitCol || "A",
      i + SOIL_UNIT_PARAMETERS_WORKSHEET_DATA_START,
    ),
  }));

  const soilUnitReconciled = soilUnitFromExcelParsed.map((unit) => {
    const existingUnit = soilUnits.find(
      (existingUnit) => existingUnit.name === unit.name,
    );
    if (existingUnit) {
      return { ...unit, soil_unit_id: existingUnit.soil_unit_id };
    }
    return unit;
  });

  return soilUnitReconciled as SoilUnitSchemaType[];
}
