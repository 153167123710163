import { useEffect } from "react";
import { noModifierKeys, primaryAction } from "ol/events/condition";
import { Polygon } from "ol/geom";
import Draw, { DrawEvent } from "ol/interaction/Draw.js";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke } from "ol/style";
import Style from "ol/style/Style";

import { Location } from "src/types/locations"; // Assuming this is the correct import path
import { findLocationsInPolygon } from "./map-utils";
import { useMapContext } from "./MapContext";

const START_OPACITY = 0.4;
const polygonStyle = (opacity: number) =>
  new Style({
    fill: new Fill({ color: `rgba(0, 0, 255, ${opacity})` }),
    stroke: new Stroke({ color: "blue", width: 2 }),
  });

interface DrawPolygonInteractionProps {
  locations: Location[]; // Pass the locations to check against the polygon
  onSelection: (selectedLocations: Location[]) => void; // Callback to get selected locations
}

export const DrawPolygonInteraction = ({
  locations,
  onSelection,
}: DrawPolygonInteractionProps) => {
  const map = useMapContext();

  useEffect(() => {
    const sourceLayer = new VectorSource({ wrapX: false });
    const vectorLayer = new VectorLayer({
      source: sourceLayer,
      style: polygonStyle(START_OPACITY),
    });

    const draw = new Draw({
      source: sourceLayer,
      type: "Polygon",
      style: polygonStyle(START_OPACITY),
      condition: (e) => noModifierKeys(e) && primaryAction(e),
    });

    const drawEndListener = (e: DrawEvent) => {
      const feature = e.feature;
      const polygonCoordinates = (
        feature.getGeometry() as Polygon
      ).getCoordinates();

      const selectedLocations = findLocationsInPolygon(
        polygonCoordinates,
        locations,
      );

      // Trigger the callback with the selected locations
      if (selectedLocations && selectedLocations.length > 0) {
        onSelection(selectedLocations);
      }

      // Fading effect for the polygon after it's drawn
      setTimeout(() => {
        let opacity = START_OPACITY;
        const interval = setInterval(() => {
          feature.setStyle(polygonStyle(opacity));
          opacity -= 0.05;
          if (opacity <= 0) {
            clearInterval(interval);
            sourceLayer.removeFeature(feature);
          }
        }, 20);
      }, 1000);
    };

    // Handle drawing abort on Escape key
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        draw.abortDrawing(); // Cancel the drawing process
      }
    };

    // Handle right-click (contextmenu) to cancel drawing
    const handleRightClick = (e: MouseEvent) => {
      e.preventDefault();
      draw.abortDrawing(); // Cancel the drawing process
    };

    draw.on("drawend", drawEndListener);

    // Add key and right-click listeners
    window.addEventListener("keyup", handleKeyUp);
    map.getViewport().addEventListener("contextmenu", handleRightClick);

    map.addInteraction(draw);
    map.addLayer(vectorLayer);

    return () => {
      draw.removeEventListener("drawend", drawEndListener as any);
      map.removeInteraction(draw);
      map.removeLayer(vectorLayer);
      sourceLayer.dispose();
      vectorLayer.dispose();
      draw.dispose();

      // Cleanup event listeners
      window.removeEventListener("keyup", handleKeyUp);
      map.getViewport().removeEventListener("contextmenu", handleRightClick);
    };
  }, [map, locations, onSelection]);

  return null;
};
