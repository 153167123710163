const BOX_SHADOW =
  "0px 1px 5px 0px rgba(0, 0, 0, 0.12),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.2)";

export class TooltipElement {
  private _element: HTMLDivElement;
  constructor() {
    this._element = document.createElement("div");
    this._element.className = "ol-tooltip";
    this._element.style.backgroundColor = "white";
    this._element.style.padding = "5px";
    this._element.style.borderRadius = "4px";

    this._element.style.display = "none";
    this._element.style.overflow = "hidden";
    this._element.style.pointerEvents = "none";
    this._element.style.boxShadow = BOX_SHADOW;
  }

  get element() {
    return this._element;
  }

  setContent(content: string) {
    this._element.innerHTML = content;
  }

  show() {
    this._element.style.display = "block";
  }

  hide() {
    this._element.style.display = "none";
  }
}
