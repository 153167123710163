import axios from "axios";

import { Project } from "src/types/api";
import { FieldManagerLocation } from "src/types/locations";
import { ProjectPhase } from "src/types/projectPhase";
import { setURL } from "src/utils/api";

export const getProjects = async (): Promise<Project[]> => {
  const url = setURL("projects");
  const { data } = await axios.get(url);
  return data;
};

export const getProject = async ({
  project_id,
}: AddProjectProps): Promise<Project> => {
  const url = setURL(`project/${project_id}`);
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      console.error(`Project not found ${project_id}`);
      return null as unknown as Project;
    }
    // For other types of errors, rethrow the error
    throw error;
  }
};

export type AddProjectProps = Pick<Project, "project_id">;

export const addProject = async ({ project_id }: AddProjectProps) => {
  const url = setURL(`project/${project_id}`);
  const response = await axios.post(url, {});
  return response.data;
};

export type DeleteProjectProps = Pick<Project, "project_id">;

export const deleteProject = async ({ project_id }: DeleteProjectProps) => {
  const url = setURL(`project/${project_id}`);
  const response = await axios.delete(url);
  return response.data;
};

type GetPhasesProjectProps = Pick<Project, "project_id">;

export const getProjectPhases = async ({
  project_id,
}: GetPhasesProjectProps): Promise<ProjectPhase[]> => {
  const url = setURL(`project/${project_id}/phases`);
  const response = await axios.get(url);
  return response.data;
};

export const getProjectExternalLocations = async ({
  project_id,
}: {
  project_id: string;
}): Promise<FieldManagerLocation[]> => {
  const url = setURL(`project/${project_id}/fieldmanager/locations`);
  const response = await axios.get(url);
  return response.data;
};
