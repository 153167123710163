import { LineStrokeStyle } from "src/types/plotElements";

export const getLineStroke = (lineStrokeStyle: LineStrokeStyle) => {
  switch (lineStrokeStyle) {
    case "dotted":
      return "1, 5"; // SVG style for dotted
    case "dashed":
      return "10, 5"; // SVG style for dashed
    case "solid":
    default:
      return "none"; // Solid, no dash array
  }
};
