import { useMemo } from "react";
import * as d3 from "d3";

import { curveLayer } from "src/components/common/plots/elements/curve";
import {
  PlotAxis,
  PLotMargins,
  RenderLayer,
} from "src/components/common/plots/types";
import { XYPlot } from "src/components/common/plots/XYPlot";
import { GEO } from "src/definitions/geoParameters";
import { DataPoint } from "src/types/data";
import { GeoParameter } from "src/types/geoParameters";
import { findMinMaxForKeys } from "src/utils/data-stats";
import { groupByMethodId } from "src/utils/groupCPTs";

interface Props {
  id: string;
  data: DataPoint[][];
  flattenedData: DataPoint[];
  xKeys: GeoParameter[];
  yKey?: GeoParameter;
  margins: PLotMargins;
  zoomDispatch: any;
}

export const LocationGroupPlot = ({
  id,
  data,
  flattenedData,
  xKeys,
  yKey = GEO.depth,
  margins,
  zoomDispatch,
}: Props) => {
  const xKeyMinMax = useMemo(
    () =>
      findMinMaxForKeys(
        flattenedData,
        xKeys.map((x) => x.key),
      ),
    [flattenedData, xKeys],
  );

  const yScaleDataRange: [number, number] = useMemo(() => {
    const fl = flattenedData;
    return [0, fl[fl.length - 1].depth];
  }, [flattenedData]);

  const xAxisOptions: PlotAxis = {
    dataRange: [0, xKeyMinMax[1]] as [number, number],
    label: xKeys[0].axisLabel,
    position: "top",
    maxZoomLevel: 5,
  };

  const yAxisOptions: PlotAxis = {
    dataRange: yScaleDataRange as [number, number],
    reverse: true,
    label: yKey.axisLabel,
    position: "left",
    maxZoomLevel: 5,
  };

  const renderLayers = useMemo(() => {
    const layers = [] as ((params: RenderLayer) => void)[];
    xKeys.forEach((xKey) => {
      data.forEach((d, j) => {
        const fl = groupByMethodId(d);
        fl.forEach((f, k) => {
          layers.push(
            curveLayer({
              id: `${j}-${xKey.key}-${yKey.key}-${k}`,
              data: f,
              xKey: xKey.key,
              yKey: yKey.key,
              options: { style: { stroke: d3.schemeCategory10[j % 10] } },
            }),
          );
        });
      });
    });
    return layers as ((params: RenderLayer) => void)[];
  }, [data, xKeys, yKey.key]);

  return (
    <XYPlot
      plotId={id}
      margins={margins}
      xAxisOptions={xAxisOptions}
      yAxisOptions={yAxisOptions}
      renderLayers={renderLayers}
      zoomDispatch={zoomDispatch}
    />
  );
};
