import { useCallback, useMemo } from "react";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { Box, Grid } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { getDefaultsForSchema } from "zod-defaults";

import { NButton, NDialog } from "@ngi/react-component";
import {
  addProjectPhaseMutationQuery,
  updateProjectPhaseMutationQuery,
} from "src/queries/mutations";
import {
  projectPhaseSchema,
  ProjectPhaseSchemaType,
} from "src/schemas/projectPhaseSchema";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  project_id: string;
  phase_id?: string;
  initialData?: ProjectPhaseSchemaType;
  description?: string;
};

export const CreateOrUpdateProjectPhase = ({
  open,
  setOpen,
  initialData = getDefaultsForSchema(projectPhaseSchema),
  project_id,
  phase_id,
}: Props) => {
  const formContext = useForm<ProjectPhaseSchemaType>({
    mode: "onTouched",
    resolver: zodResolver(projectPhaseSchema),
    // ensure that the selected methods to export as set in the form options at start
    defaultValues: {
      ...initialData,
    },
  });

  const isValid = formContext.formState.isValid;

  const createProjectPhaseMutation = useMutation(
    addProjectPhaseMutationQuery(project_id),
  );

  const updateProjectPhaseMutation = useMutation(
    updateProjectPhaseMutationQuery(project_id),
  );

  const text = useMemo(
    () => (phase_id ? "Update phase" : "Create new phase"),
    [phase_id],
  );

  const handleCreateProjectPhase = useCallback(async () => {
    const isValid = await formContext.trigger();
    if (!isValid) return;
    const payload = formContext.getValues();

    if (phase_id) {
      updateProjectPhaseMutation.mutate({
        phase_id,
        payload,
      });
    } else {
      createProjectPhaseMutation.mutate({
        project_id,
        payload,
      });
    }
    setOpen(false);
  }, [
    createProjectPhaseMutation,
    formContext,
    project_id,
    phase_id,
    setOpen,
    updateProjectPhaseMutation,
  ]);

  const createPhaseActions = useMemo(() => {
    const disabled = !isValid;
    return (
      <>
        <NButton onClick={handleCreateProjectPhase} disabled={disabled}>
          {text}
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    );
  }, [handleCreateProjectPhase, isValid, setOpen, text]);

  return (
    <NDialog
      dialogTitle={text}
      onClose={() => setOpen(false)}
      open={open}
      actions={createPhaseActions}
    >
      <Box>
        <FormContainer formContext={formContext}>
          <Grid container spacing={2} sx={{ px: 2 }}>
            <Grid item xs={12}>
              <TextFieldElement
                name="name"
                label="Name"
                required
                sx={{ my: 1, width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldElement
                name="description"
                label="Description"
                multiline
                rows={4}
                sx={{ my: 1, width: "100%" }}
              />
            </Grid>
          </Grid>
        </FormContainer>
      </Box>
    </NDialog>
  );
};
