import { useCallback, useState } from "react";
import { RowSelectionState, Updater } from "@tanstack/react-table";

type Props<T> = {
  data: T[];
  initialRowSelection: string[];
  rowKey: keyof T & string;
  onRowSelectionChange: (selectedRows: string[]) => void;
};

const setInitialRowSelection = <T>({
  data,
  initialRowSelection,
  rowKey,
}: Pick<
  Props<T>,
  "data" | "initialRowSelection" | "rowKey"
>): RowSelectionState =>
  data.reduce((acc, x) => {
    const key = x[rowKey] as unknown as string;
    acc[key] = initialRowSelection?.includes(key) || false;
    return acc;
  }, {});

export const useRowSelection = <T>({
  data,
  initialRowSelection,
  onRowSelectionChange,
  rowKey,
}: Props<T>) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    setInitialRowSelection({ data, initialRowSelection, rowKey }),
  );

  const handleOnRowSelectionChange = useCallback(
    (valueFn: Updater<RowSelectionState>) => {
      if (typeof valueFn === "function") {
        const updatedRowSelection = valueFn(rowSelection);
        setRowSelection(updatedRowSelection);

        const selectedRows = Object.entries(updatedRowSelection)
          .filter((row) => row[1])
          .map((row) => row[0])
          .sort();

        onRowSelectionChange(selectedRows);
      }
    },
    [onRowSelectionChange, rowSelection],
  );

  return { rowSelection, setRowSelection, handleOnRowSelectionChange };
};
