import { useCallback, useMemo, useState } from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { NButton, NCard, NCardContent } from "@ngi/react-component";
import { AppContent } from "src/components/app/AppContent";
import { ProjectNavigation } from "src/components/app/AppNavigation";
import { ErrorComponent } from "src/components/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { LocationImportNotifications } from "src/components/LocationImportNotification";
import { LocationLayer } from "src/components/mapElement/LocationLayer";
import { MapElement } from "src/components/mapElement/MapElement";
import { MapScale } from "src/components/mapElement/MapScale";
import { PageContentHeader } from "src/components/PageContentHeader";
import {
  MAP_MARKER_ERROR,
  MAP_MARKER_PENDING,
  MAP_MARKER_PRIMARY,
} from "src/definitions/constants";
import { ERRORS_STATUSES, IMPORT_CPT_STATUSES } from "src/definitions/statuses";
import { useRevisionLocations } from "src/hooks/useRevisionLocations";
import { LocationTable } from "src/pages/revisionLocations/LocationTable";
import { SelectLocationDialog } from "src/pages/revisionLocations/SelectLocationDialog";
import { addRevisionLocationsMutationQuery } from "src/queries/mutations";
import { FieldManagerLocation } from "src/types/locations";

export const Route = createFileRoute(
  "/project/$project_id/phase/$phase_id/revision/$revision_id/locations",
)({
  component: LocationsPage,
});

function LocationsPage() {
  const { project_id, phase_id, revision_id } = Route.useParams();
  const [isBusy, setIsBusy] = useState(false);

  const {
    hasLocationCPTMissing,
    isLocationsPending,
    isLocationsError,
    locations: revisionLocations,
    refetchLocations,
  } = useRevisionLocations({ project_id, phase_id, revision_id });

  const addLocationsMutation = useMutation(
    addRevisionLocationsMutationQuery(project_id, phase_id, revision_id),
  );

  const handleAddLocations = useCallback(
    (locations: FieldManagerLocation[]) => {
      setIsBusy(true);
      addLocationsMutation.mutate(
        {
          revision_id,
          locations,
        },
        {
          onSuccess: () => {
            setIsBusy(false);
            refetchLocations();
          },
        },
      );
    },
    [addLocationsMutation, refetchLocations, revision_id],
  );

  const binnedLocations = useMemo(
    () =>
      revisionLocations
        ? {
            success: revisionLocations.filter(
              (location) =>
                location.import_status === IMPORT_CPT_STATUSES.SUCCESS,
            ),
            pending: revisionLocations.filter(
              (location) =>
                location.import_status === IMPORT_CPT_STATUSES.WAITING ||
                location.import_status === null,
            ),
            error: revisionLocations.filter((location) =>
              ERRORS_STATUSES.includes(location.import_status),
            ),
          }
        : { success: [], pending: [], error: [] },
    [revisionLocations],
  );

  const [openSelectLocations, setOpenSelectLocations] = useState(false);

  if (isLocationsPending) {
    return <LoadingComponent />;
  }

  if (isLocationsError) {
    return <ErrorComponent />;
  }

  return (
    <AppContent
      navigation={
        <ProjectNavigation
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision_id}
          hasLocationCPTMissing={hasLocationCPTMissing}
        />
      }
    >
      <Box className="max-w-wider mx-auto">
        <PageContentHeader
          title="Selected locations for this revisions"
          actions={
            <NButton
              variant="text"
              onClick={() => setOpenSelectLocations(true)}
            >
              Select locations
            </NButton>
          }
        />
        {!isBusy && revisionLocations.length > 0 ? (
          <div className="flex flex-col xl:flex-row mt-4">
            <div className="xl:w-1/2 wide:w-1/3 p-2">
              <NCard>
                <NCardContent sx={{ p: 0 }}>
                  <LocationTable
                    project_id={project_id}
                    phase_id={phase_id}
                    locations={revisionLocations}
                  />
                </NCardContent>
              </NCard>
            </div>

            <div className="xl:w-1/2 wide:w-2/3 p-2" style={{ height: "60vh" }}>
              <MapElement>
                <LocationLayer
                  locations={binnedLocations.pending}
                  pointColor={MAP_MARKER_PENDING}
                />
                <LocationLayer
                  locations={binnedLocations.error}
                  pointColor={MAP_MARKER_ERROR}
                />
                <LocationLayer
                  locations={binnedLocations.success}
                  pointColor={MAP_MARKER_PRIMARY}
                />
                <MapScale locations={revisionLocations} />
              </MapElement>
              <Box className="mt-4 flex gap-4 items-center justify-center">
                {binnedLocations.pending.length > 0 && (
                  <Chip
                    avatar={
                      <Avatar sx={{ bgcolor: MAP_MARKER_PENDING }}> </Avatar>
                    }
                    label="Locations pending CPT import"
                  />
                )}
                {binnedLocations.error.length > 0 && (
                  <Chip
                    avatar={
                      <Avatar sx={{ bgcolor: MAP_MARKER_ERROR }}> </Avatar>
                    }
                    label="Locations with error while import CPT"
                  />
                )}
                {binnedLocations.success.length > 0 && (
                  <Chip
                    avatar={
                      <Avatar sx={{ bgcolor: MAP_MARKER_PRIMARY }}> </Avatar>
                    }
                    label="Locations with CPT"
                  />
                )}
              </Box>
            </div>
          </div>
        ) : (
          <NCard className="m-4">
            <NCardContent className="!p-8">
              <div className="flex gap-4 justify-center items-center">
                {isBusy ? (
                  <LoadingComponent label="Importing the locations. This may take few seconds to a minute to complete." />
                ) : (
                  <Typography variant="body1">
                    No locations found. You need to import locations from
                    FieldManager first by clicking the button above.
                  </Typography>
                )}
              </div>
            </NCardContent>
          </NCard>
        )}
      </Box>

      {revisionLocations && openSelectLocations && (
        <SelectLocationDialog
          open={openSelectLocations}
          setOpen={setOpenSelectLocations}
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision_id}
          revisionLocations={revisionLocations}
          onAddLocations={handleAddLocations}
        />
      )}
      {hasLocationCPTMissing && (
        <LocationImportNotifications locations={revisionLocations} />
      )}
    </AppContent>
  );
}
