import { useCallback, useEffect, useRef } from "react";

export function defaultSetData<T>(updateFn: (layers: T[]) => T[]) {
  console.log("updateData function triggered");
  console.log(updateFn);
}

export function debugUpdateData(
  rowIndex: number,
  columnId: string,
  value: unknown,
) {
  console.log("updateData: rowIndex", rowIndex);
  console.log("updateData: columnId", columnId);
  console.log("updateData: value", value);
}

// see: https://tanstack.com/table/latest/docs/framework/react/examples/editable-data
export function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}
