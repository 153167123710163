import { useMemo } from "react";
import { Box } from "@mui/material";
import {
  createColumnHelper,
  RowSelectionState,
  Updater,
} from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { rowSelectionColumn } from "src/components/common/datatable/features/rowSelection";
import { Location } from "src/types/locations";

type Props = {
  locations: Location[];
  setRowSelection: (valueFn: Updater<RowSelectionState>) => void;
  rowSelection: RowSelectionState;
  autoGroupStringLength: number;
  showStringHighlight: boolean;
};

const columnHelper = createColumnHelper<Location>();

export const LocationTable = ({
  locations,
  rowSelection,
  setRowSelection,
  autoGroupStringLength,
  showStringHighlight,
}: Props) => {
  const columns = useMemo(
    () => [
      rowSelectionColumn(),
      columnHelper.accessor("name", {
        cell: (row) => {
          const name = row.getValue();
          if (autoGroupStringLength > 0 && showStringHighlight) {
            const highlightedText = name.substring(0, autoGroupStringLength);
            const restOfText = name.substring(autoGroupStringLength);
            return (
              <div>
                <span className="bg-yellow-200 rounded">{highlightedText}</span>
                <span>{restOfText}</span>
              </div>
            );
          }
          return name;
        },
        header: "Location",
        size: 400,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
      }),
      columnHelper.accessor("point_easting", {
        cell: (row) => row.getValue(),
        header: "Easting",
        size: 130,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
      }),
      columnHelper.accessor("point_northing", {
        cell: (row) => row.getValue(),
        header: "Northing",
        size: 130,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
      }),
    ],
    [autoGroupStringLength, showStringHighlight],
  );

  return (
    <Box>
      <Datatable
        data={locations}
        columns={columns}
        usePagination
        useRowSelection
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        getRowId={(row: Location) => row.siite_location_id}
      />
    </Box>
  );
};
