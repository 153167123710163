import {
  Downloading,
  Error,
  OpenInNew,
  Troubleshoot,
} from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { NIconButton } from "@ngi/react-component";
import { ERRORS_STATUSES, IMPORT_CPT_STATUSES } from "src/definitions/statuses";
import { ENV } from "src/types/env";
import { Location } from "src/types/locations";
import { TableCustomCell } from "src/types/table";
import { createFMLink } from "src/utils/links";

export const ActionCellFactory = (
  project_id: string,
  env: ENV,
  handlePreviewClick: (id: string) => void,
) => {
  const Component = ({
    cell,
    row,
  }: TableCustomCell<Location, Location["siite_location_id"]>) => {
    const siite_location_id = cell.getValue();
    const { import_status, location_id } = row.original;

    return (
      <Box className="flex gap-2 items-center justify-start">
        {import_status === IMPORT_CPT_STATUSES.SUCCESS ? (
          <Tooltip title="Preview RAW CPT" placement="right-end">
            <NIconButton
              color="primary"
              onClick={() => handlePreviewClick(siite_location_id)}
            >
              <Troubleshoot />
            </NIconButton>
          </Tooltip>
        ) : ERRORS_STATUSES.includes(import_status) ? (
          <Tooltip title="Failed to import CPT" placement="right-end">
            <div className="flex items-center justify-center grow">
              <Error color="error" />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Pending import" placement="right-end">
            <div className="flex items-center justify-center grow">
              <Downloading color="disabled" />
            </div>
          </Tooltip>
        )}
        <Tooltip title="View in FieldManager" placement="right-end">
          <div className="flex items-center justify-center grow">
            <a
              href={createFMLink({ project_id, location_id, env })}
              target="_blank"
              rel="noreferrer"
            >
              <OpenInNew color="primary" />
            </a>
          </div>
        </Tooltip>
      </Box>
    );
  };

  Component.displayName = "ActionCell";
  return Component;
};
