import axios from "axios";

import { SoilLayerType } from "src/schemas/soilLayerSchema";
import { DataPoint } from "src/types/data";
import { LocationGroupProcessedCpts } from "src/types/locationGroup";
import { setURL } from "src/utils/api";

type GetCPTProps = {
  siite_location_id: string;
};

type GetCPTReturn = {
  data: DataPoint[];
};

export const getCPT = async ({
  siite_location_id,
}: GetCPTProps): Promise<GetCPTReturn> => {
  const url = setURL(`siite_location/${siite_location_id}/cpt`);
  const { data } = await axios.get(url);
  return data;
};

export type GetProcessedCPTsProps = {
  location_group_id: string;
  payload: {
    layers?: SoilLayerType[];
    cpts?: LocationGroupProcessedCpts[];
  };
};

type GetProcessedCPTsReturn = {
  data: LocationGroupProcessedCpts;
};

export const getProcessedCPTs = async ({
  location_group_id,
  payload,
}: GetProcessedCPTsProps): Promise<GetProcessedCPTsReturn> => {
  const url = setURL(`location_group/${location_group_id}/cpt/process`);

  const response = await axios.post(url, payload);
  if (response.status === 200) {
    return response.data.data;
  }
  return response.data;
};
