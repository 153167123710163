import * as XLSX from "xlsx";

import { DEFAULT_SOIL_UNIT_COLOR } from "src/definitions/constants";

export const getCellFillColor = (
  worksheet: XLSX.WorkSheet,
  col: string,
  row: number,
): string => {
  const address = `${col}${row + 1}`;
  const color = worksheet[address]?.s?.fgColor?.rgb;
  return color ? `#${color}` : DEFAULT_SOIL_UNIT_COLOR;
};

export const columnNameToIndex = (name: string) => {
  return XLSX.utils.decode_col(name);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const chars = name.split("");
  let index = chars.length > 1 ? (chars.length - 1) * alphabet.length - 1 : 0;
  for (let i = 0; i < chars.length; i++) {
    index += alphabet.indexOf(chars[i]) + 1;
  }
  return index;
};

export const indexToColumnName = (index: number): string => {
  return XLSX.utils.encode_col(index);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let columnName = "";

  while (index > 0) {
    // Convert to zero-based index
    index--;
    const remainder = index % alphabet.length;
    columnName = alphabet[remainder] + columnName;
    index = Math.floor(index / alphabet.length);
  }

  return columnName;
};

export const findColumFromHeader = (
  header: Record<string, string>,
  value: string,
) =>
  Object.entries(header).find(
    (row) => row[1].toLowerCase() === value.toLowerCase(),
  )?.[0];
