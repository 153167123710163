import { AlignHorizontalRight } from "@mui/icons-material";

import { LocationGroup } from "src/types/locationGroup";
import { TableCustomCell } from "src/types/table";

export const LocationGroupStatusCell = ({
  getValue,
}: TableCustomCell<LocationGroup, LocationGroup["notes"]>) => {
  const notes = getValue();

  const hasHorizon =
    notes && notes.some((note) => note.note_type === "HORIZON");

  return (
    <div className="flex space-x-4">
      <AlignHorizontalRight color={hasHorizon ? "primary" : "disabled"} />
    </div>
  );
};
