import { useMemo } from "react";
import * as d3 from "d3";

import { curveLayer } from "src/components/common/plots/elements/curve";
import {
  PLotMargins,
  RenderLayerProp,
} from "src/components/common/plots/types";
import { XYPlot } from "src/components/common/plots/XYPlot";
import { GEO } from "src/definitions/geoParameters";
import { DataPoint } from "src/types/data";
import { GeoParameter } from "src/types/geoParameters";
import { findMinMaxForKeys } from "src/utils/data-stats";
import { simplifyCPT } from "src/utils/simplify-cpt";

interface Props {
  id: string;
  data: DataPoint[][];
  xKeys: GeoParameter[];
  yKey?: GeoParameter;
  margins: PLotMargins;
  yScaleDataRange: [number, number];
  zoomDispatch: any;
}

const showSimplifyCPT = false;

export const CptPlot = ({
  id,
  data,
  xKeys,
  yKey = GEO.depth,
  margins,
  yScaleDataRange,
  zoomDispatch,
}: Props) => {
  const xKeyMinMax = useMemo(
    () =>
      findMinMaxForKeys(
        data.flat(),
        xKeys.map((x) => x.key),
      ),
    [data, xKeys],
  );

  const simpleData = useMemo(
    () =>
      showSimplifyCPT ? simplifyCPT(data.flat(), ["qc", "fs", "u2"]) : data,
    [data],
  );

  const xAxisOptions = useMemo(
    () => ({
      dataRange: xKeyMinMax as [number, number],
      label: xKeys[0].axisLabel,
      position: "top",
      maxZoomLevel: 5,
    }),
    [xKeyMinMax, xKeys],
  );

  const yAxisOptions = useMemo(
    () => ({
      dataRange: yScaleDataRange as [number, number],
      reverse: true,
      label: yKey.axisLabel,
      position: "left",
      maxZoomLevel: 5,
    }),
    [yScaleDataRange, yKey],
  );
  const renderLayers = useMemo(() => {
    const layers: RenderLayerProp[] = [];
    console.log("data", data);

    xKeys.map((xKey, i) =>
      data.map((d, j) => {
        layers.push(
          curveLayer({
            id: `${id}-${xKey.key}-${j}`, // Provide a unique id for each curve
            data: d,
            xKey: xKey.key,
            yKey: yKey.key,
            options: { style: { stroke: d3.schemeCategory10[i % 10] } },
          }),
        );
      }),
    );

    if (showSimplifyCPT) {
      xKeys.map((xKey, i) =>
        layers.push(
          curveLayer({
            id: `${id}-${xKey.key}-simple`, // Provide a unique id for each curve
            data: simpleData,
            xKey: xKey.key,
            yKey: yKey.key,
            options: {
              style: { stroke: d3.schemeCategory10[(i % 10) + 1] },
            },
          }),
        ),
      );
    }

    return layers;
  }, [xKeys, id, data, yKey.key, simpleData]);

  return (
    <XYPlot
      plotId={id}
      margins={margins}
      xAxisOptions={xAxisOptions}
      yAxisOptions={yAxisOptions}
      renderLayers={renderLayers}
      zoomDispatch={zoomDispatch}
    />
  );
};
