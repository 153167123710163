import { Alert, AlertTitle } from "@mui/material";

import { NButton, NDialog } from "@ngi/react-component";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
};

export const EditableLayerPlotHelp = ({ open, setOpen }: Props) => (
    <NDialog
      dialogTitle="Plot help"
      onClose={() => setOpen(false)}
      open={open}
      maxWidth="md"
      actions={
        <>
          <NButton variant="text" onClick={() => setOpen(false)}>
            Close
          </NButton>
        </>
      }
    >
      <Alert severity="info">
        <AlertTitle>How to interact with the plots?</AlertTitle>
        <div className="space-y-2 pt-2">
          <p>
            To add a point in the plot: <b>double click </b>where to add the
            point
          </p>
          <p>
            To drag an existing point: <b>click and hold</b> while moving the
            point
          </p>
          <p>
            To delete an existing point: <b>hold SHIFT and click </b> on the
            point
          </p>
          <p>
            To update an existing point type: <b>right click</b> on the point
          </p>
        </div>
      </Alert>
      <Alert severity="info">
        <AlertTitle>Updating processed data</AlertTitle>
        <div className="space-y-2 pt-2">
          <p>
            Save the changes to recompute the processed data. It will take few
            seconds to show the updated data.
          </p>
        </div>
      </Alert>
    </NDialog>
  );
