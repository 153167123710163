import { useState } from "react";
import { Add } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { NButton, NCard, NCardContent } from "@ngi/react-component";
import { AppContent } from "src/components/app/AppContent";
import { ProjectNavigation } from "src/components/app/AppNavigation";
import { ErrorComponent } from "src/components/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { PageContentHeader } from "src/components/PageContentHeader";
import { CreateOrUpdateSoilUnit } from "src/pages/revisionSoilUnits/CreateOrUpdateSoilUnit";
import { SoilUnitTable } from "src/pages/revisionSoilUnits/SoilUnitTable";
import { getRevisionSoilUnitQuery } from "src/queries/queries";

export const Route = createFileRoute(
  "/project/$project_id/phase/$phase_id/revision/$revision_id/soil-units",
)({
  component: SoilUnitPage,
});

function SoilUnitPage() {
  const { project_id, phase_id, revision_id } = Route.useParams();
  const [open, setOpen] = useState(false);
  const {
    isPending,
    isError,
    data: soilUnits,
  } = useQuery(getRevisionSoilUnitQuery(revision_id));

  if (isPending) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <ErrorComponent />;
  }

  return (
    <AppContent
      navigation={
        <ProjectNavigation
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision_id}
        />
      }
    >
      <Box className="max-w-wider mx-auto">
        <PageContentHeader
          title="Soil Units"
          actions={
            <NButton
              startIcon={<Add />}
              variant="text"
              onClick={() => setOpen(true)}
            >
              Define soil units
            </NButton>
          }
        />
        <NCard className="m-4">
          <NCardContent className="!p-0">
            {soilUnits && soilUnits.length > 0 ? (
              <SoilUnitTable revision_id={revision_id} data={soilUnits} />
            ) : (
              <Typography variant="body1">
                No soil units found. Create first soil units by clicking the
                button above.
              </Typography>
            )}
          </NCardContent>
        </NCard>
        {open && (
          <CreateOrUpdateSoilUnit
            open={open}
            revision_id={revision_id}
            setOpen={setOpen}
          />
        )}
      </Box>
    </AppContent>
  );
}
