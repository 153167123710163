import { useState } from "react";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { NButton, NCard, NCardContent } from "@ngi/react-component";
import { AppContent } from "src/components/app/AppContent";
import { ProjectNavigation } from "src/components/app/AppNavigation";
import { ErrorComponent } from "src/components/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { PageContentHeader } from "src/components/PageContentHeader";
import { RevisionNotEnoughData } from "src/components/RevisionNotEnoughData";
import { CreateLocationGroupDialog } from "src/pages/revisionLocationGroups/CreateLocationGroupDialog";
import { LocationGroupTable } from "src/pages/revisionLocationGroups/LocationGroupTable";
import {
  getRevisionLocationGroupQuery,
  getRevisionLocationsQuery,
} from "src/queries/queries";

export const Route = createFileRoute(
  "/project/$project_id/phase/$phase_id/revision/$revision_id/location-groups",
)({
  component: LocationGroupPage,
});

function LocationGroupPage() {
  const { project_id, phase_id, revision_id } = Route.useParams();
  const [open, setOpen] = useState(false);
  const {
    isPending: isLocationsPending,
    isError: isLocationsError,
    data: locations,
  } = useQuery(getRevisionLocationsQuery(project_id, phase_id, revision_id));

  const {
    isPending: isLocationGroupsPending,
    isError: isLocationGroupsError,
    data: locationGroups,
  } = useQuery(getRevisionLocationGroupQuery(revision_id));

  const locationNameMapping = locations
    ? locations.reduce(
        (acc, location) => {
          acc[location.siite_location_id] = location.name;
          return acc;
        },
        {} as Record<string, string>,
      )
    : {};

  if (isLocationsPending || isLocationGroupsPending) {
    return <LoadingComponent />;
  }

  if (isLocationsError || isLocationGroupsError) {
    return <ErrorComponent />;
  }

  return (
    <AppContent
      navigation={
        <ProjectNavigation
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision_id}
        />
      }
    >
      <Box className="max-w-wider mx-auto">
        <PageContentHeader
          title="Location Groups"
          actions={
            locations.length > 0 && (
              <NButton
                startIcon={<Add />}
                variant="text"
                onClick={() => setOpen(true)}
              >
                Define Location Group
              </NButton>
            )
          }
        />
        {locationGroups && locationGroups.length > 0 ? (
          <NCard className="m-4">
            <NCardContent className="!p-0">
              <LocationGroupTable
                project_id={project_id}
                locationNameMapping={locationNameMapping}
                locationGroups={locationGroups}
              />
            </NCardContent>
          </NCard>
        ) : (
          <RevisionNotEnoughData
            locations={locations}
            locationGroups={locationGroups}
            project_id={project_id}
            phase_id={phase_id}
            revision_id={revision_id}
            scope="locationGroups"
          />
        )}
      </Box>
      {open && (
        <CreateLocationGroupDialog
          locations={locations}
          locationNameMapping={locationNameMapping}
          locationGroups={locationGroups}
          open={open}
          revision_id={revision_id}
          setOpen={setOpen}
        />
      )}
    </AppContent>
  );
}
