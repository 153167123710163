import { useCallback, useMemo } from "react";
import * as d3 from "d3";

import { DEFAULT_CIRCLE_STYLE } from "src/components/common/plots/constants";
import { curveLayer } from "src/components/common/plots/elements/curve";
import {
  editableScatterLayer,
  FormattedData,
} from "src/components/common/plots/elements/editableScatter";
import { Legend } from "src/components/common/plots/elements/Legend";
import { rectangleLayer } from "src/components/common/plots/elements/rectangle";
import { hSpan, vSpan } from "src/components/common/plots/elements/span";
import { textLayer } from "src/components/common/plots/elements/text";
import { PlotAxis, RenderLayer } from "src/components/common/plots/types";
import { XYPlot } from "src/components/common/plots/XYPlot";
import { HORIZON_COLOR, HORIZON_DEPTH_OFFSET } from "src/definitions/constants";
import { GEO } from "src/definitions/geoParameters";
import { SOIL_ZONES_SBT, SoilBehavior } from "src/definitions/soilZones";
import { LayerTypeSchema, SoilLayerType } from "src/schemas/soilLayerSchema";
import { DataPoint } from "src/types/data";
import {
  LocationGroup,
  LocationGroupProcessedCpts,
} from "src/types/locationGroup";
import { LineStrokeStyle, PlotLegendItem } from "src/types/plotElements";
import { GeoPlot } from "src/types/plots";
import { SoilUnit } from "src/types/soilUnit";
import { hexToRGBA } from "src/utils/color";
import { findMinMaxForKeys } from "src/utils/data-stats";
import { groupByMethodId } from "src/utils/groupCPTs";
import { dragConditions } from "./plotFeatures/dragConditions";
import { conditionalStyle } from "./plotFeatures/styleConditions";

interface Props {
  location_group: LocationGroup;
  data: LocationGroupProcessedCpts;
  flattenedData: DataPoint[];
  soilUnits: SoilUnit[];
  plot: GeoPlot;
  showLayers: boolean;
  showHorizons: boolean;
  updateLayers: (newData: SoilLayerType[]) => void;
  zoomDispatch: any;
  onRightClick?: (
    event: MouseEvent,
    dataPoint: FormattedData<SoilLayerType>,
  ) => void;
}

const margins = { top: 40, left: 50, bottom: 20, right: 10 };

export const EditableLayeringCptPlot = ({
  location_group,
  data,
  flattenedData,
  soilUnits,
  updateLayers,
  showLayers,
  showHorizons,
  plot,
  zoomDispatch,
  onRightClick,
}: Props) => {
  const localData = useMemo(() => {
    if (plot.splitDataPerMethodID) {
      return data.map((d) => ({ ...d, data: groupByMethodId(d.data) }));
    }
    return data.map((d) => ({ ...d, data: [d.data] }));
  }, [data, plot.splitDataPerMethodID]);

  const xKeys = plot.series.x;

  const xKeyMinMax = useMemo(() => {
    if (plot.xAxisRange.min !== "auto" && plot.xAxisRange.max !== "auto") {
      return [plot.xAxisRange.min, plot.xAxisRange.max];
    }

    const range = findMinMaxForKeys(
      flattenedData,
      xKeys.map((x) => x.key),
    );

    const xMin =
      plot.xAxisRange.min === "auto" ? range[0] : plot.xAxisRange.min;
    const xMax =
      plot.xAxisRange.max === "auto" ? range[1] : plot.xAxisRange.max;

    if (xMin === xMax) {
      return [0.5 * xMin, 1.5 * xMax];
    }
    return [xMin, xMax];
  }, [flattenedData, plot.xAxisRange.max, plot.xAxisRange.min, xKeys]);

  const yScaleDataRange: [number, number] = [0, location_group.max_depth];

  const xAxisOptions: PlotAxis = {
    dataRange: xKeyMinMax as [number, number],
    label: xKeys[0].axisLabel,
    position: "top",
    maxZoomLevel: 5,
    // tickCount: 5,
  };

  const yAxisOptions: PlotAxis = {
    dataRange: yScaleDataRange as [number, number],
    reverse: true,
    label: plot.series.y.axisLabel,
    position: "left",
    maxZoomLevel: 5,
  };

  // INFO: the following only works well if there are less than 3 different xKeys per plot
  const getLineStrokeStyle = (i: number): LineStrokeStyle => {
    if (i === 0) return "solid";
    if (i === 1) return "dashed";
    return "dotted";
  };

  const legendItems: PlotLegendItem[] = useMemo(
    () =>
      plot.series.x.map((xKey, i) => ({
        id: `${i}`,
        label: xKey.legendLabel,
        lineColor: "black",
        lineWidth: 2,
        lineStrokeStyle: getLineStrokeStyle(i),
      })),
    [plot.series.x],
  );

  const handleUpdatelayers = useCallback(
    (data: SoilLayerType[]) => {
      updateLayers(data);
    },
    [updateLayers],
  );

  const renderLayers = useMemo(() => {
    const layers = [] as ((params: RenderLayer) => void)[];

    // Add the static series
    plot.series.x.map((xKey, i) =>
      localData.map((d, j) => {
        if (!plot.showOnlyOneCPT || j == 0) {
          d.data.forEach((l, k) => {
            layers.push(
              curveLayer({
                id: `${i}-${j}-${k}`,
                data: l,
                xKey: xKey.key,
                yKey: plot.series.y.key,
                options: {
                  style: {
                    stroke: d3.schemeCategory10[j % 10],
                    strokeDasharray: getLineStrokeStyle(i),
                  },
                },
              }),
            );
          });
        }
      }),
    );

    location_group.layers.map((layer, i) => {
      if (layer.layer_type === LayerTypeSchema.Values.LAYER) {
        const sanitizedDepth = layer.depth.toString().replace(/\./g, "-");
        const uniqueId = `layer-boundary-${sanitizedDepth}-${layer.layer_type}-${i}`;
        layers.push(hSpan({ id: uniqueId, y: layer.depth }));
      }
    });

    if (showHorizons && location_group.notes) {
      const horizons = location_group.notes.filter(
        (note) => note.note_type === "HORIZON",
      );
      if (horizons.length > 0) {
        horizons.forEach((horizon, i) => {
          const depth = horizon.depth;
          if (depth) {
            const sanitizedDepth = depth.toString().replace(/\./g, "-");
            const uniqueId = `horizon-${sanitizedDepth}-${i}`;
            layers.push(
              hSpan({
                id: uniqueId,
                y: depth,
                options: { style: { stroke: HORIZON_COLOR } },
              }),
            );
            layers.push(
              textLayer({
                text: horizon.title,
                pos: {
                  x: 0.99,
                  y: depth + HORIZON_DEPTH_OFFSET,
                  domain: { x: "percentage", y: "data" },
                },
                anchor: "end",
                rotate: 0,
                style: { color: HORIZON_COLOR },
              }),
            );
          }
        });
      }
    }

    // Add the SBT line and text (if applicable)
    if (xKeys.includes(GEO.Icn)) {
      SOIL_ZONES_SBT.forEach((zone: SoilBehavior, i: number) => {
        const sanitizedBoundary = zone.label_pos.toString().replace(/\./g, "-");
        const uniqueId = `sbt-boundary-${sanitizedBoundary}-${i}`;
        layers.push(vSpan({ id: uniqueId, x: zone.boundary[1] }));
        layers.push(
          textLayer({
            text: zone.label,
            pos: {
              x: zone.label_pos,
              y: 0.5,
              domain: { x: "data", y: "percentage" },
            },
            rotate: 270,
          }),
        );
      });
    }

    // Add the layer rectangles
    if (showLayers) {
      const onlyLayers = location_group.layers.filter(
        (l) => l.layer_type === LayerTypeSchema.Values.LAYER,
      );
      onlyLayers.forEach((l, i) => {
        const topDepth = l.depth;
        const bottomDepth =
          i < onlyLayers.length - 1
            ? onlyLayers[i + 1].depth
            : location_group.max_depth;
        const sanitizedDepth = l.depth.toString().replace(/\./g, "-");
        const uniqueId = `layer-rectangle-${sanitizedDepth}-${l.layer_type}-${i}`;
        const soilUnit = soilUnits.find(
          (s) => s.soil_unit_id === l.soil_unit_id,
        );
        if (soilUnit?.color) {
          layers.push(
            rectangleLayer({
              id: uniqueId,
              data: {
                x1: 0,
                x2: 1,
                y1: topDepth,
                y2: bottomDepth,
                domain: {
                  x1: "percentage",
                  x2: "percentage",
                  y1: "data",
                  y2: "data",
                },
              },
              options: {
                style: {
                  fill: hexToRGBA(soilUnit?.color, 0.15),
                  stroke: soilUnit.color,
                },
              },
            }),
          );
        }
      });
    }

    // Add the editable layer
    layers.push(
      editableScatterLayer({
        data: location_group.layers,
        xKey: plot.series.x[0].key,
        yKey: plot.series.y.key,
        xDataScreen: 0.5,
        updateData: handleUpdatelayers,
        allowDragOn: "y",
        dragConditions: dragConditions(DEFAULT_CIRCLE_STYLE.radius),
        options: { conditionalStyle },
        onRightClick,
      }),
    );
    return layers as ((params: RenderLayer) => void)[];
  }, [
    handleUpdatelayers,
    localData,
    location_group.layers,
    location_group.max_depth,
    location_group.notes,
    onRightClick,
    plot.series.x,
    plot.series.y.key,
    plot.showOnlyOneCPT,
    showHorizons,
    showLayers,
    soilUnits,
    xKeys,
  ]);

  return (
    <div className="h-full flex flex-col">
      <div className="Plot grow py-4">
        <XYPlot
          plotId={plot.id}
          margins={margins}
          xAxisOptions={xAxisOptions}
          yAxisOptions={yAxisOptions}
          zoomDispatch={zoomDispatch}
          renderLayers={renderLayers}
        />
      </div>
      <div className="h-16 flex justify-center">
        <Legend items={legendItems} />
      </div>
    </div>
  );
};
