import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";

import { NButton, NCard, NCardHeader } from "@ngi/react-component";

type Props = {
  openAddProjectDialog: (open: boolean) => void;
};

export const ProjectListHeader = ({ openAddProjectDialog }: Props) => (
  <Box>
    <NCard>
      <NCardHeader
        title="Projects"
        action={
          <NButton
            startIcon={<Add />}
            variant="text"
            onClick={() => openAddProjectDialog(true)}
          >
            Add projects from FM
          </NButton>
        }
      />
    </NCard>
  </Box>
);
