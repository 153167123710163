import { createTheme } from "@mui/material/styles";

import { themeOptions } from "@ngi/react-component";

// Create a theme instance.
const theme = createTheme(themeOptions, {
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "#0073D10a",
          },
        },
      },
    },
  },
});

export default theme;
