// Function to calculate Euclidean distance between two points in easting/northing coordinates
export function euclideanDistance(
  coord1: [number, number],
  coord2: [number, number],
): number {
  const [x1, y1] = coord1;
  const [x2, y2] = coord2;

  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}
