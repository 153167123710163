import { useCallback, useMemo } from "react";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { NBaseAlert, NButton, NDialog } from "@ngi/react-component";
import { deleteProjectPhaseMutationQuery } from "src/queries/mutations";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  project_id: string;
  phase_id: string;
};

export const DeleteProjectPhase = ({
  open,
  setOpen,
  project_id,
  phase_id,
}: Props) => {
  const deleteProjectPhaseMutation = useMutation(
    deleteProjectPhaseMutationQuery(project_id),
  );

  const handleDeleteProjectPhase = useCallback(async () => {
    deleteProjectPhaseMutation.mutate({ phase_id });
    setOpen(false);
  }, [deleteProjectPhaseMutation, phase_id, setOpen]);

  const createPhaseActions = useMemo(
    () => (
      <>
        <NButton
          onClick={handleDeleteProjectPhase}
          variant="destructive"
          startIcon={<Delete />}
        >
          Delete Project Phase
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    ),
    [handleDeleteProjectPhase, setOpen],
  );

  return (
    <NDialog
      dialogTitle="Delete Project Phase"
      onClose={() => setOpen(false)}
      open={open}
      actions={createPhaseActions}
    >
      <Box>
        <NBaseAlert
          title="Are you sure you want to delete this project phase?"
          description="The operation cannot be undone."
          severity="warning"
        />
      </Box>
    </NDialog>
  );
};
