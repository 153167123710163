import axios from "axios";

import { API_BASE_URL } from "src/definitions/constants";
import { ENV } from "src/types/env";

type FrontendConfig = {
  env: ENV;
  version: string;
  base_url: string;
};

export const getConfig = async (): Promise<FrontendConfig> => {
  const { data } = await axios.get(`${API_BASE_URL}/config`);
  return data;
};
