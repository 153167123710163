export function hexToRGBA(hex: string, alpha: number): string {
  // Remove the hash symbol if it exists
  const cleanHex = hex.replace("#", "");

  // Parse the hex color to its RGB components
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  // Return the RGBA string with the alpha value
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
